<template>
  <transition
    name="fade"
    enter-active-class="transition-opacity duration-500"
    leave-active-class="transition-opacity duration-500"
  >
    <button
      v-if="showBacktoTop"
      class="back_to_top fixed bottom-5 right-5 z-50 bg-blue-300 text-white rounded-full flex justify-center items-center shadow-md"
      style="height: 50px; width: 50px"
      @click.prevent="scrollToTop"
    >
      <span
        class="icon-[mdi--arrow-up]"
        style="color: white; height: 2rem; width: 2rem"
      ></span>
    </button>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showBacktoTop: false,
    };
  },
  props: {
    appearPx: {
      type: Number,
      default: 200,
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      this.showBacktoTop = window.scrollY > this.appearPx;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
