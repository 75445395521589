<template>
  <div></div>
</template>
<script>
export default {
  /* ステータス計算式 [{(種族値×2＋個体値＋努力値/4)×Lv/100}＋5]×性格補正(1.1,1,0.9)
   *  const value = Math.floor(((2 * base_status + iv + ev / 4) * level / 100 + 5) * nature);
   *  ここでは、ポケモンのステータスを状況に応じて計算する
   *  例えば atkSide が true ならば、攻撃側の atk もしくsp_atkを計算する
   *  かつ
   *  ゆきで、こおりタイプの場合、Bが1.5倍
   *  すなあらしで、いわタイプの場合、Dが1.5倍
   *  各サイドのランクの補正も計算する
   */
  props: {
    atkSide: Boolean,
    defSide: Boolean,
    moveClass: String, // '物理' または '特殊'
    pokeStatus: Object, // { poke_attack, poke_sp_atk, poke_defense, poke_sp_def, level }
    IVValue: Number,
    EVValue: Number,
    RankValue: Number,
    LevelValue: Number,
    NatureModifierValue: Number,
    defPokeType1: String,
    defPokeType2: String, // nullの場合あり
    weather: String, // 'ゆき' または 'すなあらし'
    ruin: String, // わざわい系
  },
  computed: {
    calculatedStatus() {
      let statValue = 0;

      // ランク値に基づく補正を計算
      let rankModifier =
        this.RankValue >= 0
          ? (2 + this.RankValue) / 2
          : 2 / (2 - this.RankValue);

      // weatherModifierのための、天候による補正値を計算
      let weatherModifier = 1; // 天候による補正値の初期値
      if (this.defSide) {
        if (
          (this.weather === "ゆき" &&
            (this.defPokeType1 === "こおり" ||
              this.defPokeType2 === "こおり")) ||
          (this.weather === "すなあらし" &&
            (this.defPokeType1 === "いわ" || this.defPokeType2 === "いわ") &&
            this.moveClass === "特殊")
        ) {
          weatherModifier = 1.5;
        }
      }

      // わざわい系による補正値を計算
      let ruinModifier = 1; // わざわい系による補正値の初期値
      if (
        this.atkSide &&
        this.moveClass === "物理" &&
        this.ruin === "わざわいのおふだ"
      ) {
        ruinModifier = 0.75;
      } else if (
        this.atkSide &&
        this.moveClass === "特殊" &&
        this.ruin === "わざわいのうつわ"
      ) {
        ruinModifier = 0.75;
      } else if (
        this.defSide &&
        this.moveClass === "物理" &&
        this.ruin === "わざわいのつるぎ"
      ) {
        ruinModifier = 0.75;
      } else if (
        this.defSide &&
        this.moveClass === "特殊" &&
        this.ruin === "わざわいのたま"
      ) {
        ruinModifier = 0.75;
      }

      // 攻撃側または防御側のステータス計算にランク補正を適用
      if (this.atkSide) {
        if (this.moveClass === "物理") {
          statValue = Math.floor(
            (((2 * this.pokeStatus.poke_attack +
              this.IVValue +
              this.EVValue / 4) *
              this.LevelValue) /
              100 +
              5) *
              this.NatureModifierValue *
              rankModifier *
              ruinModifier
          );
        } else if (this.moveClass === "特殊") {
          statValue = Math.floor(
            (((2 * this.pokeStatus.poke_sp_atk +
              this.IVValue +
              this.EVValue / 4) *
              this.LevelValue) /
              100 +
              5) *
              this.NatureModifierValue *
              rankModifier *
              ruinModifier
          );
        }
      } else if (this.defSide) {
        if (this.moveClass === "物理") {
          statValue = Math.floor(
            (((2 * this.pokeStatus.poke_defense +
              this.IVValue +
              this.EVValue / 4) *
              this.LevelValue) /
              100 +
              5) *
              this.NatureModifierValue *
              weatherModifier * // 天候による補正を適用
              rankModifier *
              ruinModifier
          );
        } else if (this.moveClass === "特殊") {
          statValue = Math.floor(
            (((2 * this.pokeStatus.poke_sp_def +
              this.IVValue +
              this.EVValue / 4) *
              this.LevelValue) /
              100 +
              5) *
              this.NatureModifierValue *
              weatherModifier * // 天候による補正を適用
              rankModifier *
              ruinModifier
          );
        }
      }
      return statValue;
    },
  },
  watch: {
    calculatedStatus(newValue) {
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>
