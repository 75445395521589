<template>
  <div class="space-y-3">
    <span class="font-semibold flex justify-center border-b-2 border-blue-500"
      >個体値</span
    >
    <div v-for="iv in ivs" :key="iv.name" class="items-center gap-2">
      <input
        type="range"
        class="range w-full mt-2"
        min="0"
        max="31"
        step="1"
        :value="iv.value"
        @input="updateValue($event, iv.name)"
      />
      <div class="flex justify-between mt-1">
        <button @click="decrement(iv.name)" class="round-button">-</button>
        <span class="mx-2 text-sm">({{ iv.name }}):{{ iv.value }}</span>
        <button @click="increment(iv.name)" class="round-button">+</button>
      </div>
    </div>
  </div>
  <button
    @click="clearIValue"
    class="flex justify-center my-4 w-full round-long-button-border"
  >
    全個体値をクリア
  </button>
  <button
    @click="fillIValue"
    class="flex justify-center my-4 w-full round-long-button-border"
  >
    全個体値をMAX
  </button>
</template>
<script>
export default {
  name: "MemoIVCounter",
  emits: ["updateIVs"],
  props: {
    // propが存在した場合それを使用して初期化する
    initialIVs: {
      type: Array,
      default: () => [
        { name: "体力", key: "iHp", value: 31 },
        { name: "攻撃", key: "iAtk", value: 31 },
        { name: "防御", key: "iDef", value: 31 },
        { name: "特攻", key: "iSpAtk", value: 31 },
        { name: "特防", key: "iSpDef", value: 31 },
        { name: "素早さ", key: "iSpeed", value: 31 },
      ],
    },
  },
  data() {
    return {
      ivs: this.initialIVs,
    };
  },
  watch: {
    initialIVs(newValue) {
      // propが変更された場合、ivsを更新
      this.ivs = newValue;
    },
  },
  methods: {
    clearIValue() {
      this.ivs.forEach((iv) => (iv.value = 0));
      this.$emit("updateIVs", this.ivs);
    },
    fillIValue() {
      this.ivs.forEach((iv) => (iv.value = 31));
      this.$emit("updateIVs", this.ivs);
    },
    updateValue(event, ivName) {
      const newValue = parseInt(event.target.value);
      const iv = this.ivs.find((iv) => iv.name === ivName);
      if (newValue >= 0 && newValue <= 31) {
        iv.value = newValue;
        this.$emit("updateIVs", this.ivs);
      } else {
        event.target.value = iv.value;
      }
    },
    increment(ivName) {
      const iv = this.ivs.find((iv) => iv.name === ivName);
      if (iv.value < 31) {
        iv.value++;
        this.$emit("updateIVs", this.ivs);
      }
    },
    decrement(ivName) {
      const iv = this.ivs.find((iv) => iv.name === ivName);
      if (iv.value > 0) {
        iv.value--;
        this.$emit("updateIVs", this.ivs);
      }
    },
  },
};
</script>
