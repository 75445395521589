<template>
  <div>
    <register-form />
  </div>
</template>
<script>
import RegisterForm from "@/components/auth/RegisterForm.vue";
export default {
  components: {
    RegisterForm,
  },
};
</script>
