<template>
  <div>
    <button
      @click="isModalOpen = true"
      class="w-full px-2 py-1 bg-blue-500 text-white rounded text-sm hover:bg-blue-600"
    >
      <span class="text-sm">攻撃アイテムを選択</span>
    </button>

    <TheModal
      :isVisible="isModalOpen"
      @update:isVisible="handleModalVisibility"
    >
      <div class="p-3">
        <ul class="grid grid-cols-2 md:grid-cols-2 gap-4">
          <li
            v-for="item in items"
            :key="item.name"
            @click="notifySelectedItem(item)"
            :class="[
              'px-2 py-1 rounded cursor-pointer hover:bg-blue-100',
              item.name === selectedItem.name
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200',
            ]"
          >
            <div class="flex justify-center text-sm">{{ item.name }}</div>
            <div class="text-xs">{{ item.description }}</div>
          </li>
        </ul>
      </div>
    </TheModal>
  </div>
</template>

<script>
import TheModal from "@/components/common/TheModal.vue";

export default {
  components: {
    TheModal,
  },
  data() {
    return {
      isModalOpen: false,
      selectedItem: { name: "未選択", description: "" }, // null から実際のオブジェクトに変更
      /* 攻撃側のアイテムリスト */
      items: [
        { name: "未選択", description: "" },
        {
          name: "いのちのたま",
          description: "攻撃技は1.3倍になるが、攻撃時HPの1/10が減少する",
        },
        {
          name: "こだわりハチマキ",
          description: "物理技の威力が1.5倍になるが、その技しか使えなくなる",
        },
        {
          name: "こだわりメガネ",
          description: "特殊技の威力が1.5倍になるが、その技しか使えなくなる",
        },
        {
          name: "たつじんのおび",
          description: "効果抜群の技だった場合、1.2倍",
        },
        { name: "ちからのハチマキ", description: "物理技の威力が、1.1倍" },
        { name: "ものしりメガネ", description: "特殊技の威力が、1.1倍" },
        {
          name: "タイプ1.2倍系",
          description: "タイプが一致している技の威力が、1.2倍",
        },
        {
          name: "ふといホネ",
          description: "カラカラかガラガラに持たせると、攻撃が2倍",
        },
        {
          name: "でんきだま",
          description: "ピカチュウにもたせると、攻撃と特攻が2倍",
        },
        // { name: 'ばんのうがさ', description: 'にほんばれとあめ状態の影響を受けない'},
      ],
    };
  },
  methods: {
    handleModalVisibility(isVisible) {
      this.isModalOpen = isVisible;
    },
    notifySelectedItem(item) {
      this.selectedItem = item; // 選択されたアイテムを更新
      this.$emit("atkItemSelected", item);
      this.isModalOpen = false; // アイテム選択後にモーダルを閉じる
    },
  },
};
</script>
