import { authAxios } from "@/axios";

const state = {
  user: 0,
  bio: "",
  favorited_pokemon: "",
  name: "",
  email: "",
  additional_field: {
    favorite_poke_image_src: "",
  },
};

const getters = {
  getProfile(state) {
    return {
      user: state.user,
      bio: state.bio,
      favorited_pokemon: state.favorited_pokemon,
      name: state.name,
      email: state.email,
      additional_field: state.additional_field,
    };
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setBio(state, bio) {
    state.bio = bio;
  },
  setFavoritedPokemon(state, favorited_pokemon) {
    state.favorited_pokemon = favorited_pokemon;
  },
  setName(state, name) {
    state.name = name;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setAdditionalField(state, additional_field) {
    state.additional_field = additional_field;
  },
};

const actions = {
  async fetchProfile({ commit }) {
    try {
      const response = await authAxios.get("/api/profile");
      commit("setUser", response.data.user);
      commit("setBio", response.data.bio);
      commit("setFavoritedPokemon", response.data.favorited_pokemon);
      commit("setName", response.data.name);
      commit("setEmail", response.data.email);
      commit("setAdditionalField", response.data.additional_field);
    } catch (error) {
      console.error("Error fetching Profile:", error);
      throw error; // 外部でエラーをキャッチするためにスローする
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
