<template>
  <div>
    <!-- ヘッダー -->
    <header class="bg-blue-500 text-white sticky top-0 z-50">
      <div class="container mx-auto flex items-center justify-between p-2">
        <!-- 左側: メニューボタン -->
        <button @click="toggleMenu" class="text-3xl">
          <span class="icon-[mdi--menu]"></span>
        </button>

        <!-- 中央: タイトルとホームリンク -->
        <div class="text-lg font-bold">
          <router-link to="/home" class="flex items-center justify-center">
            <span class="icon-[mdi--pokeball]"></span> PokeProject
          </router-link>
        </div>

        <!-- 右側: ユーザー情報、SNS共有、ログイン/登録リンク -->
        <div class="flex items-center space-x-2">
          <TheInfo />
          <SnsShare />
          <div v-if="isLoggedIn">
            <button @click="toggleRightMenu" class="text-3xl">
              <span class="icon-[mdi--user]"></span>
            </button>
          </div>
          <div v-else class="mb-2">
            <router-link
              to="/login"
              class="text-sm round-long-button-if-bg-blue ml-2"
            >
              ログイン
            </router-link>
            <router-link
              to="/register"
              class="text-sm round-long-button-if-bg-blue ml-2 hidden sm:inline-block"
            >
              新規登録
            </router-link>
          </div>
        </div>
      </div>
    </header>

    <!-- ナビゲーションメニュー -->
    <navigation-menu />

    <!-- グローバルなお知らせ -->
    <grobal-announce />

    <!-- progress bar  -->
    <vue-progress-bar></vue-progress-bar>

    <!-- 左側のAppメニュー -->
    <transition name="slide">
      <div
        class="fixed top-0 bottom-0 left-0 z-50 w-70 bg-gray-800 text-white"
        v-if="menuOpen"
        ref="menu"
      >
        <button @click="toggleMenu" class="p-2 flex items-center">
          <span class="icon-[mdi--close-circle-outline] w-6 h-6"> </span>
          <span class="ml-3 text-lg">閉じる</span>
        </button>
        <nav class="flex flex-col space-y-2 p-2">
          <router-link
            to="/home"
            class="flex items-center space-x-2 hover:underline"
          >
            <span class="icon-[mdi--home] w-5 h-5"></span>
            <span class="text-lg">ホーム</span>
          </router-link>
          <router-link
            to="/dmg"
            class="flex items-center space-x-2 hover:underline"
          >
            <span class="icon-[mdi--calculator] w-5 h-5"></span>
            <span class="text-lg">ダメージ計算</span>
          </router-link>
          <router-link
            to="/memo"
            class="flex items-center space-x-2 hover:underline"
          >
            <span class="icon-[mdi--note-edit] w-5 h-5"></span>
            <span class="text-lg">ポケモンメモ</span>
          </router-link>
          <router-link
            to="/rankmaker"
            class="flex items-center space-x-2 hover:underline"
          >
            <span class="icon-[mdi--podium-gold] w-5 h-5"></span>
            <span class="text-lg">ランクメーカー</span>
          </router-link>
        </nav>
      </div>
    </transition>
    <transition name="slide-right">
      <div
        class="fixed top-0 bottom-0 right-0 z-50 w-70 bg-gray-800 text-white"
        v-if="rightMenuOpen"
        ref="rightMenu"
      >
        <!-- 右側の認証系メニュー -->
        <button @click.stop="toggleRightMenu" class="p-2 flex items-center">
          <span class="icon-[mdi--close-circle-outline] w-6 h-6"> </span>
          <span class="ml-3 text-lg">閉じる</span>
        </button>
        <nav class="flex flex-col space-y-2 p-2">
          <div class="">
            <span class="mx-3 font-bold">{{ userData.name }}</span>
            <div class="mx-3 text-xs">{{ userData.email }}</div>
          </div>
          <router-link
            to="/profile"
            class="flex items-center space-x-2 hover:underline"
          >
            <span class="icon-[mdi--user-card-details-outline] w-5 h-5"></span>
            <span class="text-lg">プロフィール</span>
          </router-link>
          <router-link
            to="/home/user"
            class="flex items-center space-x-2 hover:underline"
          >
            <span class="icon-[mdi--note-plus] w-5 h-5"></span>
            <span class="text-lg">投稿したポケメモ</span>
          </router-link>
          <router-link
            to="/home/user/favorited"
            class="flex items-center space-x-2 hover:underline"
          >
            <span class="icon-[mdi--favorite] w-5 h-5"></span>
            <span class="text-lg">お気に入りポケメモ</span>
          </router-link>
          <!-- <router-link
            to="profile/edit"
            class="flex items-center space-x-2 hover:underline"
          >
            <span class="icon-[mdi--folder-user] w-5 h-5"></span>
            <span class="text-lg">プロフィール編集</span>
          </router-link> -->
          <router-link
            to="/password/change"
            class="flex items-center space-x-2 hover:underline"
          >
            <span class="icon-[mdi--password-reset] w-5 h-5"></span>
            <span class="text-lg">パスワード変更</span>
          </router-link>
          <div
            @click="logout"
            class="flex items-center space-x-2 hover:underline hover:cursor-pointer"
          >
            <span class="icon-[mdi--logout] w-5 h-5"></span>
            <span class="text-lg">ログアウト</span>
          </div>
        </nav>
      </div>
    </transition>

    <div class="mt-5">
      <main class="container mx-auto p-4">
        <router-view></router-view>
      </main>
    </div>
    <!-- `dmg`でない場合にのみフッターを表示 -->
    <the-footer v-if="this.$route.path !== '/dmg'" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SnsShare from "@/components/common/share/SnsShare.vue";
import TheInfo from "@/components/common/TheInfo.vue";
import NavigationMenu from "./NavigationMenu.vue";
import GrobalAnnounce from "./GrobalAnnounce.vue";
import TheFooter from "./TheFooter.vue";
export default {
  components: {
    SnsShare,
    TheInfo,
    NavigationMenu,
    GrobalAnnounce,
    TheFooter,
  },
  data() {
    return {
      menuOpen: false,
      rightMenuOpen: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      // 更新するために一度りロード
      location.reload();
      this.rightMenuOpen = !this.rightMenuOpen;
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
      if (this.menuOpen && this.rightMenuOpen) {
        // 左のメニューを開くときに右のメニューが開いていれば、右のメニューを閉じる
        this.rightMenuOpen = false;
      }
    },
    toggleRightMenu() {
      this.rightMenuOpen = !this.rightMenuOpen;
      if (this.rightMenuOpen && this.menuOpen) {
        // 右のメニューを開くときに左のメニューが開いていれば、左のメニューを閉じる
        this.menuOpen = false;
      }
    },
    closeMenu(event) {
      // menu と menuButton の存在を確認
      const menuExists =
        this.$refs.menu && this.$refs.menu.contains(event.target);
      const rightMenuExists =
        this.$refs.rightMenu && this.$refs.rightMenu.contains(event.target);
      const buttonExists =
        this.$refs.menuButton && this.$refs.menuButton.contains(event.target);
      const rightButtonExists = event.target === this.$refs.rightMenuButton;

      // メニューまたはハンバーガーメニューボタン以外の場所でクリックが発生した場合、メニューを閉じる
      if (
        !menuExists &&
        !buttonExists &&
        !rightMenuExists &&
        !rightButtonExists
      ) {
        this.menuOpen = false;
        this.rightMenuOpen = false;
      }
    },
  },
  created() {
    // App.vueが最初に読み込まれたときにプログレスバーを開始する
    this.$Progress.start();

    // ルートビューが切り替わる前にプログレスバーを開始するためのフック
    this.$router.beforeEach((to, from, next) => {
      // 遷移先のページにmeta.progressオブジェクトがある場合
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        // メタタグを解析する
        this.$Progress.parseMeta(meta);
      }
      // プログレスバーを開始する
      this.$Progress.start();
      // 次のページに進む
      next();
    });

    // ルートビューの切り替えが完了した後にプログレスバーを終了するためのフック
    this.$router.afterEach((to, from) => {
      console.log("Current route:", to.path);
      console.log("Previous route:", from.path);
      this.$Progress.finish();
    });
  },
  beforeUnmount() {
    // コンポーネントが破棄される前に、イベントリスナーを削除します
    document.removeEventListener("click", this.closeMenu, true);
  },
  mounted() {
    this.$router.afterEach(() => {
      this.menuOpen = false; // ナビゲーションが完了したらメニューを閉じる
      this.rightMenuOpen = false;
    });
    // ドキュメント全体にクリックイベントリスナーを追加します
    document.addEventListener("click", this.closeMenu, true);

    // コンポーネントが読み込まれた後にプログレスバーを終了する
    this.$Progress.finish();

    // ローカルストレージからユーザーデータを取得する dddd
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      this.userData = JSON.parse(storedUserData);
      // console.log("User Data:", this.userData);
    }
  },
};
</script>

<style scoped>
/* スライドイン・アウトのアニメーション */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}
.slide-enter, .slide-leave-to /* .slide-leave-active in <2.1.8 */ {
  transform: translateX(-100%);
}

/* 右からスライドインするアニメーション */
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.5s ease;
}
.slide-right-enter,
.slide-right-leave-to {
  transform: translateX(100%);
}
</style>
