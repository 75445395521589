<template>
  <div class="mx-auto">
    <div class="sticky top-0 bg-white mb-5 z-40">
      <the-pagination
        :totalPages="totalPages"
        :currentPage="currentPage"
        :hasNextPage="hasNextPage"
        :hasPreviousPage="hasPreviousPage"
        @pageChange="fetchStorePokemonList"
      />
    </div>
    <div class="my-5">
      <memo-input-fetch :is-user-memo="true" />
    </div>
    <BackToTopArrow />
    <div class="flex items-center mb-8 border-b-2 border-gray-700">
      <span class="icon-[mdi--format-list-bulleted] text-xl"></span>
      <div class="text-lg font-bold">みんなのポケモンリスト</div>
    </div>
    <div class="memo-card-container">
      <div class="flex flex-wrap -mx-2">
        <div
          v-for="pokemon in pokemonList"
          :key="pokemon.id"
          class="w-full sm:w-1/2 md:w-1/3 px-2 mb-4"
        >
          <memo-card :memo-info="pokemon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 検索後のポケメモを表示するコンポーネント */
import BackToTopArrow from "@/components/common/BackToTopArrow.vue";
import ThePagination from "@/components/common/ThePagination.vue";
import MemoCard from "../MemoCard.vue";
import MemoInputFetch from "../MemoInputFetch.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BackToTopArrow,
    ThePagination,
    MemoCard,
    MemoInputFetch,
  },
  data() {
    return {
      pokemonList: [],
      totalPages: 0,
      currentPage: 1,
      hasNextPage: false,
      hasPreviousPage: false,
    };
  },
  computed: {
    ...mapState("homeMemoSearchUser", ["searchedPokeMemo"]),
  },
  watch: {
    "$route.query"(newQuery) {
      this.fetchStorePokemonList(parseInt(newQuery.page), newQuery.search);
    },
  },
  methods: {
    ...mapActions("homeMemoSearchUser", ["searchPokeMemo"]),
    async fetchStorePokemonList(page = 1, search = "") {
      await this.searchPokeMemo({ page, search });
      this.updatePokemonList();
    },
    updatePokemonList() {
      const pokeMemo = this.searchedPokeMemo;
      if (pokeMemo) {
        this.pokemonList = pokeMemo.results;
        this.totalPages = Math.ceil(pokeMemo.count / 10);
        this.currentPage = parseInt(this.$route.query.page) || 1;
        this.hasNextPage = pokeMemo.next !== null;
        this.hasPreviousPage = pokeMemo.previous !== null;
      }
    },
  },
  mounted() {
    this.fetchStorePokemonList(
      parseInt(this.$route.query.page) || 1,
      this.$route.query.search || ""
    );
  },
};
</script>

<style scoped></style>
