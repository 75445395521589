/*
 * 下記でポケモンのステータスの初期値を設定する
 * 例えば、selectedMove.move_classの値で物理か特殊かを判定し、
 * A, B, C, D, Sの値を設定する
 */

// 技の初期値
export const initPokemon = {
  id: 1,
  poke_ability: {
    name: "しんりょく",
    description:
      "HPが1/3以下になると、くさタイプのわざで攻撃するときのこうげき・とくこうが1.5倍になる。",
  },
  poke_ability2: null,
  poke_abi_hidden: {
    name: "ようりょくそ",
    description: "にほんばれのとき、すばやさが2倍になる。",
  },
  poke_name: "フシギダネ",
  poke_name_suggest: "フシギダネ, ふしぎだね, fushigidane",
  poke_image: null,
  poke_image_src: `${process.env.VUE_APP_BASE_URL}/media/poke_img/001.png`,
  poke_number: 1,
  poke_type: "くさ",
  poke_type2: "どく",
  poke_hp: 45,
  poke_attack: 49,
  poke_defense: 49,
  poke_sp_atk: 65,
  poke_sp_def: 65,
  poke_speed: 45,
  poke_base_stats: 318,
  before_evolution: true,
};

export const initMove = {
  id: 8,
  move_name: "アイスハンマー",
  move_type: "こおり",
  move_class: "物理",
  move_power: 100,
  move_accuracy: 90,
};
