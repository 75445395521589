import AppLayout from "../layouts/AppLayout.vue";
import PokeDmg from "../views/PokeDmg.vue";
import PokeMemo from "../views/PokeMemo.vue";
import PokeMemoUpdateView from "../views/PokeMemoUpdateView.vue";
import TheHome from "../views/TheHome.vue";
import TheHomeDetail from "../views/TheHomeDetail.vue";
import TheHomeSearch from "../views/TheHomeSearch.vue";
import TheHomeSearchUser from "../views/TheHomeSearchUser.vue";
import TheHomeFavorited from "../views/TheHomeFavorited.vue";
import RankMaker from "../views/RankMaker.vue";

import UserMemo from "../views/UserMemo.vue";

// 認証系のルーティング
import routerAuth from "./routerAuth";

// アカウントに紐づくルーティング
import routerAccount from "./routerAccount";

import store from "@/store";

/* 
ダイナミックインポートまたは動的インポートの書き方
簡単に言うと、実行するときにコードを読み込む。
下記の方法だとmain.jsが読み込まれた時点ですべてのコンポーネントが読み込まれるので、初期読み込み時の負荷が大きい
なのでコードを分割して読み込むことで、初期読み込み時の負荷を軽減できる要するにチャンク
googleに怒られたらこれ使おうかな
  {
  path: "home",
  component: () => import("../views/TheHome.vue"),
  meta: {
    title: "ホーム",
    description: "ホームページの説明",
  },
*/

const routes = [
  {
    path: "/",
    component: AppLayout,
    children: [
      ...routerAuth,
      ...routerAccount,
      { path: "", redirect: "/home" },
      {
        path: "home",
        component: TheHome,
        meta: {
          title: "ポケプロジェクトホーム",
          description:
            "ポケモン愛好家のための中心地。ユーザーが投稿したポケモンのメモ情報を探索。",
        },
      },
      {
        path: "home/detail/:id",
        component: TheHomeDetail,
        name: "TheHomeDetail",
        meta: {
          title: "ポケモンのメモ情報",
          description:
            "ユーザーが投稿したポケモンのメモ情報を確認。詳細な情報をチェックしよう。",
        },
      },
      {
        path: "home/search",
        component: TheHomeSearch,
        name: "SearchResult",
        meta: {
          title: "全てのポケモンのメモ情報からの検索結果",
          description:
            "みんなのメモしたポケモンの情報を検索。お気に入りのポケモンのメモ情報を探してみよう。",
        },
        props: (route) => ({ page: parseInt(route.query.page) || 1 }),
      },
      {
        path: "user",
        redirect: "/home/user", // /userがないとかで警告メッセージが出るので追加
      },
      {
        path: "home/user",
        component: UserMemo,
        name: "UserMemo",
        meta: {
          title: "ユーザーのポケモンのメモ情報",
          description: "自分が投稿した全てのポケモンのメモ情報を確認。",
        },
      },
      {
        path: "home/user/search",
        component: TheHomeSearchUser,
        name: "SearchResultUser",
        meta: {
          title: "ユーザーが投稿したポケモンのメモ情報からの検索結果",
          description:
            "自分が投稿したポケモンのメモ情報を検索して、欲しい情報にアクセスしよう。",
        },
        props: (route) => ({ page: parseInt(route.query.page) || 1 }),
      },
      {
        path: "home/user/favorited",
        component: TheHomeFavorited,
        name: "Favorited",
        meta: {
          title: "お気に入り登録したポケモンのメモ情報",
          description:
            "お気に入り登録したポケモンのメモ情報を確認。お気に入り登録したポケモンの情報を探してみよう。",
        },
        props: (route) => ({ page: parseInt(route.query.page) || 1 }),
      },
      {
        path: "dmg",
        component: PokeDmg,
        meta: {
          title: "ダメージ計算ツール",
          description:
            "詳細なポケモンのバトルダメージ計算をサポート。対戦の準備に最適なツールで戦略を練り上げよう。",
        },
      },
      {
        path: "memo",
        component: PokeMemo,
        name: "PokeMemo",
        beforeEnter: (to, from, next) => {
          // ログインしていない場合はログインページにリダイレクト
          if (!localStorage.getItem("accessToken")) {
            store.commit(
              "auth/setRedirectMessage",
              "ログインしていないと利用できないページです。"
            );
            next("/login");
          } else {
            next();
          }
        },
        meta: {
          title: "ポケモンのメモ情報",
          description:
            "あなたのポケモンを記録する専用メモアプリ。対戦用に育成するポケモンの情報を保存しよう。",
        },
      },
      {
        path: "memo/update/:id",
        component: PokeMemoUpdateView,
        name: "PokeMemoUpdate",
        beforeEnter: (to, from, next) => {
          // ログインしていない場合はログインページにリダイレクト
          if (!localStorage.getItem("accessToken")) {
            store.commit(
              "auth/setRedirectMessage",
              "ログインしていないと利用できないページです。"
            );
            next("/login");
          } else {
            next();
          }
        },
        meta: {
          title: "ポケモンのメモ情報の編集",
          description:
            "あなたのポケモンを記録する専用メモアプリ。対戦用に育成するポケモンの情報を編集しよう。",
        },
      },
      {
        path: "rankmaker",
        component: RankMaker,
        name: "RankMaker",
        meta: {
          title: "ランクメーカー",
          description:
            "ポケモンのランクを作成するツール。自分だけのランクを作成し、他のユーザーと共有しよう。",
        },
      },
    ],
  },
];

export default routes;
