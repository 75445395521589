import store from "@/store";

// アカウント情報に紐づくルート情報を定義する
import TheProfile from "@/views/TheProfile.vue";
import TheEditProfile from "@/views/TheEditProfile.vue";

const authRoutes = [
  {
    path: "profile",
    component: TheProfile,
    beforeEnter: (to, from, next) => {
      // ログインしていない場合はログインページにリダイレクト
      if (!localStorage.getItem("accessToken")) {
        store.commit(
          "auth/setRedirectMessage",
          "ログインしていないと利用できないページです。"
        );
        next("/login");
      } else {
        next();
      }
    },
    meta: {
      title: "プロフィール",
      description: "あなたのポケプロジェクトのプロフィールを確認しよう。",
    },
  },
  {
    path: "profile/edit",
    component: TheEditProfile,
    name: "EditProfile",
    beforeEnter: (to, from, next) => {
      // ログインしていない場合はログインページにリダイレクト
      if (!localStorage.getItem("accessToken")) {
        store.commit(
          "auth/setRedirectMessage",
          "ログインしていないと利用できないページです。"
        );
        next("/login");
      } else {
        next();
      }
    },
    meta: {
      title: "プロフィール編集",
      description: "あなたのポケプロジェクトのプロフィールを編集しよう。",
    },
  },
];

export default authRoutes;
