<template>
  <div></div>
</template>

<script>
/*
雨と晴れの場合のみの天気補正値を計算
雪と砂はポケモンのステータスが変化するため、別途計算が必要
*/
export default {
  props: {
    weather: String,
    moveType: String,
  },
  watch: {
    weather: "calculateWeatherEffect",
    moveType: "calculateWeatherEffect",
  },
  methods: {
    calculateWeatherEffect() {
      let newWeatherEffect = 1; // 補正値の初期値
      if (this.moveType === "ほのお" && this.weather === "はれ") {
        newWeatherEffect = 1.5;
      } else if (this.moveType === "ほのお" && this.weather === "あめ") {
        newWeatherEffect = 0.5;
      } else if (this.moveType === "みず" && this.weather === "あめ") {
        newWeatherEffect = 1.5;
      } else if (this.moveType === "みず" && this.weather === "はれ") {
        newWeatherEffect = 0.5;
      } else {
        newWeatherEffect = 1;
      }
      // 親コンポーネントに補正値を送る
      this.$emit("update:weatherEffect", newWeatherEffect);
    },
  },
  // コンポーネントがマウントされた時に初期計算を実行
  mounted() {
    this.calculateWeatherEffect();
  },
};
</script>
