<template>
  <div class="flex justify-center items-center w-full py-5">
    <div class="relative w-2/3">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="ポケモン名で検索"
        class="w-full pl-3 pr-10 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
        @keyup.enter="fetchPokeMemos"
      />
      <span
        @click="fetchPokeMemos"
        class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer text-black"
      >
        <span class="icon-[mdi--search]" style="color: black"></span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isUserMemo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pokeMemos: [],
      searchQuery: "",
    };
  },
  methods: {
    async fetchPokeMemos() {
      if (!this.isUserMemo) {
        // 全ポケモンメモから、ポケモン名で検索
        try {
          this.$router.push({
            name: "SearchResult",
            query: { page: 1, search: this.searchQuery },
          });
        } catch (error) {
          console.error("Error fetching PokeMemos:", error);
          this.$toast.open({
            type: "error",
            message: "入力したポケモンが見つかりませんでした",
            position: "top",
            duration: 3000,
          });
        }
      } else {
        // ユーザーが投稿したポケモンメモから、ポケモン名で検索
        try {
          this.$router.push({
            name: "SearchResultUser",
            query: { page: 1, search: this.searchQuery },
          });
        } catch (error) {
          console.error("Error fetching PokeMemos:", error);
          this.$toast.open({
            type: "error",
            message: "入力したポケモンが見つかりませんでした",
            position: "top",
            duration: 3000,
          });
        }
      }
    },
  },
};
</script>
