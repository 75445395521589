<template>
  <div>
    <activate-email />
  </div>
</template>
<script>
import ActivateEmail from "@/components/auth/ActivateEmail.vue";
export default {
  components: {
    ActivateEmail,
  },
};
</script>
