<template>
  <div class="container mx-auto">
    <div class="flex items-center mb-4 border-b-2 border-gray-700">
      <span class="icon-[mdi--shield-outline] text-xl"></span>
      <div class="text-lg font-bold">防御側</div>
    </div>

    <div class="text-xs">ポケモン</div>
    <poke-name-suggest
      @pokemon-selected="handlePokemonSelected"
      :placeholder-text="'フシギダネ'"
    />
    <div
      v-if="selectedPokemon"
      class="flex items-center p-1 m-2 bg-white rounded-lg shadow-md"
    >
      <!-- 画像部分 (flex-shrink-0 でサイズを固定) -->
      <div class="flex-shrink-0">
        <a :href="selectedPokemon.poke_image_src">
          <img :src="selectedPokemon.poke_image_src" alt="" class="w-12 h-12" />
        </a>
      </div>
      <!-- 情報部分 (flex-grow で残りのスペースを埋める) -->
      <div class="flex-grow ml-4">
        <div class="text-base">
          {{ selectedPokemon.poke_name }} ({{ selectedPokemon.poke_type }},
          {{ selectedPokemon.poke_type2 }})
          <div
            v-if="selectedTeraType !== '未選択'"
            class="text-sm text-blue-500 font-semibold"
          >
            テラタイプ:{{ selectedTeraType }}
          </div>
        </div>
        <div class="text-xs">
          H:{{ selectedPokemon.poke_hp }} A:{{
            selectedPokemon.poke_attack
          }}
          B:{{ selectedPokemon.poke_defense }} C:{{
            selectedPokemon.poke_sp_atk
          }}
          D:{{ selectedPokemon.poke_sp_def }} S:{{
            selectedPokemon.poke_speed
          }}
          合計:{{ selectedPokemon.poke_base_stats }}
        </div>
      </div>
    </div>

    <div v-if="selectedPokemon">
      <abilities-selector
        :abilities="getAbilities(selectedPokemon)"
        :selected-pokemon="selectedPokemon"
        @ability-selected="handleAbilitySelected"
        @ability-reset="handleAbilityReset"
      />
    </div>

    <div
      class="shadow rounded border w-full mt-4 mr-2 p-2"
      v-if="selectedPokemon && selectedMove"
    >
      <CalcHP
        :modelPokeHP="selectedPokemon.poke_hp"
        :modelIV="Number(hIVValue)"
        :modelEV="Number(hEVValue)"
        :modelLevel="Number(LevelValue)"
        @update:modelValue="handleClacHpValue"
      />

      <div>
        <span class="text-sm"> HPのステータス: {{ calcHStatusValue }} </span>
      </div>
    </div>
    <div class="grid grid-cols-9 mt-4">
      <div class="col-span-4">
        <IVCounter
          :modelValue="Number(hIVValue)"
          :h-flag="true"
          @update:modelValue="hadleIVValue"
        />
      </div>
      <div class="col-span-1"></div>
      <div class="col-span-4">
        <EVCounter
          :modelValue="Number(hEVValue)"
          :h-flag="true"
          @update:modelValue="handleEVValue"
        />
      </div>
    </div>

    <div
      class="shadow rounded border w-full mt-6 mr-2 p-2"
      v-if="selectedPokemon && selectedMove"
    >
      <CalcStatus
        :defSide="true"
        :moveClass="selectedMove.move_class"
        :pokeStatus="selectedPokemon"
        :IVValue="Number(IVValue)"
        :EVValue="Number(EVValue)"
        :RankValue="Number(RankValue)"
        :LevelValue="Number(LevelValue)"
        :NatureModifierValue="Number(defNatureModifierValue)"
        :defPokeType1="pokeType1"
        :defPokeType2="poketype2"
        :weather="selectedWeather"
        :ruin="selectedRuin"
        @update:modelValue="handleDefStatusValue"
      />
      <div class="text-sm">防御側のステータス:{{ caluculatedStatusValue }}</div>
      <div class="flex justify-start items-center">
        <span class="icon-[mdi--pencil-box-outline]"></span>
        <div class="text-xs mt-1 ml-2">
          攻撃技によって、自動でBかDの計算を分けています
        </div>
      </div>
    </div>

    <div class="grid grid-cols-9 mt-4">
      <div class="col-span-4">
        <LevelCounter
          :modelValue="Number(LevelValue)"
          @update:modelValue="LevelValue = $event"
        />
      </div>
      <div class="col-span-1"></div>
      <div class="col-span-4">
        <RankCounter
          :modelValue="Number(RankValue)"
          @update:modelValue="handleRankNum"
        />
      </div>
    </div>

    <div class="grid grid-cols-9 mt-4">
      <div class="col-span-4">
        <IVCounter
          :modelValue="Number(IVValue)"
          @update:modelValue="IVValue = $event.value"
        />
      </div>
      <div class="col-span-1"></div>
      <div class="col-span-4">
        <!-- <EVCounter :modelValue="Number(EVValue)" @update:modelValue="EVValue = $event" /> -->
        <EVCounter
          :modelValue="Number(EVValue)"
          @update:modelValue="EVValue = $event.value"
        />
      </div>
    </div>

    <div class="grid grid-cols-9 mt-4">
      <div class="col-span-4">
        <ChoiceTeraType
          :value="selectedTeraType"
          @input="selectedTeraType = $event"
        />
        <span class="text-xs">テラタイプ:{{ selectedTeraType }}</span>
      </div>
      <div class="col-span-1"></div>
      <div class="col-span-4">
        <DefItemsList @defItemSelected="handleDefItem" />
        <span class="text-xs">アイテム:{{ defSelectedItem.name }}</span>
      </div>
    </div>

    <div class="mt-4">
      <NatureModifier
        :defNature="true"
        @update:modifier="handleModifierUpdate"
      />
    </div>

    <div class="grid grid-cols-2 mt-4">
      <div class="col-span-1">
        <PhysicalWall
          :move-class="selectedMove.move_class"
          @update:modelValue="physicalWallValue = $event"
        />
        <span class="text-xs">補正値:{{ physicalWallValue }}</span>
      </div>
      <div class="col-span-1">
        <SpecialWall
          :move-class="selectedMove.move_class"
          @update:modelValue="specialWallValue = $event"
        />
        <span class="text-xs">補正値:{{ specialWallValue }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// api
import PokeNameSuggest from "@/components/common/api/PokeNameSuggest.vue";

// commmon
import AbilitiesSelector from "@/components/dmg9/ability/AbilitiesSelector.vue";
import ChoiceTeraType from "@/components/dmg9/tera_type/ChoiceTeraType.vue";
import NatureModifier from "@/components/dmg9/nature/NatureModifier.vue";

// common/flag
import PhysicalWall from "@/components/dmg9/flag/PhysicalWall.vue";
import SpecialWall from "@/components/dmg9/flag/SpecialWall.vue";

// common/counter
import IVCounter from "@/components/dmg9/counter/IVCounter.vue";
import EVCounter from "@/components/dmg9/counter/EVCounter.vue";
import LevelCounter from "@/components/dmg9/counter/LevelCounter.vue";
import RankCounter from "@/components/dmg9/counter/RankCounter.vue";

// common/items
import DefItemsList from "@/components/dmg9/items/DefItemsList.vue";

// comon/calc_status
import CalcStatus from "@/components/dmg9/calc_status/CalcStatus.vue";
import CalcHP from "@/components/dmg9/calc_status/CalcHP.vue";

export default {
  components: {
    LevelCounter,
    IVCounter,
    EVCounter,
    NatureModifier,
    ChoiceTeraType,
    RankCounter,
    PokeNameSuggest,
    AbilitiesSelector,
    CalcStatus,
    CalcHP,
    DefItemsList,
    PhysicalWall,
    SpecialWall,
  },
  data() {
    return {
      isModalVisible: false,
      moveName: "",
      moveClass: "",
      burnCorrectValue: 1,
      hIVValue: 31,
      IVValue: 31,
      hEVValue: 0,
      EVValue: 252,
      defNatureModifierValue: 1.0,
      selectedTeraType: "未選択",
      LevelValue: 50,
      RankValue: 0,
      RankRealValue: 1,
      selectedPokemon: {
        id: 1,
        poke_ability: {
          name: "しんりょく",
          description:
            "HPが1/3以下になると、くさタイプのわざで攻撃するときのこうげき・とくこうが1.5倍になる。",
        },
        poke_ability2: null,
        poke_abi_hidden: {
          name: "ようりょくそ",
          description: "にほんばれのとき、すばやさが2倍になる。",
        },
        poke_name: "フシギダネ",
        poke_name_suggest: "フシギダネ, ふしぎだね, fushigidane",
        poke_image_src: "/media/poke_img/001.png",
        poke_number: 1,
        poke_type: "くさ",
        poke_type2: "どく",
        poke_hp: 45,
        poke_attack: 49,
        poke_defense: 49,
        poke_sp_atk: 65,
        poke_sp_def: 65,
        poke_speed: 45,
        poke_base_stats: 318,
      },
      selectedPokemonAbility: "",
      // caluculatedHStatusValue: "",
      // defSelectedItem: {
      //   name: "未選択",
      //   description: "",
      // },
      physicalWallValue: 1,
      specialWallValue: 1,
      // テラスタイプを加味したタイプ,計算にはこのタイプを使用
      pokeType1: "くさ",
      poketype2: "どく",
    };
  },
  computed: {
    // ストアの状態をリアクティブに反映するためにcomputedを使用
    selectedMove() {
      return this.$store.state.dmgAttack.selectedMove;
    },
    selectedWeather() {
      return this.$store.state.dmgDefense.selectedWeather;
    },
    selectedRuin() {
      return this.$store.state.dmgDefense.selectedRuin;
    },
    calcHStatusValue() {
      return this.$store.state.dmgDefense.defHStatus;
    },
    caluculatedStatusValue() {
      return this.$store.state.dmgDefense.defStatus;
    },
    defSelectedItem() {
      return this.$store.state.dmgDefense.defItems;
    },
  },
  watch: {
    selectedPokemon: "handlePokeType",
    selectedTeraType: "handleTypes",
  },
  methods: {
    handleClacHpValue(value) {
      this.$store.commit("dmgDefense/calcHStatus", value);
    },
    handleDefStatusValue(value) {
      this.$store.commit("dmgDefense/calcDefStatus", value);
    },
    handlePokeType() {
      /* テラスタイプが変更されるたびに、タイプが変わってしまうため */
      if (this.selectedTeraType === "未選択") {
        this.$store.commit("dmgDefense/setDefTypes", {
          defType1: this.selectedPokemon.poke_type,
          defType2: this.selectedPokemon.poke_type2,
          isTeraType: false,
        });
      } else {
        this.$store.commit("dmgDefense/setDefTypes", {
          defType1: this.selectedTeraType,
          defType2: null,
          isTeraType: true,
        });
      }
    },
    handleTypes(value) {
      // テラスタイプが変更された場合に、計算に用いるためのタイプを変更する
      if (value === "未選択") {
        this.$store.commit("dmgDefense/setDefTypes", {
          defType1: this.selectedPokemon.poke_type,
          defType2: this.selectedPokemon.poke_type2,
          isTeraType: false,
        });
      } else {
        this.$store.commit("dmgDefense/setDefTypes", {
          defType1: value,
          defType2: null,
          isTeraType: true,
        });
      }
    },
    handleDefItem(items) {
      this.$store.commit("dmgDefense/setDefItem", items);
    },
    showModal() {
      this.isModalVisible = true;
    },
    handleModifierUpdate(data) {
      // 攻撃性格補正値と防御性格補正値の更新処理
      if (data.atkNature) {
        this.AtkNatureModifierValue = data.value; // 攻撃性格補正値を更新
      } else if (data.defNature) {
        this.defNatureModifierValue = data.value; // 防御性格補正値を更新
      }
    },
    handlePokemonSelected(pokemon) {
      this.selectedPokemon = pokemon;
      this.selectedPokemonAbility = ""; // 新しいポケモンが選択されたら、以前のアビリティ選択をクリア
      this.$store.commit("dmgDefense/setSelectedDefPoke", pokemon);
    },
    handleAbilitySelected(ability) {
      this.selectedPokemonAbility = ability; // 選択されたアビリティを更新
    },
    handleAbilityReset() {
      this.selectedPokemonAbility = ""; // アビリティの選択をリセット
    },
    getAbilities(pokemon) {
      return [
        pokemon.poke_ability,
        pokemon.poke_ability2,
        pokemon.poke_abi_hidden,
      ].filter(Boolean); // 空の値を除外
    },
    handleRankNum(value) {
      this.RankValue = value.RankCount;
      this.RankRealValue = value.RankRealValue;
    },
    handleEVValue(value) {
      if (value.hFlag) {
        this.hEVValue = value.value;
      }
    },
    hadleIVValue(value) {
      if (value.hFlag) {
        this.hIVValue = value.value;
      }
    },
  },
};
</script>
