export default {
  namespaced: true,
  state() {
    return {
      // flag (やけど、壁...)
      burnCorrect: 1.0,
      reflectCorrect: 1.0,
      lightScreenCorrect: 1.0,
    };
  },
  mutations: {
    // flag (やけど、壁...)
    setBurnCorrect(state, value) {
      state.burnCorrect = value;
    },
    setReflectCorrect(state, value) {
      state.reflectCorrect = value;
    },
    setLightScreenCorrect(state, value) {
      state.specialWallCorrect = value;
    },
  },
};
