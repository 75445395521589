<template>
  <div></div>
</template>
<script>
export default {
  props: {
    Pokemon: Object,
    item: String,
    move: Object,
    typeMatchBonus: Number,
  },
  watch: {
    // propsを監視する正しい方法
    Pokemon: {
      handler() {
        this.calculateAndEmit();
      },
      deep: true, // オブジェクト内の変更も検出する
    },
    Item: function () {
      this.calculateAndEmit();
    },
    Move: {
      handler() {
        this.calculateAndEmit();
      },
      deep: true,
    },
  },
  methods: {
    calculateAndEmit() {
      this.$emit("update:atkItemNum", this.calculatedAtkItemNum);
    },
  },
  computed: {
    calculatedAtkItemNum() {
      let atkItemNum = 1;
      // 補正値の計算
      if (this.item) {
        if (this.item === "いのちのたま") {
          atkItemNum = 1.3;
        } else if (
          this.item === "こだわりハチマキ" &&
          this.Move.move_class === "物理"
        ) {
          atkItemNum = 1.5;
        } else if (
          this.item === "こだわりメガネ" &&
          this.Move.move_class === "特殊"
        ) {
          atkItemNum = 1.5;
        } else if (
          this.item === "たつじんのおび" &&
          this.typeMatchBonus === 2
        ) {
          atkItemNum = 1.2;
        } else if (
          this.item === "ちからのハチマキ" &&
          this.Move.move_class === "物理"
        ) {
          atkItemNum = 1.1;
        } else if (
          this.item === "ものしりメガネ" &&
          this.Move.move_class === "特殊"
        ) {
          atkItemNum = 1.1;
        } else if (
          this.item === "タイプ1.2倍系" &&
          this.typeMatchBonus === 1.2
        ) {
          atkItemNum = 1.2;
        } else if (
          (this.item === "ふといホネ" && this.Pokemon.name === "カラカラ") ||
          this.Pokemon.name === "ガラガラ"
        ) {
          atkItemNum = 2;
        } else if (
          this.item === "でんきだま" &&
          this.Pokemon.name === "ピカチュウ"
        ) {
          atkItemNum = 2;
        }
      }
      return atkItemNum;
    },
  },
};
</script>
