<template>
  <div class="flex justify-center items-center space-x-1 sticky">
    <button
      @click="currentPage > 1 && goToPage(currentPage - 1)"
      :disabled="!hasPreviousPage"
      class="px-2 py-1 rounded bg-blue-500 text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
    >
      前
    </button>
    <button
      v-for="page in pages"
      :key="page"
      @click="goToPage(page)"
      :class="[
        'px-3 py-1 rounded',
        currentPage === page
          ? 'bg-blue-500 text-white'
          : 'bg-gray-200 text-gray-700',
      ]"
    >
      {{ page }}
    </button>
    <button
      @click="hasNextPage && goToPage(currentPage + 1)"
      :disabled="!hasNextPage"
      class="px-2 py-1 rounded bg-blue-500 text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
    >
      次
    </button>
  </div>
</template>

<script>
/* 
DRFのページネーションクラスのAPIデータからページネーションのためのデータを受け取る前提で作成

*/
export default {
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    hasNextPage: {
      type: Boolean,
      required: true,
    },
    hasPreviousPage: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    pages() {
      const pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  methods: {
    goToPage(page) {
      this.$emit("pageChange", page);
    },
  },
};
</script>
