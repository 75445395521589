import { createRouter, createWebHistory } from "vue-router";
import { nextTick } from "vue";
import routes from "./router";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  nextTick(() => {
    updateMeta(to.meta);
  });
  next();
});

// ogpのmetaタグを更新するための関数
// コンポーネントごとにogpを切り分けているため、ogpの設定を行いたい場合は随時、各router.jsに追記する

function updateMeta(meta) {
  if (meta.title) {
    document.title = meta.title;
    const titleElement = document.querySelector('meta[property="og:title"]');
    if (titleElement) {
      titleElement.setAttribute("content", meta.title);
    }
  }
  if (meta.description) {
    const descriptionElement = document.querySelector(
      'meta[name="description"]'
    );
    const ogDescriptionElement = document.querySelector(
      'meta[property="og:description"]'
    );
    if (descriptionElement) {
      descriptionElement.setAttribute("content", meta.description);
    }
    if (ogDescriptionElement) {
      ogDescriptionElement.setAttribute("content", meta.description);
    }
  }
}

export default router;
