import { createStore } from "vuex";

// utility
import teraTypesIcon from "./modules/teraTypesIcon";

// auth
import auth from "./modules/auth";

// dmg app
import dmg from "./modules/dmg";
import dmgAttack from "./modules/dmgAttack";
import dmgDefense from "./modules/dmgDefense";
import dmgEnviron from "./modules/dmgEnviron";

// memo app
import updateMemo from "./modules/updateMemo";
import homeMemoSearch from "./modules/homeMemoSearch";
import homeMemoSearchUser from "./modules/homeMemoSearchUser";

// profile app
import profile from "./modules/profile";

export default createStore({
  modules: {
    dmg,
    dmgAttack,
    dmgDefense,
    dmgEnviron,
    homeMemoSearch,
    homeMemoSearchUser,
    updateMemo,
    teraTypesIcon,
    auth,
    profile,
  },
});
