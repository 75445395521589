<template>
  <div>
    <password-change />
  </div>
</template>
<script>
import PasswordChange from "@/components/auth/PasswordChange.vue";
export default {
  components: {
    PasswordChange,
  },
};
</script>
