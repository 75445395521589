<template>
  <div>
    <button
      @click="doSnsShare"
      class="flex items-center justify-center font-bold text-gray-800 p-4 m-4"
    >
      <span :class="snsShareInfo.shareIcon" class="w-8 h-8"></span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    snsName: {
      type: String,
      default: "",
    },
  },

  computed: {
    pageTitle() {
      return encodeURIComponent(document.title);
    },

    pageURL() {
      return encodeURIComponent(window.location.href);
    },

    hashTag() {
      return encodeURIComponent("ポケプロジェクト");
    },

    snsShareInfo() {
      const ret = {
        shareURL: "",
        shareIcon: "",
      };

      switch (this.snsName) {
        case "twitter":
          ret.shareURL = `https://twitter.com/intent/tweet?text=${this.pageTitle}&hashtags=${this.hashTag}&url=${this.pageURL}`;
          ret.shareIcon = "icon-[bi--twitter-x]";
          break;
        case "facebook":
          ret.shareURL = `https://www.facebook.com/sharer/sharer.php?u=${this.pageURL}`;
          ret.shareIcon = "icon-[bi--facebook]";
          break;
        case "line":
          ret.shareURL = `https://social-plugins.line.me/lineit/share?url=${this.pageURL}`;
          ret.shareIcon = "icon-[bi--line]";
          break;
        default:
          ret.shareURL = this.pageURL;
          ret.shareIcon = "";
          break;
      }
      return ret;
    },
  },

  methods: {
    doSnsShare() {
      window.open(this.snsShareInfo.shareURL, "_blank");
      // シェアが完了したら、モーダルを閉じる
      this.$emit("shared");
    },
  },
};
</script>
