<template>
  <div class="container mx-auto min-h-screen">
    <div class="flex justify-center items-center mb-6 space-x-3">
      <span class="icon-[mdi--podium-gold] w-10 h-10 text-yellow-500"></span>
      <h1 class="text-2xl font-bold text-center">ランクメーカー</h1>
    </div>

    <div class="text-gray-500 text-xs flex justify-center my-3">
      ※現在ベータ版となっています
    </div>
    <div class="flex justify-center mb-3">
      <RankMakerHowToUse />
    </div>
    <div
      ref="rankingContainer"
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-1"
    >
      <div
        v-for="(list, listIndex) in rankedLists"
        :key="listIndex"
        :class="borderClass(listIndex)"
      >
        <h2 class="text-lg font-bold text-center">
          Rank {{ rankLabels[listIndex] }}
        </h2>
        <draggable
          v-model="list.items"
          group="items"
          item-key="id"
          class="flex flex-wrap gap-4 p-2"
        >
          <template #item="{ element }">
            <div class="relative w-12 h-12">
              <img
                :src="element.image"
                :alt="element.name"
                class="w-full h-full object-cover rounded"
                crossorigin="anonymous"
              />
              <button
                @click="removeItem(list.items, element)"
                class="absolute top-0 right-0 w-3 h-3 rounded-full bg-red-600 text-white flex items-center justify-center"
              >
                &times;
              </button>
            </div>
          </template>
        </draggable>
      </div>
      <div v-if="showTag" class="text-sm text-blue-500 font-bold text-right">
        # ポケプロジェクト ランクメーカー
      </div>
    </div>

    <h2 class="text-lg font-bold mt-6">追加したポケモン</h2>
    <div class="p-2 bg-gray-100 rounded mt-2 min-h-[4rem]">
      <draggable
        :list="addPokemonList"
        group="items"
        item-key="id"
        class="flex flex-wrap gap-4 p-2"
      >
        <template #item="{ element }">
          <div class="relative w-12 h-12">
            <img
              :src="element.image"
              :alt="element.name"
              class="w-full h-full object-cover rounded"
            />
            <button
              @click="removeItem(addPokemonList, element)"
              class="absolute top-0 right-0 w-3 h-3 rounded-full bg-red-600 text-white flex items-center justify-center"
            >
              &times;
            </button>
          </div>
        </template>
      </draggable>
    </div>
    <PokeNameSuggest
      :placeholderText="'ポケモンを追加する'"
      :imageOnlyFlag="true"
      @pokemon-selected="handlePokeData"
      class="my-4"
    />
    <!-- <button @click="consoleList">配列の中身を見る</button> -->
    <div class="my-10">
      <button @click="saveAsCleanImage" class="round-long-button-border">
        画像を保存する
      </button>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import draggable from "vuedraggable";

import PokeNameSuggest from "@/components/common/api/PokeNameSuggest.vue";
import RankMakerHowToUse from "@/components/rank/RankMakerHowToUse.vue";

export default {
  components: {
    draggable,
    PokeNameSuggest,
    RankMakerHowToUse,
  },
  data() {
    return {
      rankedLists: [
        { id: "s", name: "S Rank", items: [] },
        { id: "a", name: "A Rank", items: [] },
        { id: "b", name: "B Rank", items: [] },
        { id: "c", name: "C Rank", items: [] },
        { id: "d", name: "D Rank", items: [] },
      ],
      addPokemonList: [],
      rankLabels: ["S", "A", "B", "C", "D"],
      showTag: false,
    };
  },
  methods: {
    handlePokeData(pokeData) {
      this.addPokemonList.push({
        id: Date.now(),
        name: pokeData.name,
        image: pokeData.image,
      });
    },
    removeItem(list, item) {
      const index = list.indexOf(item);
      if (index > -1) {
        list.splice(index, 1);
      }
    },
    borderClass(index) {
      const colors = [
        "border-yellow-400 text-yellow-400",
        "border-gray-300 text-gray-300",
        "border-orange-500 text-orange-500",
        "border-green-500 text-green-500",
        "border-blue-500 text-blue-500",
      ];
      return `${colors[index]} border-2 rounded pb-2 min-h-[8rem]`;
    },
    consoleList() {
      console.log("rankedLists", this.rankedLists);
    },
    async saveAsCleanImage() {
      this.showTag = true;
      const element = this.$refs.rankingContainer;
      await this.$nextTick();

      const buttons = element.querySelectorAll("button");
      buttons.forEach((button) => {
        button.style.display = "none";
      });
      const canvas = await html2canvas(element, {
        useCORS: true,
      });
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      const now = new Date();
      const formatData = now.toLocaleString("ja-JP");
      link.download = `poke_ranking_${formatData}.png`;
      link.click();
      buttons.forEach((button) => {
        button.style.display = "";
      });
      this.showTag = false;
    },
  },
};
</script>
