<template>
  <div class="h-screen mx-auto">
    <div class="flex justify-center">
      <form
        @submit.prevent="login"
        class="bg-white shadow-md rounded px-8 pt-6 pb-8"
      >
        <div class="flex items-center justify-center mb-5">
          <span class="icon-[mdi--login] w-10 h-10"> </span>
          <span class="ml-2">ログイン</span>
        </div>
        <div class="flex items-center mb-4">
          <span class="icon-[mdi--email] h-7 w-7 mr-3"></span>

          <input
            v-model="email"
            type="email"
            placeholder="メールを入力"
            autocomplete="email"
            required
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div class="flex items-center mb-4 relative">
          <span class="icon-[mdi--password] w-7 h-7 mr-3"></span>
          <input
            :type="passwordFieldType"
            v-model="password"
            placeholder="パスワードを入力"
            autocomplete="current-password"
            required
            class="shadow appearance-none border rounded w-full py-2 px-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <button
            type="button"
            @click="showPasswordToggle"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
          >
            <span v-if="showIcon" class="icon-[mdi--eye]"></span>
            <span v-else class="icon-[mdi--eye-off]"></span>
          </button>
        </div>
        <div class="flex items-center">
          <button
            class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            ログイン
          </button>
        </div>
      </form>
    </div>
    <div>
      <router-link to="/register" class="flex justify-center mt-5">
        <span class="text-blue-500">新規登録はこちら</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      showIcon: false,
    };
  },
  computed: {
    passwordFieldType() {
      return this.showIcon ? "text" : "password";
    },
  },
  methods: {
    async login() {
      try {
        const success = await this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password,
        });
        if (success) {
          this.$toast.open({
            message: "ログインに成功しました",
            position: "top",
            type: "success",
            duration: 3000,
          });
          // routerでリダイレクトすると設定が反映されないので、直接URLを指定する
          // this.$router.push("/home");
          window.location.href = "/home";
        }
      } catch (error) {
        this.$toast.open({
          message: error.message,
          position: "top",
          type: "error",
          duration: 3000,
        });
      }
    },
    showPasswordToggle() {
      this.showIcon = !this.showIcon;
    },
  },
  created() {
    // storeにリダイレクトメッセージがある場合は表示
    if (this.$store.state.auth.redirectMessage) {
      this.$toast.open({
        message: this.$store.state.auth.redirectMessage,
        position: "top",
        type: "info",
        duration: 3000,
      });
      // メッセージをクリアする
      this.$store.commit("auth/setRedirectMessage", "");
    }
  },
  mounted() {
    if (this.$store.getters["auth/isLoggedIn"]) {
      this.$router.push("/home");
    }

    if (this.$store.state.auth.redirectMessage) {
      this.$toast.open({
        message: this.$store.state.auth.redirectMessage,
        position: "top",
        type: "info",
        duration: 3000,
      });

      // メッセージをクリアする
      this.$store.commit("auth/setRedirectMessage", "");
    }
  },
};
</script>
