import axios from "axios";

// ログイン情報が不要なインスタンス
const publicAxios = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// ログイン情報が必要なインスタンス
const authAxios = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// アクセストークンを付与するインターセプター
authAxios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `JWT ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// アクセストークンの有効期限が切れた場合にリフレッシュトークンを使用して新しいアクセストークンを取得するインターセプター
authAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url === "/api/auth/jwt/refresh/"
    ) {
      // リフレッシュトークンの取得に失敗した場合は、ログインページにリダイレクト
      this.$store.dispath(
        "auth/setRedirectMessage",
        "認証関連の有効期限が切れました。再度ログインしてください。"
      );
      this.$router.push("/login");
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem("refreshToken");
        const response = await axios.post("/api/auth/jwt/refresh/", {
          refresh: refreshToken,
        });
        const newAccessToken = response.data.access;
        localStorage.setItem("accessToken", newAccessToken);
        originalRequest.headers["Authorization"] = `JWT ${newAccessToken}`;
        return authAxios(originalRequest);
      } catch (refreshError) {
        console.log(refreshError);
        this.$store.dispath(
          "auth/setRedirectMessage",
          "認証関連の有効期限が切れました。再度ログインしてください。"
        );
        this.$router.push("/login");
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export { publicAxios, authAxios };
