<template>
  <div class="h-screen">
    <div class="profile bg-white shadow-lg rounded-lg p-6">
      <div class="flex items-center mb-4 border-b-2 border-gray-700">
        <span class="icon-[mdi--folder-user] h-8 w-8 mr-3"></span>
        <div class="text-xl font-bold">プロフィール編集</div>
      </div>

      <!-- メールアドレス変更用のロジック出来上がったがたぶんつかわんやろってことでいったんコメントアウト -->
      <!-- <div class="mb-4">
        <div class="flex items-center space-x-5 mb-3">
          <label class="label-main">メールアドレス</label>
          <button @click="changeEmail" class="round-long-button-border mt-2">
            メールアドレスを変更
          </button>
        </div>
        <input
          v-model="profile.email"
          type="text"
          placeholder="emailを入力"
          class="input-block"
        />
      </div> -->

      <div class="mb-4">
        <div class="flex items-center space-x-5 mb-3">
          <label class="label-main">メール</label>
          <span class="text-xs text-gray-700"
            >(現在メールの変更には対応しておりません)</span
          >
        </div>
        <div class="border-block">
          {{ profile.email }}
        </div>
      </div>

      <div class="mb-4">
        <div class="flex items-center space-x-5 mb-3">
          <label class="label-main">ユーザーネーム</label>
          <button @click="changeName" class="round-long-button-border mt-2">
            ユーザーネームを変更
          </button>
        </div>
        <input
          v-model="profile.name"
          type="text"
          placeholder="ユーザーネームを入力"
          class="input-block"
        />
      </div>

      <div class="mb-4">
        <div class="flex items-center space-x-5 mb-3">
          <label class="label-main">説明文</label>
          <button @click="changeBio" class="round-long-button-border mt-2">
            説明文とお気に入りポケモンを変更
          </button>
        </div>
        <textarea
          v-model="profile.bio"
          placeholder="自己紹介を入力"
          rows="4"
          class="input-block"
        ></textarea>
      </div>
      <div class="mb-4">
        <label class="label-main"
          >好きなポケモン（選んだポケモンがサムネになります）</label
        >
        <poke-name-suggest
          :placeholder-text="profile.favorited_pokemon"
          :name-only-flag="true"
          @pokemon-selected="this.profile.favorited_pokemon = $event"
        />
      </div>
    </div>
    <div>
      <router-link to="/profile" class="flex justify-center mt-5">
        <span class="text-blue-500">プロフィールに戻る</span>
      </router-link>

      <router-link to="/password/change" class="flex justify-center mt-5">
        <span class="text-blue-500">パスワード変更はこちら</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import PokeNameSuggest from "@/components/common/api/PokeNameSuggest.vue";
import { authAxios } from "@/axios";
export default {
  components: {
    PokeNameSuggest,
  },
  data() {
    return {
      newProfile: {
        name: "",
        bio: "",
        favorited_pokemon: "",
      },
    };
  },
  computed: {
    profile() {
      return this.$store.getters["profile/getProfile"];
    },
  },
  methods: {
    async changeBio() {
      try {
        const response = await authAxios.put("/api/profile/update", {
          bio: this.profile.bio,
          favorited_pokemon: this.profile.favorited_pokemon,
        });
        console.log("put profile response", response);
        this.$toast.open({
          message: "説明文とお気に入りポケモンを更新しました",
          type: "success",
          position: "top",
        });
        this.$router.push("/profile");
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
        }
        this.$toast.open({
          message: "説明文とお気に入りポケモンの更新に失敗しました",
          type: "error",
          position: "top",
        });
      }
    },
    async changeName() {
      try {
        const response = await authAxios.put("/api/auth/users/me/", {
          name: this.profile.name,
          email: this.profile.email,
        });
        console.log("put profile response", response);
        this.$toast.open({
          message: "ユーザーネームを更新しました",
          type: "success",
          position: "top",
        });
        this.$router.push("/profile");
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
        }
        this.$toast.open({
          message: "ユーザーネームの更新に失敗しました",
          type: "error",
          position: "top",
        });
      }
    },
    async changeEmail() {
      try {
        const response = await authAxios.post("/api/auth/users/reset_email/", {
          email: this.profile.email,
        });
        console.log("put profile response", response);
        this.$toast.open({
          message:
            "メールアドレスの変更を確認しましたので。登録したメールより変更を完了してください",
          type: "success",
          position: "top",
          duration: 60000,
        });
        this.$router.push("/profile");
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
        }
        this.$toast.open({
          message: "メールアドレスの更新に失敗しました",
          type: "error",
          position: "top",
        });
      }
    },
  },
};
</script>
