<template>
  <div class="container">
    <get-profile />
  </div>
</template>

<script>
import GetProfile from "@/components/profile/GetProfile.vue";

export default {
  components: {
    GetProfile,
  },
};
</script>
