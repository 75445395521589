<template>
  <div class="container">
    <user-memo />
  </div>
</template>

<script>
import UserMemo from "@/components/home/user/UserMemo.vue";

export default {
  components: {
    UserMemo,
  },
};
</script>
