<template>
  <div>
    <rank-maker />
  </div>
</template>
<script>
import RankMaker from "@/components/rank/RankMaker.vue";
export default {
  components: {
    RankMaker,
  },
};
</script>
