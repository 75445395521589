<template>
  <div class="max-w-md mx-auto">
    <button @click="toggleModal">
      <span class="icon-[mdi--information] text-2xl"></span>
    </button>

    <TheModal
      :isVisible="isModalOpen"
      @update:isVisible="handleModalVisibility"
    >
      <template v-slot:default>
        <div class="flex justify-between p-1 m-2">
          <button
            @click="switchTab('info')"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            <span class="icon-[mdi--warning-circle-outline]"></span>
            お知らせ
          </button>
          <button
            @click="switchTab('bug')"
            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            <span class="icon-[mdi--warning-decagram-outline]"></span>
            バグ修正
          </button>
        </div>
        <div v-if="activeTab === 'info'" class="p-2 border-l-4 border-blue-500">
          <ul>
            <li v-for="info in announceInfo" :key="info.id" class="mb-4">
              <div class="text-gray-700 text-sm">{{ info.content }}</div>
              <small class="text-gray-500">{{ info.created }}</small>
              <hr />
            </li>
          </ul>
        </div>
        <div
          v-else-if="activeTab === 'bug'"
          class="p-2 border-l-4 border-red-500"
        >
          <ul>
            <li v-for="bug in announceBug" :key="bug.id" class="mb-4">
              <div class="text-gray-700 text-sm">{{ bug.content }}</div>
              <small class="text-gray-500">{{ bug.created }}</small>
              <hr />
            </li>
          </ul>
        </div>
      </template>
    </TheModal>
  </div>
</template>

<script>
import { publicAxios } from "@/axios";
import TheModal from "./TheModal.vue";

export default {
  components: {
    TheModal,
  },
  data() {
    return {
      isModalOpen: false,
      announceInfo: [],
      announceBug: [],
      activeTab: "info", // 初期タブを設定
    };
  },
  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
      if (this.isModalOpen) {
        this.fetchAnnounceInfo(); // モーダルを開くときにお知らせ情報をロード
        this.fetchAnnounceBug(); // モーダルを開くときにバグ情報をロード
      }
    },
    handleModalVisibility(newValue) {
      this.isModalOpen = newValue;
    },
    async fetchAnnounceInfo() {
      try {
        const response = await publicAxios.get("/api/announce/info");
        this.announceInfo = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchAnnounceBug() {
      try {
        const response = await publicAxios.get("/api/announce/bug");
        this.announceBug = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    switchTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style scoped>
button {
  margin: 5px;
}
</style>
