<template>
  <div class="space-y-3">
    <div class="md:flex md:space-x-4">
      <div class="md:w-1/2 md:pr-4">
        <AttackSide />
      </div>
      <div class="md:mt-0 md:w-1/2 md:pl-4">
        <DefenseSide />
      </div>
    </div>
    <div>
      <EnvironSide />
    </div>
    <div>
      <ResultSide />
    </div>
  </div>
</template>
<script>
import AttackSide from "@/components/dmg9/side/AttackSide.vue";
import DefenseSide from "@/components/dmg9/side/DefenseSide.vue";
import EnvironSide from "@/components/dmg9/side/EnvironSide.vue";
import ResultSide from "@/components/dmg9/side/ResultSide.vue";
export default {
  components: {
    AttackSide,
    DefenseSide,
    EnvironSide,
    ResultSide,
  },
};
</script>
