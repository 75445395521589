<template>
  <div>
    <button
      @click="isModalOpen = true"
      class="px-2 py-1 w-full bg-blue-500 text-white rounded text-sm hover:bg-blue-600"
    >
      <span class="text-sm">テラスタイプを選択</span>
    </button>
    <TheModal :isVisible="isModalOpen" @update:isVisible="isModalOpen = $event">
      <div class="grid grid-cols-2 gap-3 p-3">
        <button
          v-for="type in teraTypes"
          :key="type.value"
          :class="[
            'px-2 py-3 rounded hover:bg-blue-100 flex items-center',
            selectedTeraType === type.value
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200',
          ]"
          @click="selectType(type.value)"
        >
          <img
            :src="getTeraTypeImage(type.value)"
            :alt="type.label"
            class="w-6 h-6 mr-2"
          />
          {{ type.label }}
        </button>
      </div>
    </TheModal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TheModal from "@/components/common/TheModal.vue";
export default {
  components: { TheModal },
  props: { value: { type: String, default: null } },
  data() {
    return {
      isModalOpen: false,
      selectedTeraType: this.value,
      teraTypes: [
        { value: "未選択", label: "未選択" },
        { value: "ノーマル", label: "ノーマル" },
        { value: "ほのお", label: "ほのお" },
        { value: "みず", label: "みず" },
        { value: "でんき", label: "でんき" },
        { value: "くさ", label: "くさ" },
        { value: "こおり", label: "こおり" },
        { value: "かくとう", label: "かくとう" },
        { value: "どく", label: "どく" },
        { value: "じめん", label: "じめん" },
        { value: "ひこう", label: "ひこう" },
        { value: "エスパー", label: "エスパー" },
        { value: "むし", label: "むし" },
        { value: "いわ", label: "いわ" },
        { value: "ゴースト", label: "ゴースト" },
        { value: "ドラゴン", label: "ドラゴン" },
        { value: "あく", label: "あく" },
        { value: "はがね", label: "はがね" },
        { value: "フェアリー", label: "フェアリー" },
      ],
    };
  },
  computed: { ...mapGetters("teraTypesIcon", ["getImage"]) },
  methods: {
    selectType(type) {
      this.selectedTeraType = type;
      this.isModalOpen = false;
      this.$emit("input", this.selectedTeraType);
    },
    getTeraTypeImage(type) {
      const imageMap = {
        未選択: "noneAvailable",
        ノーマル: "teraNormal",
        ほのお: "teraFire",
        みず: "teraWater",
        でんき: "teraElec",
        くさ: "teraGrass",
        こおり: "teraIce",
        かくとう: "teraPhysical",
        どく: "teraPoison",
        じめん: "teraGround",
        ひこう: "teraFly",
        エスパー: "teraEsper",
        むし: "teraBugs",
        いわ: "teraRock",
        ゴースト: "teraGhost",
        ドラゴン: "teraDragon",
        あく: "teraDark",
        はがね: "teraSteel",
        フェアリー: "teraFairy",
      };
      return this.getImage(imageMap[type]);
    },
  },
};
</script>
