<template>
  <the-dropdown
    :items="dropdownItems"
    buttonText="天候を選択"
    @select="onSelect"
  >
  </the-dropdown>
</template>

<script>
import TheDropdown from "@/components/common/TheDropdown.vue";

export default {
  components: {
    TheDropdown,
  },
  data() {
    return {
      dropdownItems: [
        { label: "未選択", value: "未選択", description: "" },
        {
          label: "はれ",
          value: "はれ",
          description:
            "ほのおタイプの技が1.5倍。みずタイプの技が半減。こおり状態にならない",
        },
        {
          label: "あめ",
          value: "あめ",
          description: "みずタイプの技が1.5倍。ほのおタイプの技が半減",
        },
        {
          label: "すなあらし",
          value: "すなあらし",
          description:
            "いわ、じめん、はがねタイプ以外は、毎ターン最大HPの1/16のダメージ。いわタイプのポケモンはDが1.5倍",
        },
        {
          label: "ゆき",
          value: "ゆき",
          description: "こおりタイプのポケモンはBが1.5倍",
        },
      ],
    };
  },
  methods: {
    onSelect(item) {
      this.$store.commit("dmgEnviron/setSelectedWeather", item.value);
      // this.$emit("update:weather", item.value);
    },
  },
};
</script>
