<template>
  <div></div>
</template>

<script>
export default {
  props: {
    moveType: String,
    defPokeType1: String, // テラスタイプも加味したタイプ
    defPokeType2: String, // nullの場合もある
  },
  data() {
    return {
      typeEffectiveness: 1, // タイプ相性の補正値
    };
  },
  watch: {
    moveType: "calculateTypeEffectiveness",
    defPokeType1: "calculateTypeEffectiveness",
    defPokeType2: "calculateTypeEffectiveness",
  },
  methods: {
    calculateTypeEffectiveness() {
      const typeChart = {
        ノーマル: { いわ: 0.5, ゴースト: 0, はがね: 0.5 },
        ほのお: {
          ほのお: 0.5,
          みず: 0.5,
          くさ: 2,
          こおり: 2,
          むし: 2,
          いわ: 0.5,
          ドラゴン: 0.5,
          はがね: 2,
        },
        みず: {
          ほのお: 2,
          みず: 0.5,
          くさ: 0.5,
          じめん: 2,
          いわ: 2,
          ドラゴン: 0.5,
        },
        でんき: {
          みず: 2,
          でんき: 0.5,
          くさ: 0.5,
          じめん: 0,
          ひこう: 2,
          ドラゴン: 0.5,
        },
        くさ: {
          ほのお: 0.5,
          みず: 2,
          くさ: 0.5,
          どく: 0.5,
          じめん: 2,
          ひこう: 0.5,
          むし: 0.5,
          いわ: 2,
          ドラゴン: 0.5,
          はがね: 0.5,
        },
        こおり: {
          ほのお: 0.5,
          みず: 0.5,
          くさ: 2,
          こおり: 0.5,
          じめん: 2,
          ひこう: 2,
          ドラゴン: 2,
          はがね: 0.5,
        },
        かくとう: {
          ノーマル: 2,
          こおり: 2,
          どく: 0.5,
          ひこう: 0.5,
          エスパー: 0.5,
          むし: 0.5,
          いわ: 2,
          ゴースト: 0,
          あく: 2,
          はがね: 2,
          フェアリー: 0.5,
        },
        どく: {
          くさ: 2,
          どく: 0.5,
          じめん: 0.5,
          いわ: 0.5,
          ゴースト: 0.5,
          はがね: 0,
          フェアリー: 2,
        },
        じめん: {
          ほのお: 2,
          でんき: 2,
          くさ: 0.5,
          どく: 2,
          ひこう: 0,
          むし: 0.5,
          いわ: 2,
          はがね: 2,
        },
        ひこう: {
          でんき: 0.5,
          くさ: 2,
          かくとう: 2,
          むし: 2,
          いわ: 0.5,
          はがね: 0.5,
        },
        エスパー: {
          かくとう: 2,
          どく: 2,
          ゴースト: 1,
          エスパー: 0.5,
          あく: 0,
          はがね: 0.5,
        },
        むし: {
          ほのお: 0.5,
          くさ: 2,
          かくとう: 0.5,
          どく: 0.5,
          ひこう: 0.5,
          エスパー: 2,
          ゴースト: 0.5,
          あく: 2,
          はがね: 0.5,
          フェアリー: 0.5,
        },
        いわ: {
          ほのお: 2,
          こおり: 2,
          かくとう: 0.5,
          じめん: 0.5,
          ひこう: 2,
          むし: 2,
          はがね: 0.5,
        },
        ゴースト: { ノーマル: 0, エスパー: 2, ゴースト: 2, あく: 0.5 },
        ドラゴン: { ドラゴン: 2, はがね: 0.5, フェアリー: 0 },
        あく: {
          かくとう: 0.5,
          エスパー: 2,
          ゴースト: 2,
          あく: 0.5,
          フェアリー: 0.5,
        },
        はがね: {
          ほのお: 0.5,
          みず: 0.5,
          でんき: 0.5,
          こおり: 2,
          いわ: 2,
          はがね: 0.5,
          フェアリー: 2,
        },
        フェアリー: {
          ほのお: 0.5,
          かくとう: 2,
          どく: 0.5,
          ドラゴン: 2,
          あく: 2,
          はがね: 0.5,
        },
      };

      // 攻撃技のタイプに基づく防御ポケモンのタイプ1への効果
      const effectiveness1 = typeChart[this.moveType]?.[this.defPokeType1] ?? 1;
      // console.log(
      //   `タイプ1の相性補正値 :攻撃技(${this.moveType}) -> :守りタイプ1 (${this.defPokeType1}) = ${effectiveness1}`
      // );
      // defPokeType2がnullまたは空文字の場合は1、そうでなければ対応する効果を取得
      const effectiveness2 = this.defPokeType2
        ? typeChart[this.moveType]?.[this.defPokeType2] ?? 1
        : 1;
      // console.log(
      //   `タイプ2の相性補正値 :攻撃技(${this.moveType}) -> :守りタイプ2 (${this.defPokeType2}) = ${effectiveness2}`
      // );
      // 総合的なタイプ相性の補正値を計算
      this.typeEffectiveness = effectiveness1 * effectiveness2;
      // console.log(`タイプ1 * タイプ2 = ${this.typeEffectiveness}`);
      // 親コンポーネントに補正値を送る
      this.$emit("update:typeEffectiveness", this.typeEffectiveness);
    },
  },
  // コンポーネントがマウントされた時に初期計算を実行
  mounted() {
    this.calculateTypeEffectiveness();
  },
};
</script>
