<template>
  <div class="container">
    <edit-profile />
  </div>
</template>

<script>
import EditProfile from "@/components/profile/EditProfile.vue";

export default {
  components: {
    EditProfile,
  },
};
</script>
