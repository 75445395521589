import { initPokemon, initMove } from "./dmgInitValues";

// 攻撃サイドの初期値をここで設定
const initAtkStatus = 101; //フシギダネのAのステータス 技が物理か特殊かで変更
const initAtkTypes = { atkType1: "くさ", atkType2: "どく", isTeraType: false };

export default {
  namespaced: true,
  state() {
    return {
      // 攻撃サイド
      atkPokemon: initPokemon,
      selectedMove: initMove,
      atkAbility: "",
      atkStatus: initAtkStatus,
      atkLevel: 50,
      atkItems: { name: "未選択", description: "" },
      atkTypes: initAtkTypes,
    };
  },
  mutations: {
    // 攻撃サイド
    setSelectedAtkPoke(state, pokemon) {
      state.atkPokemon = pokemon;
    },
    setSelectedMove(state, move) {
      state.selectedMove = move; // 状態を更新するミューテーション
    },
    // アビリティーobjの中から選択した単一のアビリティー
    setAtkAbility(state, ability) {
      state.atkAbility = ability;
    },
    calcAtkStatus(state, value) {
      state.atkStatus = value;
    },
    setAtkLevelValue(state, value) {
      state.atkLevel = value;
    },
    setAtkItem(state, items) {
      state.atkItems = items;
    },
    // 2種類のタイプ obj
    setAtkTypes(state, types) {
      state.atkTypes = types;
    },
  },
  // 非同期の処理はここから、mutainsにコミット
  actions: {
    selectMove({ commit }, move) {
      commit("setSelectedMove", move);
    },
  },
};
