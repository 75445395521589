<template>
  <div class="flex items-center">
    <button @click="toggleIconValue">
      <span :class="iconClass" class="w-4 h-4 align-middle"></span>
      <span class="align-middle">リフレクター</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    moveClass: String,
  },
  data() {
    return {
      isReflect: false,
    };
  },
  computed: {
    iconClass() {
      return this.isReflect
        ? "icon-[mdi--circle-outline]"
        : "icon-[mdi--circle-off-outline]";
    },
    caluculateWallValue() {
      let wallValue = 1;
      if (this.isReflect && this.moveClass === "物理") {
        return wallValue * 0.5;
      } else {
        return wallValue;
      }
    },
  },
  methods: {
    toggleIconValue() {
      this.isReflect = !this.isReflect;
      const newValue = this.caluculateWallValue;
      this.$store.commit("dmg/setReflectCorrect", newValue);
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>
