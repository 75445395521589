<template>
  <div class="h-screen mx-auto">
    <div class="flex justify-center">
      <form
        @submit.prevent="register"
        class="bg-white shadow-md rounded px-8 pt-6 pb-8"
      >
        <div class="flex items-center justify-center mb-5">
          <span class="icon-[mdi--register] w-10 h-10"> </span>
          <span class="ml-2">ユーザー登録</span>
        </div>
        <div class="flex items-center mb-4">
          <span class="icon-[mdi--user-add] w-7 h-7 mr-3"></span>
          <input
            v-model="username"
            type="text"
            placeholder="ユーザー名(表示名)"
            required
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div class="flex items-center mb-4">
          <span class="icon-[mdi--email-plus] h-7 w-7 mr-3"></span>

          <input
            v-model="email"
            type="email"
            placeholder="メールを入力"
            autocomplete="email"
            required
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div class="flex items-center mb-4 relative">
          <span class="icon-[mdi--password-add] w-7 h-7 mr-3"></span>
          <input
            :type="passwordFieldType"
            v-model="password"
            placeholder="パスワードを入力"
            autocomplete="current-password"
            required
            class="shadow appearance-none border rounded w-full py-2 px-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <button
            type="button"
            @click="showPasswordToggle"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
          >
            <span v-if="showIcon" class="icon-[mdi--eye]"></span>
            <span v-else class="icon-[mdi--eye-off]"></span>
          </button>
        </div>
        <div class="flex items-center mb-4 relative">
          <span class="icon-[mdi--password-add-outline] w-7 h-7 mr-3"></span>
          <input
            :type="passwordFieldType"
            v-model="rePassword"
            placeholder="確認用パスワードを入力"
            autocomplete="current-password"
            required
            class="shadow appearance-none border rounded w-full py-2 px-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <button
            type="button"
            @click="showPasswordToggle"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
          >
            <span v-if="showIcon" class="icon-[mdi--eye]"></span>
            <span v-else class="icon-[mdi--eye-off]"></span>
          </button>
        </div>
        <div class="flex items-center">
          <button
            class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
            :disabled="isProcessing"
          >
            登録
          </button>
        </div>
      </form>
    </div>
    <div class="mt-5 space-y-5">
      <router-link to="/login" class="items-center flex justify-center">
        <span class="text-blue-500">ログインはこちら</span>
      </router-link>
      <span
        @click="resendMail"
        class="items-center flex justify-center hover:cursor-pointer"
      >
        <span class="text-blue-500">メールが届かない場合はこちら</span>
      </span>
    </div>
  </div>
</template>

<script>
import { publicAxios } from "@/axios";

export default {
  data() {
    return {
      email: "",
      password: "",
      rePassword: "",
      username: "",
      showIcon: false,
      isProcessing: false,
    };
  },
  computed: {
    passwordFieldType() {
      return this.showIcon ? "text" : "password";
    },
  },
  methods: {
    async register() {
      this.isProcessing = true;
      this.$toast.open({
        message: "現在処理中です。少々お待ちください。",
        position: "top",
        type: "info",
        duration: 5000,
      });
      try {
        const response = await publicAxios.post("/api/auth/users/", {
          name: this.username,
          email: this.email,
          password: this.password,
          re_password: this.rePassword,
        });
        console.log(response.data);
        this.$toast.open({
          message:
            "登録したメールアドレスに確認メールを送信しました。メール内のリンクをクリックして本登録を完了してください。",
          position: "top",
          type: "success",
          duration: 5000,
        });
      } catch (error) {
        console.error(error);
        let errorMessage =
          "ユーザー登録中にエラーが発生しました。もう一度お試しください。";
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          if (errorData.name && errorData.name[0]) {
            errorMessage = errorData.name[0];
          } else if (errorData.email && errorData.email[0]) {
            errorMessage = errorData.email[0];
          } else if (errorData.password && errorData.password[0]) {
            errorMessage = errorData.password[0];
          } else if (
            errorData.non_field_errors &&
            errorData.non_field_errors[0]
          ) {
            errorMessage = "パスワードが一致しません。もう一度お試しください。";
          }
        }
        this.$toast.open({
          message: errorMessage,
          position: "top",
          type: "error",
          duration: 5000,
        });
      } finally {
        this.isProcessing = false;
      }
    },
    async resendMail() {
      try {
        const response = await publicAxios.post(
          "/api/auth/users/resend_activation/",
          {
            email: this.email,
          }
        );
        console.log(response.data);
        this.$toast.open({
          message:
            "登録したメールアドレスに確認メールを再送信しました。メール内のリンクをクリックして本登録を完了してください。",
          position: "top",
          type: "success",
          duration: 5000,
        });
      } catch (error) {
        console.error(error);
        let errorMessage =
          "メール再送信中にエラーが発生しました。もう一度お試しください。";
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          if (errorData.email && errorData.email[0]) {
            errorMessage = "メールアドレスは必須項目です。";
          }
        }
        this.$toast.open({
          message: errorMessage,
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    showPasswordToggle() {
      this.showIcon = !this.showIcon;
    },
  },
};
</script>
