<template>
  <div class="h-screen container mx-auto">
    <div
      class="border-2 border-gray-500 rounded-md fixed z-49 bottom-0 mx-1 left-0 right-0 bg-white"
    >
      <div class="flex items-center">
        <span class="icon-[mdi--checkbox-outline] text-xl"></span>
        <div class="text-lg font-bold">結果</div>
      </div>
      <div>
        <dmg-bar :def-hp="selectedDefHStatus" :final-dmg="finalDmg" />
      </div>
    </div>

    <base-calc
      :atk-status="selectedAtkStatus"
      :atk-level="selectedAtkLevel"
      :def-status="selectedDefStatus"
      :move-power="selectedMove.move_power"
      @updateDamage="baseDmg = $event"
    />

    <div>
      <weather-correction
        :weather="selectedWeather"
        :move-type="selectedMove.move_type"
        @update:weatherEffect="calcWetherNum = $event"
      />
    </div>

    <div>
      <field-correction
        :field="selectedField"
        :move-type="selectedMove.move_type"
        @update:fieldEffect="calcFieldNum = $event"
      />
    </div>

    <div>
      <type-match-correction
        :move-type="selectedMove.move_type"
        :def-poke-type1="defPokeTypes.defType1"
        :def-poke-type2="defPokeTypes.defType2"
        @update:typeEffectiveness="calcTypeMatchNum = $event"
      />
    </div>

    <div>
      <compatibility-correction
        :is-tera-type="atkPokeTypes.isTeraType"
        :move-type="selectedMove.move_type"
        :plane-atk-type1="selectedAtkPokemon.poke_type"
        :plane-atk-type2="selectedAtkPokemon.poke_type2"
        :atk-poke-type1="atkPokeTypes.atkType1"
        :atk-poke-type2="atkPokeTypes.atkType2"
        :atk-ability="selectedAtkAbility.name"
        @update:modelValue="calcTypeCompatibilityNum = $event"
      />
    </div>
    <final-dmg
      :base-dmg="baseDmg"
      :weather-num="calcWetherNum"
      :field-num="calcFieldNum"
      :type-match-num="calcTypeMatchNum"
      :type-compatibility-num="calcTypeCompatibilityNum"
      @calculatedDamage="finalDmg = $event"
    />

    <code>
      <p>デバック用に、コメントを残す</p>
      <br />
      // 攻撃サイド <br />
      atkStatus: {{ selectedAtkStatus }}<br />
      atkType1: {{ atkPokeTypes.atkType1 }}<br />
      atkType2: {{ atkPokeTypes.atkType2 }}<br />
      atk is teraType: {{ atkPokeTypes.isTeraType }}<br />
      atkLevel: {{ selectedAtkLevel }}<br />
      movePower: {{ selectedMove.move_power }}<br />
      atk Ability: {{ selectedAtkAbility.name }}<br />
      <br />
      // 防御サイド <br />
      defHStatus: {{ selectedDefHStatus }}<br />
      defStatus: {{ selectedDefStatus }}<br />
      deftype1: {{ defPokeTypes.defType1 }}<br />
      deftype2: {{ defPokeTypes.defType2 }}<br />
      def is teraType: {{ defPokeTypes.isTeraType }}<br />
      <br />
      // flag <br />
      burnCorrect: {{ flagBurnCorerct }}<br />
      reflectCorrect: {{ flagReflectCorrect }}<br />
      lightScreenCorrect: {{ flagLightScreenCorrect }}<br />
      <br />
      // 計算補正値 <br />
      baseDmg: {{ baseDmg }}<br />
      calcTypeMatchNum: {{ calcTypeMatchNum }}<br />
      clacWetherNum: {{ calcWetherNum }}<br />
      calcFieldNum: {{ calcFieldNum }}<br />
      calcTypeCompatibilityNum: {{ calcTypeCompatibilityNum }}<br />
      <br />
      // 最終ダメージ <br />
      finalDmg: {{ finalDmg }}<br />
    </code>
  </div>
</template>

<script>
/*
 *最終計算を行うコンポーネント
 *必要なら各データをstoreに登録
 */
import BaseCalc from "@/components/dmg9/calc/BaseCalc.vue";
import FinalDmg from "@/components/dmg9/calc/FinalDmg.vue";
import CompatibilityCorrection from "@/components/dmg9/calc/CompatibilityCorrection.vue";
import WeatherCorrection from "@/components/dmg9/calc/WeatherCorrection.vue";
import FieldCorrection from "@/components/dmg9/calc/FieldCorrection.vue";
import TypeMatchCorrection from "@/components/dmg9/calc/TypeMatchCorrection.vue";

import DmgBar from "@/components/dmg9/dmg_bar/DmgBar.vue";

export default {
  components: {
    BaseCalc,
    FinalDmg,
    CompatibilityCorrection,
    WeatherCorrection,
    FieldCorrection,
    TypeMatchCorrection,
    DmgBar,
  },
  data() {
    return {
      baseDmg: null,
      calcWetherNum: null,
      calcFieldNum: null,
      calcTypeMatchNum: null,
      calcTypeCompatibilityNum: null,
      finalDmg: null,
    };
  },
  computed: {
    // 攻撃サイド
    selectedAtkPokemon() {
      return this.$store.state.dmgAttack.atkPokemon;
    },
    selectedMove() {
      return this.$store.state.dmgAttack.selectedMove;
    },
    selectedAtkStatus() {
      return this.$store.state.dmgAttack.atkStatus;
    },
    selectedAtkAbility() {
      return this.$store.state.dmgAttack.atkAbility;
    },
    atkPokeTypes() {
      return this.$store.state.dmgAttack.atkTypes;
    },
    selectedAtkTypes() {
      return this.$store.state.dmgAttack.atkTypes;
    },
    selectedAtkLevel() {
      return this.$store.state.dmgAttack.atkLevel;
    },

    // 防御サイド
    selectedDefPokemon() {
      return this.$store.state.dmgDefense.defPokemon;
    },
    selectedDefHStatus() {
      return this.$store.state.dmgDefense.defHStatus;
    },
    selectedDefStatus() {
      return this.$store.state.dmgDefense.defStatus;
    },
    defPokeTypes() {
      return this.$store.state.dmgDefense.defTypes;
    },

    // 環境その他
    selectedWeather() {
      return this.$store.state.dmgEnviron.selectedWeather;
    },
    selectedField() {
      return this.$store.state.dmgEnviron.selectedField;
    },

    // flag(やけど、壁etc.)
    flagBurnCorerct() {
      return this.$store.state.dmg.burnCorrect;
    },
    flagReflectCorrect() {
      return this.$store.state.dmg.reflectCorrect;
    },
    flagLightScreenCorrect() {
      return this.$store.state.dmg.lightScreenCorrect;
    },
  },
  methods: {},
};
</script>
