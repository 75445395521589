import { createApp } from "vue";
import "./assets/main.css";

// Vue official
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";

//グローバルに使用するコンポーネント
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import VueProgressBar from "@aacassandra/vue3-progressbar";

// トークンの有効期限を監視するjsファイル(ぶっちゃけないほうがいいかも)
// import { initializeAuth } from "./components/auth/authService";

const options = {
  color: "#87E0DD",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  position: "relative",
  inverse: false,
};

// トークンの有効期限を監視する
// initializeAuth();

const app = createApp(App);

// Vue official library
app.use(router);
app.use(store);

// Third party library
app.use(ToastPlugin);
app.use(VueProgressBar, options);

app.mount("#app");
