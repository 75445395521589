export default {
  namespaced: true,
  state() {
    return {
      // 環境、その他
      selectedWeather: "未選択",
      selectedField: "未選択",
      selectedRuin: "未選択",
    };
  },
  mutations: {
    // 環境、その他
    setSelectedWeather(state, weather) {
      state.selectedWeather = weather;
    },
    setSelectedField(state, field) {
      state.selectedField = field;
    },
    setSelectedRuin(state, ruin) {
      state.selectedRuin = ruin;
    },
  },
};
