<template>
  <div></div>
  <!-- 最低限の空のテンプレート -->
</template>
<script>
export default {
  props: {
    modelPokeHP: Number,
    modelIV: Number,
    modelEV: Number,
    modelLevel: Number,
  },
  computed: {
    realHP() {
      return (
        Math.floor(
          ((this.modelPokeHP * 2 + this.modelIV + this.modelEV / 4) *
            this.modelLevel) /
            100
        ) +
        10 +
        this.modelLevel
      );
    },
  },
  watch: {
    realHP() {
      this.$emit("update:modelValue", this.realHP);
    },
  },
  mounted() {
    this.$emit("update:modelValue", this.realHP);
  },
};
</script>
