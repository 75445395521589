<template>
  <div class="flex items-center">
    <button @click="toggleIconValue">
      <span :class="iconClass" class="w-5 h-5 align-middle"></span>
      <span class="align-middle">やけど</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    moveName: String,
    moveClass: String,
  },
  data() {
    return {
      hasBurned: false,
    };
  },
  computed: {
    iconClass() {
      return this.hasBurned ? "icon-[mdi--fire]" : "icon-[mdi--fire-off]";
    },
    calculateBurnCorrectValue() {
      let burnModifier = 1;
      if (this.hasBurned && this.moveClass === "物理") {
        return burnModifier * 0.5;
      } else if (this.hasBurned && this.moveName === "からげんき") {
        return burnModifier;
      } else {
        return burnModifier;
      }
    },
  },
  methods: {
    toggleIconValue() {
      this.hasBurned = !this.hasBurned;
      const newValue = this.calculateBurnCorrectValue;
      this.$store.commit("dmg/setBurnCorrect", newValue);
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>
