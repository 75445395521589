<template>
  <div>
    <input
      v-model="searchQuery"
      @input="fetchPokemon"
      :placeholder="placeholderText"
      class="w-full p-1 mr-3 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
    />
    <div v-if="pokemonList.length" class="mt-2">
      <ul class="bg-white rounded-md divide-y divide-gray-200 shadow">
        <li
          v-for="(pokemon, index) in pokemonList"
          :key="index"
          class="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
          @click="selectPokemon(pokemon)"
        >
          <!-- 画像部分 (flex-shrink-0 でサイズを固定) -->
          <div class="flex-shrink-0">
            <img :src="pokemon.poke_image_src" alt="" class="w-12 h-12" />
            <!-- 画像サイズを調整 -->
          </div>
          <!-- 情報部分 (flex-grow で残りのスペースを埋める) -->
          <div class="flex-grow ml-4">
            <div class="text-base">
              {{ pokemon.poke_name }} ({{ pokemon.poke_type }},
              {{ pokemon.poke_type2 }})
            </div>
            <div class="text-xs">
              H:{{ pokemon.poke_hp }} A:{{ pokemon.poke_attack }} B:{{
                pokemon.poke_defense
              }}
              C:{{ pokemon.poke_sp_atk }} D:{{ pokemon.poke_sp_def }} S:{{
                pokemon.poke_speed
              }}
              合計:{{ pokemon.poke_base_stats }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { publicAxios } from "@/axios";

export default {
  props: {
    placeholderText: {
      type: String,
      default: "ポケモン名で検索",
    },
    nameOnlyFlag: {
      type: Boolean,
      default: false,
    },
    imageOnlyFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchQuery: "",
      pokemonList: [],
    };
  },
  methods: {
    async fetchPokemon() {
      if (this.searchQuery.length >= 3) {
        try {
          const response = await publicAxios.get("/api/pokemons/", {
            params: { search: this.searchQuery },
          });
          this.pokemonList = response.data.slice(0, 5);
          console.log(JSON.stringify(response.data, null, 2));
        } catch (error) {
          console.error(error);
        }
      }
    },
    selectPokemon(pokemon) {
      this.searchQuery = pokemon.poke_name;
      this.pokemonList = [];
      if (this.nameOnlyFlag) {
        this.$emit("pokemon-selected", pokemon.poke_name);
      } else if (this.imageOnlyFlag) {
        // ポケモン名と画像URLを返す
        this.$emit("pokemon-selected", {
          name: pokemon.poke_name,
          image: pokemon.poke_image_src,
        });
        this.searchQuery = "";
      } else {
        this.$emit("pokemon-selected", pokemon);
      }
    },
  },
};
</script>
