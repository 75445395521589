<template>
  <div>
    <div v-if="isLoggedin" class="flex items-center space-x-2">
      <span
        :class="{
          'icon-[mdi--favorite] text-red-500': isFavorited,
          'icon-[mdi--favorite-border] text-gray-400': !isFavorited,
        }"
        @click="toggleFavorite"
        class="cursor-pointer p-2 shadow w-6 h-6 ml-5 my-3"
      ></span>
      <div class="text-gray-700 font-semibold">{{ totalFavorites }}</div>
    </div>
    <div v-else class="flex items-center space-x-3 text-sm">
      <router-link to="/login" class="text-blue-400 ml-5 my-3"
        >ログインしてお気に入り登録</router-link
      >
      <div class="text-gray-700 font-semibold">
        お気に入りの数: {{ totalFavorites }}
      </div>
    </div>
  </div>
</template>

<script>
import { authAxios, publicAxios } from "@/axios";
export default {
  props: {
    memoId: {
      type: Number,
      required: true,
    },
    fetchInitFlag: {
      type: Boolean,
      default: false,
    },
    // お気に入り数をコンポーネントから受け取れる場合はそれを使用する
    // pokeMemoのテーブル自体にfavorite_countを持たせているので、ほとんどの場合で、わざわざfetchしなくてもOK
    propFavoriteCount: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      isFavorited: false,
      totalFavorites:
        this.propFavoriteCount !== null ? this.propFavoriteCount : 0,
    };
  },
  computed: {
    isLoggedin() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    userId() {
      const userData = this.$store.getters["auth/userData"];
      return userData ? userData.id : null;
    },
  },
  methods: {
    async toggleFavorite() {
      const originalState = this.isFavorited;
      this.isFavorited = !this.isFavorited;
      try {
        let response;
        if (this.isFavorited) {
          response = await authAxios.post(
            `/api/pokememo/favorite/${this.memoId}`
          );
          if (response.status >= 200 && response.status < 300) {
            this.totalFavorites += 1;
          } else {
            throw new Error("Failed to favorite");
          }
        } else {
          response = await authAxios.delete(
            `/api/pokememo/favorite/${this.memoId}`
          );
          if (response.status >= 200 && response.status < 300) {
            this.totalFavorites -= 1;
          } else {
            throw new Error("Failed to unfavorite");
          }
        }
      } catch (error) {
        console.error(error);
        this.isFavorited = originalState; // 失敗した場合は元の状態に戻す
        this.$toast.open({
          message: "いいね機能の作動中にエラーが発生しました",
          type: "error",
          position: "top",
        });
      }
    },
    async fetchInitFavorite() {
      try {
        if (this.isLoggedin) {
          const response = await authAxios.get(
            `/api/pokememo/favorite/${this.memoId}/${this.userId}`
          );
          this.isFavorited = response.data.is_favorited_by_user;
        }
      } catch (error) {
        console.error(error);
        this.$toast.open({
          message: "いいね状態の取得に失敗しました",
          type: "error",
          position: "top",
        });
      }
    },
    async fetchFavoriteCount() {
      // 親コンポーネントから、いいね数を受け取っていない場合のみfetch
      if (this.propFavoriteCount === null) {
        try {
          const response = await publicAxios.get(
            `/api/pokememo/favorite/count/${this.memoId}`
          );
          this.totalFavorites = response.data.favorite_count;
        } catch (error) {
          console.error(error);
          this.$toast.open({
            message: "いいね数の取得に失敗しました",
            type: "error",
            position: "top",
          });
        }
      }
    },
  },
  created() {
    if (this.fetchInitFlag) {
      this.fetchInitFavorite();
    }
    this.fetchFavoriteCount();
  },
};
</script>
