<template>
  <the-dropdown
    :items="dropdownItems"
    buttonText="わざわい系を選択"
    @select="onSelect"
  >
  </the-dropdown>
</template>

<script>
import TheDropdown from "@/components/common/TheDropdown.vue";

export default {
  components: {
    TheDropdown,
  },
  data() {
    return {
      dropdownItems: [
        { label: "未選択", value: "未選択" },
        {
          label: "わざわいのおふだ",
          value: "わざわいのおふだ",
          description: "自分以外のAが3/4倍 (選択するとAを0.75倍にする)",
        },
        {
          label: "わざわいのつるぎ",
          value: "わざわいのつるぎ",
          description: "自分以外のBが3/4倍 (選択するとBを0.75倍にする)",
        },
        {
          label: "わざわいのうつわ",
          value: "わざわいのうつわ",
          description: "自分以外のCが3/4倍 (選択するとCを0.75倍にする)",
        },
        {
          label: "わざわいのたま",
          value: "わざわいのたま",
          description: "自分以外のDが3/4倍 (選択するとDを0.75倍にする)",
        },
      ],
    };
  },
  methods: {
    onSelect(value) {
      this.$store.commit("dmgEnviron/setSelectedRuin", value.value);
      // this.$emit("update:ruin", value.value);
    },
  },
};
</script>
