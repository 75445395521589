<template>
  <div class="mx-auto">
    <div class="sticky top-0 bg-white mb-5 z-40">
      <the-pagination
        :totalPages="totalPages"
        :currentPage="currentPage"
        :hasNextPage="hasNextPage"
        :hasPreviousPage="hasPreviousPage"
        @pageChange="handlePageChange"
      />
    </div>
    <div class="my-5">
      <memo-input-fetch />
    </div>
    <BackToTopArrow />
    <div class="flex items-center mb-8 border-b-2 border-gray-700">
      <span class="icon-[mdi--format-list-bulleted] text-xl"></span>
      <div class="text-lg font-bold">みんなのポケモンリスト</div>
    </div>

    <div class="text-right space-x-2">
      <select
        v-model="selectedSort"
        class="border p-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        @change="handleSortChange"
      >
        <option value="-updated_at">更新日（降順）</option>
        <option value="updated_at">更新日（昇順）</option>
        <option value="-created_at">作成日（降順）</option>
        <option value="created_at">作成日（昇順）</option>
        <option value="-favorited_count">お気に入り数（降順）</option>
        <option value="favorited_count">お気に入り数（昇順）</option>
      </select>
      <select
        v-model="selectedGeneration"
        class="border p-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        @change="handleSortChange"
      >
        <option value="9">第9世代SV</option>
      </select>
    </div>
    <div class="memo-card-container">
      <div class="flex flex-wrap -mx-2">
        <div
          v-for="pokemon in pokemonList"
          :key="pokemon.id"
          class="w-full sm:w-1/2 md:w-1/3 px-2 mb-4"
        >
          <memo-card :memo-info="pokemon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { publicAxios } from "@/axios";
import BackToTopArrow from "@/components/common/BackToTopArrow.vue";
import ThePagination from "@/components/common/ThePagination.vue";
import MemoCard from "./MemoCard.vue";
import MemoInputFetch from "./MemoInputFetch.vue";

export default {
  components: {
    BackToTopArrow,
    ThePagination,
    MemoCard,
    MemoInputFetch,
  },
  data() {
    return {
      pokemonList: [],
      totalPages: 0,
      currentPage: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      selectedSort: "-updated_at",
      selectedGeneration: 9,
    };
  },
  methods: {
    async fetchPokemonList(page = 1) {
      try {
        const url = `/api/pokememo/list?page=${page}&ordering=${this.selectedSort}&generation=${this.selectedGeneration}`;
        const response = await publicAxios.get(url);
        this.pokemonList = response.data.results;
        // console.log("pokemonList", this.pokemonList);
        this.totalPages = Math.ceil(response.data.count / 10);
        this.currentPage = page;
        this.hasNextPage = response.data.next !== null;
        this.hasPreviousPage = response.data.previous !== null;
      } catch (error) {
        console.error("ポケモンリストの取得エラー:", error);
        // this.$toast.open({
        //   message: "ポケモンリストの取得に失敗しました",
        //   type: "error",
        //   position: "top",
        // });
      }
    },
    handlePageChange(page) {
      this.fetchPokemonList(page);
    },
    handleSortChange() {
      this.currentPage = 1; // ソート変更時にページをリセット
      this.fetchPokemonList(this.currentPage);
    },
  },

  created() {
    this.fetchPokemonList();
  },
};
</script>
