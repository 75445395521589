export default {
  namespaced: true,
  state: {
    images: {
      noneAvailable: "/media/tera_types/non-available.jpg",
      teraBugs: "/media/tera_types/tera-bugs.jpg",
      teraElec: "/media/tera_types/tera-elec.jpg",
      teraDark: "/media/tera_types/tera-dark.jpg",
      teraDragon: "/media/tera_types/tera-dragon.jpg",
      teraEsper: "/media/tera_types/tera-esper.jpg",
      teraFairy: "/media/tera_types/tera-fairy.jpg",
      teraFire: "/media/tera_types/tera-fire.jpg",
      teraFly: "/media/tera_types/tera-fly.jpg",
      teraGhost: "/media/tera_types/tera-ghost.jpg",
      teraGrass: "/media/tera_types/tera-grass.jpg",
      teraGround: "/media/tera_types/tera-ground.jpg",
      teraIce: "/media/tera_types/tera-ice.jpg",
      teraNormal: "/media/tera_types/tera-normal.jpg",
      teraPhysical: "/media/tera_types/tera-physical.jpg",
      teraPoison: "/media/tera_types/tera-poison.jpg",
      teraRock: "/media/tera_types/tera-rock.jpg",
      teraSteel: "/media/tera_types/tera-steel.jpg",
      teraWater: "/media/tera_types/tera-water.jpg",
    },
  },
  getters: {
    getImage: (state) => (imageName) => {
      return state.images[imageName];
    },
  },
};
