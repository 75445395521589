import { initPokemon } from "./dmgInitValues";

// 防御サイドの初期値
const initDefHStatus = 120; // フシギダネのHPのステータス
const initDefStatus = 101; // フシギダネのBのステータス 技が物理か特殊かで変更
const initDefTypes = { defType1: "くさ", defType2: "どく", isTeraType: false };

export default {
  namespaced: true,
  state() {
    return {
      // 防御サイド
      defPokemon: initPokemon,
      defHStatus: initDefHStatus,
      defStatus: initDefStatus,
      defItems: { name: "未選択", description: "" },
      defTypes: initDefTypes,
    };
  },
  mutations: {
    // 防御サイド
    setSelectedDefPoke(state, pokemon) {
      state.defPokemon = pokemon;
    },
    calcHStatus(state, value) {
      state.defHStatus = value;
    },
    calcDefStatus(state, value) {
      state.defStatus = value;
    },
    setDefItem(state, items) {
      state.defItems = items;
    },
    setDefTypes(state, types) {
      state.defTypes = types;
    },
  },
};
