<template>
  <div class="container mx-auto mt-10 h-screen">
    <h1 class="text-xl font-bold mb-4">アカウント本登録</h1>
    <p v-if="activationSuccessful" class="text-blue-500">
      アカウントが正常に登録されました！ ログインページにリダイレクトします。
    </p>
    <p v-else-if="activationError" class="text-red-500">
      本登録中にエラーが発生しました。
      申し訳ありませんが、もう一度お試しください。
    </p>
    <p v-else class="text-green-500">本登録処理中です、お待ちください...</p>
  </div>
</template>

<script>
import { authAxios } from "@/axios";
export default {
  data() {
    return {
      uid: "",
      token: "",
      activationSuccessful: false,
      activationError: false,
    };
  },
  created() {
    const params = new URLSearchParams(window.location.search);
    this.uid = params.get("uid");
    this.token = params.get("token");
  },
  mounted() {
    this.activateAccount();
  },
  methods: {
    async activateAccount() {
      const url = "/api/auth/users/reset_email_confirm/";
      const data = {
        uid: this.uid,
        token: this.token,
      };

      try {
        const response = await authAxios.post(url, data);
        console.log(response.data);
        this.activationSuccessful = true;
        this.$toast.open({
          message:
            "メールアドレスが正常に登録されました！再度ログインしてください。",
          position: "top",
          type: "success",
          duration: 5000,
        });
        this.$router.push("/login");
      } catch (error) {
        this.activationError = true;
        console.error("Error:", error);
        this.$toast.open({
          message:
            "メールアドレス変更中にエラーが発生しました。再度お試しください。",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
  },
};
</script>
