<template>
  <div class="container mx-auto">
    <div class="flex text-sm mx-5 mb-2">
      <div>HP:{{ defHp - finalDmg }}~{{ defHp - minFinalDmg }}</div>
      <div>ダメージ:{{ minFinalDmg }}~{{ finalDmg }}</div>
    </div>
    <div class="max-w-full mx-2 mb-2 h-4 flex border-2 border-gray-500">
      <!-- minFinalDmg を青色で表示 -->
      <div
        v-if="showMinFinalHpWidth"
        class="bg-blue-500 text-white text-sm flex justify-center items-center"
        :style="{ width: minFinalHpWidth }"
      ></div>
      <!-- maxFinalDmg - minFinalDmg を薄い青色で表示 -->
      <div
        v-if="showMaxFinalHpWidth"
        class="bg-blue-400 text-white text-sm flex justify-center items-center"
        :style="{ width: diffFinalHpWidth }"
      ></div>
      <!-- recoveryNum をオレンジで表示（条件による） -->
      <div
        v-if="showRecoveryNum"
        class="bg-orange-500 text-white text-sm flex justify-center items-center"
        :style="{ width: recoveryNumWidth }"
      ></div>
      <!-- 残りの部分を薄い灰色で表示 -->
      <div class="bg-gray-300 flex-grow"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    defHp: Number,
    finalDmg: Number,
    recoverryNum: Number,
  },
  computed: {
    // minFinalDmgの計算
    minFinalDmg() {
      return this.finalDmg * 0.75;
    },
    // 最大HPと最小HPが0以上であるかどうかを示すプロパティ
    showMaxMinHp() {
      return this.defHp > 0 && this.defHp - this.finalDmg > 0;
    },

    // 最小の体力表示条件の修正
    showMinFinalHpWidth() {
      return this.showMaxMinHp && parseFloat(this.minFinalHpWidth) > 0;
    },

    // 差分の体力表示条件の修正
    showMaxFinalHpWidth() {
      return this.defHp - this.finalDmg > 0;
    },

    // 回復数の表示条件の修正
    showRecoveryNum() {
      return (
        this.showMaxMinHp &&
        this.minFinalDmg <= this.defHp &&
        this.recoverryNum != null &&
        this.recoverryNum > 0
      );
    },
    // 各値をdefHpに対するパーセンテージで表示
    recoveryNumPercentage() {
      // recoveryNumをdefHpに対するパーセンテージとして計算
      return ((this.recoveryNum / this.defHp) * 100).toFixed(2);
    },
    minFinalDmgPercentage() {
      return ((this.minFinalDmg / this.defHp) * 100).toFixed(2);
    },
    maxFinalDmgPercentage() {
      return ((this.finalDmg / this.defHp) * 100).toFixed(2);
    },
    // 100 - dmg で残りのHPを表示
    minFinalHpWidth() {
      return `${100 - this.maxFinalDmgPercentage}%`;
    },
    diffFinalHpWidth() {
      const diffDmg = this.finalDmg - this.minFinalDmg;
      // 差分をdefHpに対するパーセンテージとして計算
      const width = (diffDmg / this.defHp) * 100;
      return `${width.toFixed(2)}%`;
    },
    recoveryNumWidth() {
      return this.showRecoveryNum ? `${this.recoveryNumPercentage}%` : "0%";
    },
  },
};
</script>
