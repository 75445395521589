<template>
  <div class="container mx-auto">
    <div class="flex items-center mt-6 border-b-2 border-gray-700">
      <span class="icon-[mdi--weather-partly-snowy] text-xl"></span>
      <div class="text-lg font-bold">環境、その他</div>
    </div>

    <div class="my-6">
      <div class="mb-4">
        <FieldList />
        <span class="text-xs flex justify-center"
          >フィールド: {{ selectedField }}</span
        >
      </div>

      <div class="mb-4">
        <WeatherList />
        <span class="text-xs flex justify-center"
          >天気: {{ selectedWeather }}</span
        >
      </div>

      <div class="mb-4">
        <RuinList />
        <span class="text-xs flex justify-center"
          >わざわい系: {{ selectedRuin }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
// common/environ
import WeatherList from "@/components/dmg9/environ/WeatherList.vue";
import FieldList from "@/components/dmg9/environ/FieldList.vue";
import RuinList from "@/components/dmg9/environ/RuinList.vue";

export default {
  components: {
    WeatherList,
    FieldList,
    RuinList,
  },
  computed: {
    // ストアの状態をリアクティブに反映するためにcomputedを使用
    selectedWeather() {
      return this.$store.state.dmgEnviron.selectedWeather;
    },
    selectedRuin() {
      return this.$store.state.dmgEnviron.selectedRuin;
    },
    selectedField() {
      return this.$store.state.dmgEnviron.selectedField;
    },
  },
};
</script>
