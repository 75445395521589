<template>
  <div v-if="show" class="fixed inset-0 flex items-center justify-center z-50">
    <div class="absolute inset-0 bg-black opacity-50" @click="cancel"></div>
    <div class="bg-white rounded-lg shadow-lg p-6 text-center z-10">
      <h3 class="text-2xl font-bold mb-4">{{ title }}</h3>
      <p class="text-red-500 mb-6">{{ message }}</p>
      <div class="flex justify-center">
        <button
          @click="confirm"
          class="px-4 py-2 bg-green-500 text-white rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 mr-4"
        >
          はい
        </button>
        <button
          @click="cancel"
          class="px-4 py-2 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50"
        >
          いいえ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: String,
    message: String,
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
