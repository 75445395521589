<template>
  <input
    type="range"
    class="range w-full"
    min="1"
    max="50"
    step="1"
    v-model="LevelCount"
    @input="emitLevelUpdate"
  />
  <div class="flex justify-between">
    <button @click="decrement" class="round-button">-</button>
    <span class="mx-2 text-sm">レベル:{{ LevelCount }}</span>
    <button @click="increment" class="round-button">+</button>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: Number,
  },
  data() {
    return {
      LevelCount: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.LevelCount = newValue;
    },
    LevelCount() {
      this.$emit("update:modelValue", this.LevelCount);
    },
  },
  methods: {
    increment() {
      if (this.LevelCount < 50) {
        this.LevelCount++;
      }
    },
    decrement() {
      if (this.LevelCount > 1) {
        this.LevelCount--;
      }
    },
  },
};
</script>
