<template>
  <div
    v-if="memoInfo && Object.keys(memoInfo).length"
    class="bg-white container"
  >
    <div class="flex items-center p-4">
      <!-- ポケモン画像 -->
      <div class="flex-shrink-0">
        <img
          :src="imageUrl"
          :alt="memoInfo.poke_name"
          class="w-20 h-20 object-cover"
        />
      </div>
      <!-- ポケモン情報 -->
      <div class="flex-grow ml-4">
        <div class="text-base">
          {{ memoInfo.poke_name }} ({{
            memoInfo.additional_field.poke_types.poke_type1
          }}, {{ memoInfo.additional_field.poke_types.poke_type2 }})
        </div>
        <div class="text-xs">
          H:{{ memoInfo.additional_field.poke_each_stats.poke_hp }} A:{{
            memoInfo.additional_field.poke_attack
          }}
          B:{{ memoInfo.additional_field.poke_each_stats.poke_defense }} C:{{
            memoInfo.additional_field.poke_each_stats.poke_sp_atk
          }}
          D:{{ memoInfo.additional_field.poke_each_stats.poke_sp_def }} S:{{
            memoInfo.additional_field.poke_each_stats.poke_speed
          }}
          合計:{{ memoInfo.additional_field.total_stats }}
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between space-bet px-4 py-2">
      <span
        v-if="generationName !== ''"
        class="inline-block bg-gray-500 text-white px-2 py-1 rounded-full text-xs uppercase tracking-wide mr-2"
      >
        {{ generationName }}
      </span>

      <div class="space-x-3">
        <favorited-memo
          :memo-id="memoInfo.id"
          :fetch-init-flag="true ? isLoggedin : false"
          :prop-favorite-count="memoInfo.favorited_count"
        />
      </div>
    </div>

    <div v-if="memoInfo.generation === 9" class="mb-1 mt-4">
      <span
        class="inline-block bg-blue-500 text-white px-2 py-1 rounded-full text-xs uppercase tracking-wide mr-2"
        >テラスタイプ</span
      >
      <div class="border-block">
        {{ memoInfo.poke_tera_type }}
      </div>
    </div>

    <div class="my-1">
      <span
        class="bg-rose-500 text-white px-2 py-1 rounded-full text-xs uppercase tracking-wide mr-2"
        >特性</span
      >
      <div class="border-block">
        {{ memoInfo.additional_field.poke_ability.name }}
      </div>
      <span class="text-xs">
        {{ memoInfo.additional_field.poke_ability.description }}
      </span>
    </div>

    <div class="my-1">
      <span
        class="inline-block bg-green-500 text-white px-2 py-1 rounded-full text-xs uppercase tracking-wide mr-2"
      >
        性格
      </span>
      <div class="border-block">{{ nature.name }}</div>
      <span class="text-xs">{{ nature.description }}</span>
    </div>

    <div class="mt-1 mb-4">
      <span
        class="inline-block bg-yellow-500 text-white px-2 py-1 rounded-full text-xs uppercase tracking-wide mr-2"
      >
        持ち物
      </span>
      <div class="border-block">{{ item.name }}</div>
      <span class="text-xs">{{ item.description }}</span>
    </div>

    <div class="overflow-x-auto">
      <table
        class="w-full mx-2 text-sm text-left border-collapse border border-slate-200 text-gray-500 shadow-md sm:rounded-lg"
      >
        <thead
          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-3 py-3">Lv.{{ memoInfo.poke_level }}</th>
            <th scope="col" class="px-3 py-3">H</th>
            <th scope="col" class="px-3 py-3">A</th>
            <th scope="col" class="px-3 py-3">B</th>
            <th scope="col" class="px-3 py-3">C</th>
            <th scope="col" class="px-3 py-3">D</th>
            <th scope="col" class="px-3 py-3">S</th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th
              scope="row"
              class="px-3 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
            >
              努力値
            </th>
            <td class="px-3 py-4">{{ memoInfo.poke_ev[0].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_ev[1].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_ev[2].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_ev[3].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_ev[4].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_ev[5].value }}</td>
          </tr>
          <tr class="bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
            <th
              scope="row"
              class="px-3 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
            >
              個体値
            </th>
            <td class="px-3 py-4">{{ memoInfo.poke_iv[0].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_iv[1].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_iv[2].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_iv[3].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_iv[4].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_iv[5].value }}</td>
          </tr>
          <tr class="bg-white dark:bg-gray-800">
            <th
              scope="row"
              class="px-3 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
            >
              実数値
            </th>
            <td class="px-3 py-4">{{ memoInfo.poke_rv.calcedHP }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_rv.calcedA }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_rv.calcedB }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_rv.calcedC }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_rv.calcedD }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_rv.calcedS }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 攻撃技の表示 -->
    <div class="p-2 mt-3">
      <h3 class="font-bold mb-2 border-b-4">覚えている技</h3>
      <div
        v-for="(move, index) in moves"
        :key="index"
        class="text-gray-700 text-sm"
      >
        <div class="my-2">
          <div v-if="move.move_name" class="border-block">
            <div class="font-bold">
              {{ move.move_name }}
            </div>
          </div>
          <div v-if="move.move_name" class="space-x-2">
            <span class="text-xs">{{ move.move_type }}</span>
            <span class="text-xs">{{ move.move_class }}</span>
            <span class="text-xs">威力:{{ move.move_power }}</span>
            <span class="text-xs">命中率:{{ move.move_accuracy }}</span>
          </div>
          <div v-else class="border-block font-bold">未選択</div>
          <span class="text-xs pt-2">{{ move.move_effect }}</span>
        </div>
      </div>
    </div>

    <div class="p-3 bg-white rounded-lg text-wrap">
      <div class="mb-1 p-2">
        <div
          class="font-bold text-lg text-indigo-600 break-words flex items-center"
        >
          {{ memoInfo.title }}
        </div>
        <div class="w-20 h-1 bg-indigo-500 rounded-full"></div>
      </div>
      <div class="bg-indigo-50 rounded-lg p-2 break-words">
        <span class="text-gray-800" v-html="formatMemo(memoInfo.memo)"></span>
      </div>
    </div>
    <!-- だれが投稿したかの表示 -->
    <div
      v-if="memoInfo.additional_field.author_info"
      class="flex items-center m-1 pb-3 pl-3"
    >
      {{ memoInfo.user }}
      &nbsp;
      <img
        :src="favoritedImageUrl"
        alt="お気に入りポケモンの画像"
        class="w-10 h-10 border rounded-full object-cover"
      />
      &nbsp;
      {{ memoInfo.additional_field.author_info.user_name }}
      &nbsp;
      {{ formatUpdatedAt }}
    </div>
    <div v-if="isUserMemo" class="flex space-x-4 pb-4 pl-4">
      <div @click="updateMemo" class="round-long-button-border">編集する</div>
      <div @click="showConfirm = true" class="round-long-button-border">
        削除する
      </div>
    </div>
    <div class="flex justify-center my-4">
      <router-link
        :to="{ path: $router.options.history.state.back }"
        class="round-long-button"
      >
        前のページに戻る
      </router-link>
    </div>
    <confirm-dialog
      :show="showConfirm"
      :title="memoInfo.title"
      :message="`このポケモンメモを削除しますか？`"
      @confirm="deleteMemo"
      @cancel="showConfirm = false"
    />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import FavoritedMemo from "./FavoritedMemo.vue";
import { authAxios, publicAxios } from "@/axios";
export default {
  components: {
    ConfirmDialog,
    FavoritedMemo,
  },
  data() {
    return {
      memoInfo: {},
      showConfirm: false,
      generationName: "",
    };
  },
  computed: {
    moves() {
      const moveData = this.memoInfo.additional_field.moves;
      return moveData.map((moveObj) => {
        const moveKey = Object.keys(moveObj)[0];
        return moveObj[moveKey];
      });
    },
    nature() {
      return this.memoInfo.additional_field.poke_nature;
    },
    item() {
      return this.memoInfo.additional_field.poke_item;
    },
    // TODO: 本番用のドメインを忘れずに設定する
    isLoggedin() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    isUserMemo() {
      // メモがログインユーザーのものかどうかを判定
      return (
        this.isLoggedin &&
        this.memoInfo.user === this.$store.state.auth.userData.id
      );
    },
    imageUrl() {
      return this.memoInfo.additional_field.poke_image_src;
    },
    favoritedImageUrl() {
      if (this.memoInfo.additional_field.author_info.favorite_poke_image_src) {
        return this.memoInfo.additional_field.author_info
          .favorite_poke_image_src;
      } else {
        return "hoge.png";
      }
    },
    formatUpdatedAt() {
      if (this.memoInfo.updated_at) {
        const formatData = new Date(this.memoInfo.updated_at);
        return formatData.toLocaleString("ja-JP"); // 'ja-JP' locale results in 'yyyy/mm/dd hh:mm:ss' format
      } else {
        return "";
      }
    },
  },
  methods: {
    formatMemo(memo) {
      // 改行を <br> タグに置き換える
      memo = memo.replace(/\n/g, "<br>");

      // URLを <a> タグでラップする
      memo = memo.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank">$1</a>'
      );
      return memo;
    },
    async deleteMemo() {
      await authAxios.delete(
        `/api/pokememo/list/user/retrieve/${this.memoInfo.id}`
      );
      this.showConfirm = false;
      location.reload();
      this.$router.push("/home");
      this.$toast.open({
        message: "ポケモンメモを削除しました。",
        position: "top",
        type: "success",
        duration: 5000,
      });
    },
    updateMemo() {
      this.$store.commit("updateMemo/setMemo", this.memoInfo);
      this.$router.push({
        name: "PokeMemoUpdate",
        params: { id: this.memoInfo.id },
      });
    },
    async fetchMemoDetail() {
      const memoId = this.$route.params.id;
      console.log("memoId", memoId);
      try {
        const response = await publicAxios.get(
          `/api/pokememo/detail/${memoId}`
        );
        this.memoInfo = response.data;
        if (this.memoInfo.generation === 9) {
          this.generationName = "スカーレット・バイオレット: 第9世代";
        }
        console.log("this.memoInfo", this.memoInfo);
      } catch (error) {
        console.log(error);
        this.$router.push("/home");
        this.$toast.open({
          message: "ポケモンメモの取得に失敗しました。",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
  },
  created() {
    // ページ遷移時にスクロールをトップに戻す
    window.scrollTo(0, 0);
    this.fetchMemoDetail();
  },
};
</script>
