<template>
  <div class="container">
    <MemoDetail />
  </div>
</template>

<script>
import MemoDetail from "@/components/home/MemoDetail.vue";

export default {
  components: {
    MemoDetail,
  },
};
</script>
