const state = {
  memo: null,
};

const getters = {
  memo: (state) => state.memo,
  existMemo: (state) => state.memo !== null,
};

const mutations = {
  setMemo(state, memoInfo) {
    state.memo = memoInfo;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
