<template>
  <transition name="fade">
    <div
      v-if="isVisible"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 ease-in-out z-50"
      @click.self="closeModal"
    >
      <div
        :class="[
          'bg-white p-5 rounded-lg relative transition-all duration-300 ease-in-out transform overflow-y-auto',
          widthClass,
          isVisible ? 'scale-100' : 'scale-95 opacity-0',
        ]"
        style="max-height: 90vh; transition: transform 0.5s, opacity 0.5s"
        @click.stop
      >
        <slot></slot>
        <button class="absolute top-1 right-1 text-black" @click="closeModal">
          <span
            class="icon-[mdi--cancel-circle-outline] absolute right-1 top-1 cursor-pointer text-3xl"
          ></span>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "max-w-md",
    },
  },
  watch: {
    // モーダルが表示されたときに、body 要素のスクロールを無効化
    isVisible(newValue) {
      if (newValue) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  computed: {
    widthClass() {
      return this.width ? this.width : "max-w-md";
    },
  },
  methods: {
    closeModal() {
      this.$emit("update:isVisible", false);
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
