<template>
  <div></div>
</template>

<script>
export default {
  props: {
    baseDmg: Number,
    weatherNum: Number,
    fieldNum: Number,
    typeMatchNum: Number,
    typeCompatibilityNum: Number,
  },
  watch: {
    // 下記の方法でプロパティごとにウォッチャを設定する代わりに、deepオプションを使用して全てのプロパティを一度に監視
    $props: {
      handler: "calcFinalDmg",
      deep: true,
      immediate: true, // コンポーネントがマウントされた直後にも実行
    },
  },
  methods: {
    calcFinalDmg() {
      // すべての補正を考慮したダメージの計算
      const damage = Math.max(
        1,
        this.baseDmg *
          this.weatherNum *
          this.fieldNum *
          this.typeMatchNum *
          this.typeCompatibilityNum
      );
      // 計算結果を親コンポーネントに伝える emitがあるため、computedは使用不可
      this.$emit("calculatedDamage", damage);
    },
  },
  mounted() {
    this.calcFinalDmg();
  },
};
</script>
