<template>
  <div></div>
</template>
<script>
export default {
  props: {
    selectedPokemon: Object,
    whatStatus: String, // A, B, C, D, S
    modelIV: Number,
    modelEV: Number,
    modelLevel: Number,
    modelNature: String,
  },
  computed: {
    calcPerStatus() {
      let baseStat;
      let natureEffect = 1;

      // ステータスに応じた種族値を取得
      switch (this.whatStatus) {
        case "A":
          baseStat = this.selectedPokemon.poke_attack;
          break;
        case "B":
          baseStat = this.selectedPokemon.poke_defense;
          break;
        case "C":
          baseStat = this.selectedPokemon.poke_sp_atk;
          break;
        case "D":
          baseStat = this.selectedPokemon.poke_sp_def;
          break;
        case "S":
          baseStat = this.selectedPokemon.poke_speed;
          break;
        default:
          baseStat = 0;
      }

      // 性格に応じてステータスの補正を行う
      const natureModifiers = {
        さみしがり: { A: 1.1, B: 0.9 },
        いじっぱり: { A: 1.1, C: 0.9 },
        やんちゃ: { A: 1.1, D: 0.9 },
        ゆうかん: { A: 1.1, S: 0.9 },
        ずぶとい: { B: 1.1, A: 0.9 },
        わんぱく: { B: 1.1, C: 0.9 },
        のうてんき: { B: 1.1, D: 0.9 },
        のんき: { B: 1.1, S: 0.9 },
        ひかえめ: { C: 1.1, A: 0.9 },
        おっとり: { C: 1.1, B: 0.9 },
        うっかりや: { C: 1.1, D: 0.9 },
        れいせい: { C: 1.1, S: 0.9 },
        おだやか: { D: 1.1, A: 0.9 },
        おとなしい: { D: 1.1, B: 0.9 },
        しんちょう: { D: 1.1, C: 0.9 },
        なまいき: { D: 1.1, S: 0.9 },
        おくびょう: { S: 1.1, A: 0.9 },
        せっかち: { S: 1.1, B: 0.9 },
        ようき: { S: 1.1, C: 0.9 },
        むじゃき: { S: 1.1, D: 0.9 },
        てれや: {},
        がんばりや: {},
        すなお: {},
        きまぐれ: {},
        まじめ: {},
      };

      // 選択された性格に応じて補正値を取得、設定
      const selectedNatureModifiers = natureModifiers[this.modelNature] || {};
      natureEffect = selectedNatureModifiers[this.whatStatus] || 1.0;

      // ステータス値の計算
      return Math.floor(
        (((2 * baseStat + this.modelIV + this.modelEV / 4) * this.modelLevel) /
          100 +
          5) *
          natureEffect
      );
    },
  },
  watch: {
    calcPerStatus(newValue) {
      this.$emit("update:perStatus", newValue);
    },
  },
  mounted() {
    this.$emit("update:perStatus", this.calcPerStatus);
  },
};
</script>
