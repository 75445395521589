<template>
  <input
    type="range"
    class="range w-full"
    min="0"
    max="252"
    step="4"
    v-model="EVcount"
    @input="updateEVValue"
  />
  <div class="flex justify-between">
    <button @click="decrement" class="round-button">-</button>
    <span class="mx-2 text-sm">努力値:{{ EVcount }}</span>
    <button @click="increment" class="round-button">+</button>
  </div>
</template>

<script>
/* 
努力値のカウンター
それぞれのevのpropの真偽値により、どの努力値を更新するかを判定する
また努力値は、0~252の範囲であり、バーと連動させる

実装がめんどくさそうだったので、4ずつ増減するようにしたのに注意
*/
export default {
  props: {
    modelValue: Number,
    hFlag: Boolean,
    aFlag: Boolean,
    bFlag: Boolean,
    cFlag: Boolean,
    dFlag: Boolean,
    sFlag: Boolean,
  },
  data() {
    return {
      EVcount: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.EVcount = newValue;
    },
    EVcount() {
      this.$emit("update:modelValue", {
        value: this.EVcount,
        hFlag: this.hFlag,
        aFlag: this.aFlag,
        bFlag: this.bFlag,
        cFlag: this.cFlag,
        dFlag: this.dFlag,
        sFlag: this.sFlag,
      });
    },
  },
  methods: {
    increment() {
      if (this.EVcount === 0) {
        this.EVcount += 4;
      } else if (this.EVcount < 252) {
        this.EVcount = Math.min(this.EVcount + 8, 252);
      }
    },
    decrement() {
      if (this.EVcount <= 4) {
        this.EVcount = 0;
      } else if (this.EVcount > 0) {
        this.EVcount -= 8;
      }
    },
  },
};
</script>
