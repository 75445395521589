<template>
  <div></div>
</template>

<script>
/*
タイプ一致
テラスタルしていない場合
攻撃側のタイプと技のタイプが同じ場合、ダメージが1.5倍になります。
攻撃側のタイプと技のタイプが同じ場合、かつ特性がてきおうりょくの場合は2倍になります。

テラスタルしている場合
テラスタイプと技のタイプが同じ場合、ダメージが1.5倍になります。
テラスタイプと技のタイプが同じ場合、かつ特性がてきおうりょくの場合はダメージが2.0倍になります。
テラスタイプと攻撃側のタイプと技のタイプが同じ場合、ダメージが2.0倍になります。
テラスタイプと攻撃側のタイプと技のタイプが同じ場合、
かつ特性がてきおうりょくの場合は2.25倍になります。
攻撃側のタイプと技のタイプが同じ場合でも、テラスタイプが異なる場合、
ダメージは1.5倍になります。特性がてきおうりょくの場合でも1.5倍のままです。
参照先: https://latest.pokewiki.net/%E3%83%80%E3%83%A1%E3%83%BC%E3%82%B8%E8%A8%88%E7%AE%97%E5%BC%8F
*/
export default {
  props: {
    isTeraType: Boolean,
    moveType: String,
    planeAtkType1: String,
    planeAtkType2: String,
    atkPokeType1: String,
    atkPokeType2: String,
    atkAbility: String,
  },
  data() {
    return {
      typeMatchBonus: 1,
    };
  },
  watch: {
    isTeraType: {
      handler: "calculateTypeMatchBonus",
      immediate: true,
    },
    moveType: {
      handler: "calculateTypeMatchBonus",
      immediate: true,
    },
    planeAtkType1: {
      handler: "calculateTypeMatchBonus",
      immediate: true,
    },
    planeAtkType2: {
      handler: "calculateTypeMatchBonus",
      immediate: true,
    },
    atkPokeType1: {
      handler: "calculateTypeMatchBonus",
      immediate: true,
    },
    atkPokeType2: {
      handler: "calculateTypeMatchBonus",
      immediate: true,
    },
    atkAbility: {
      handler: "calculateTypeMatchBonus",
      immediate: true,
    },
  },
  methods: {
    calculateTypeMatchBonus() {
      let typeMatchBonus = 1;
      const isAdaptability = this.atkAbility === "てきおうりょく";
      // テラスタイプと攻撃側のタイプと技のタイプが同じ場合
      // 安全のため、条件式でisTeraType && isMatchを使う
      const isMatch =
        (this.atkPokeType1 === this.planeAtkType1 ||
          this.atkPokeType1 === this.planeAtkType2) &&
        this.atkPokeType1 === this.moveType;
      const isMoveTypePlaneTypeMatch =
        this.moveType === this.planeAtkType1 ||
        this.moveType === this.planeAtkType2;

      if (this.isTeraType) {
        // テラスタルしている場合
        if (isMatch && isAdaptability) {
          typeMatchBonus = 2.25;
        } else if (isMatch) {
          typeMatchBonus = 2.0;
        } else if (this.atkPokeType1 === this.moveType && isAdaptability) {
          typeMatchBonus = 2.0;
        } else if (this.atkPokeType1 === this.moveType) {
          typeMatchBonus = 1.5;
        } else if (
          (this.planeAtkType1 === this.moveType) !== this.atkPokeType1 &&
          isAdaptability
        ) {
          typeMatchBonus = 1.5;
        } else {
          typeMatchBonus = 1;
        }
      } else {
        // テラスタルしていない場合
        if (isMoveTypePlaneTypeMatch && isAdaptability) {
          typeMatchBonus = 2.0;
        } else if (isMoveTypePlaneTypeMatch) {
          typeMatchBonus = 1.5;
        } else {
          typeMatchBonus = 1;
        }
      }
      this.typeMatchBonus = typeMatchBonus;

      // 結果をコンソールに出力（デバッグ用）
      // console.log(
      //   `テラスタイプ:${this.isTeraType}`,
      //   `技タイプ:${this.moveType}`,
      //   `タイプ1:${this.atkPokeType1}`,
      //   `タイプ2:${this.atkPokeType2}`,
      //   `特性:${this.atkAbility}`,
      //   `タイプ一致ボーナス:${this.typeMatchBonus}`
      // );

      // 外部に通知（必要に応じて）
      this.$emit("update:modelValue", this.typeMatchBonus);
    },
  },
  mounted() {
    this.calculateTypeMatchBonus();
  },
};
</script>
