<template>
  <div>
    <!-- 開発終わったら、v-if="selectedPokemonを追加" -->
    <button
      @click="isModalOpen = true"
      class="w-full p-3 mr-3 bg-blue-500 text-white rounded text-sm hover:bg-blue-600"
    >
      <span class="text-semibod">ポケモンメモを確認する！</span>
    </button>

    <TheModal
      :isVisible="isModalOpen"
      @update:isVisible="handleModalVisibility"
    >
      <div class="p-3">
        <div
          v-if="selectedPokemon"
          class="flex items-center p-1 m-2 bg-white rounded-lg shadow-md"
        >
          <!-- 画像部分 (flex-shrink-0 でサイズを固定) -->
          <div class="flex-shrink-0">
            <a :href="selectedPokemon.poke_image_src">
              <img
                :src="selectedPokemon.poke_image_src"
                alt=""
                class="w-12 h-12"
              />
            </a>
          </div>
          <!-- 情報部分 (flex-grow で残りのスペースを埋める) -->
          <div class="flex-grow ml-4">
            <div>
              {{ selectedPokemon.poke_name }}
              ({{ selectedPokemon.poke_type }},
              {{ selectedPokemon.poke_type2 }}) Lv: {{ pokeLevel }}
              <div
                v-if="selectedTeraType === '未選択'"
                class="text-sm text-orange-500 font-semibold"
              >
                <span class="icon-[mdi--warning-circle-outline]"></span>
                テラタイプを選択してください
              </div>
              <div v-else class="text-sm text-blue-500 font-semibold">
                <span class="icon-[mdi--check-circle-outline]"></span>
                テラタイプ:{{ selectedTeraType }}
              </div>
              <div
                v-if="selectedPokemonAbility === '未選択'"
                class="text-sm text-orange-500 font-semibold"
              >
                <span class="icon-[mdi--warning-circle-outline]"></span>
                特性を選択してください
              </div>
              <div v-else class="text-sm text-blue-500 font-semibold">
                <span class="icon-[mdi--check-circle-outline]"></span>
                特性:{{ selectedPokemonAbility }}
              </div>
            </div>
            <div class="text-xs">
              H:{{ selectedPokemon.poke_hp }} A:{{
                selectedPokemon.poke_attack
              }}
              B:{{ selectedPokemon.poke_defense }} C:{{
                selectedPokemon.poke_sp_atk
              }}
              D:{{ selectedPokemon.poke_sp_def }} S:{{
                selectedPokemon.poke_speed
              }}
              合計:{{ selectedPokemon.poke_base_stats }}
            </div>
          </div>
        </div>
        <div>
          <div
            v-if="pokeNature == '未選択'"
            class="text-sm font-semibold text-orange-500"
          >
            <span class="icon-[mdi--warning-circle-outline]"></span>
            性格を選択してください
          </div>
          <div v-else class="text-sm font-semibold text-blue-500">
            <span class="icon-[mdi--check-circle-outline]"></span>
            性格:{{ pokeNature }}
          </div>
          <div
            v-if="decidedItem == '未選択'"
            class="text-sm font-semibold text-orange-500"
          >
            <span class="icon-[mdi--warning-circle-outline]"></span>
            アイテムを選択してください
          </div>
          <div v-else class="text-sm text-blue-500 font-semibold">
            <span class="icon-[mdi--check-circle-outline]"></span>
            アイテム:{{ decidedItem }}
          </div>
          <div
            v-if="memoMove1 === '未選択'"
            class="text-sm text-orange-500 font-semibold"
          >
            <span class="icon-[mdi--warning-circle-outline]"></span>
            技1を選択してください
          </div>
          <div v-else class="text-sm text-blue-500 font-semibold">
            <span class="icon-[mdi--check-circle-outline]"></span>
            技1:{{ memoMove1 }}
          </div>
          <div
            v-if="memoMove2 === '未選択'"
            class="text-sm text-orange-500 font-semibold"
          >
            <span class="icon-[mdi--warning-circle-outline]"></span>
            技2を選択してください
          </div>
          <div v-else class="text-sm text-blue-500 font-semibold">
            <span class="icon-[mdi--check-circle-outline]"></span>
            技2:{{ memoMove2 }}
          </div>
          <div
            v-if="memoMove3 === '未選択'"
            class="text-sm text-orange-500 font-semibold"
          >
            <span class="icon-[mdi--warning-circle-outline]"></span>
            技3を選択してください
          </div>
          <div v-else class="text-sm text-blue-500 font-semibold">
            <span class="icon-[mdi--check-circle-outline]"></span>
            技3:{{ memoMove3 }}
          </div>
          <div
            v-if="memoMove4 === '未選択'"
            class="text-sm text-orange-500 font-semibold"
          >
            <span class="icon-[mdi--warning-circle-outline]"></span>
            技4を選択してください
          </div>
          <div v-else class="text-sm text-blue-500 font-semibold">
            <span class="icon-[mdi--check-circle-outline]"></span>
            技4:{{ memoMove4 }}
          </div>
          <!-- 技が重複してしったwarnignをここに表示 -->
          <div v-if="isDuplicate" class="text-sm text-orange-500 font-semibold">
            <span class="icon-[mdi--alert-circle-outline]"></span>
            技が重複しています
          </div>
        </div>
        <div class="flex items-center mx-2 my-1 whitespace-nowrap">
          <span class="text-sm font-semibold">努力値:</span>
          <div v-for="ev in effortValue" :key="ev.value" class="text-xs">
            {{ ev.name }}:{{ ev.value }}/
          </div>
        </div>
        <div class="flex items-center mx-2 my-1 whitespace-nowrap">
          <span class="text-sm font-semibold">個体値:</span>
          <div v-for="iv in indivisualValue" :key="iv.value" class="text-xs">
            {{ iv.name }}:{{ iv.value }}/
          </div>
        </div>
        <div class="flex">
          <div class="flex items-center mx-2 my-1 whitespace-nowrap">
            <span class="text-sm font-semibold">実数値:</span>
            <div class="text-xs">
              体力:{{ calcedHP }}/ 攻撃:{{ calcedA }}/ 防御:{{ calcedB }}/
              特功:{{ calcedC }}/ 特防:{{ calcedD }}/ 素早さ:{{ calcedS }}/
            </div>
          </div>
        </div>
        <div>
          <div
            v-if="pokeTitle === ''"
            class="text-sm font-semibold text-red-500"
          >
            <div class="flex items-center">
              <span class="icon-[mdi--warning-decagram-outline]"></span>
              <span>必須項目: タイトルを入力してください</span>
            </div>
          </div>

          <div
            v-else
            class="flex items-center mx-2 my-1 text-blue-500 font-semibold"
          >
            <div class="flex-shrink-0 flex items-center">
              <span class="icon-[mdi--check-circle-outline]"></span>
              <span class="text-sm font-semibold">タイトル:</span>
            </div>

            <div class="flex-grow">
              <span class="text-xs">{{ pokeTitle }}</span>
            </div>
          </div>

          <div
            v-if="pokeMemo === ''"
            class="text-sm font-semibold text-red-500"
          >
            <div class="flex items-center">
              <span class="icon-[mdi--warning-decagram-outline]"></span>
              <span>必須項目: メモを入力してください</span>
            </div>
          </div>

          <div
            v-else
            class="flex items-center mx-2 my-1 text-blue-500 font-semibold"
          >
            <div class="flex-shrink-0 flex items-center">
              <span class="icon-[mdi--check-circle-outline]"></span>
              <span class="text-sm font-semibold">メモ:</span>
            </div>
            <div class="flex-grow">
              <span class="text-xs">{{ pokeMemo }}</span>
            </div>
          </div>
        </div>
        <button
          @click="postPokeMemoInfo"
          class="flex items-center justify-center p-4 text-bold mt-4 w-full round-long-button"
        >
          <span class="icon-[mdi--send] mr-3" style="color: white"></span>
          ポケメモを投稿する！
        </button>
      </div>
    </TheModal>
  </div>
</template>

<script>
import { authAxios } from "@/axios";
import TheModal from "../common/TheModal.vue";

export default {
  props: {
    selectedPokemon: {
      type: Object,
      default: null,
    },
    selectedTeraType: {
      type: String,
      default: "未選択",
    },
    selectedPokemonAbility: {
      type: String,
      default: "未選択",
    },
    pokeLevel: {
      type: Number,
      default: 50,
    },
    pokeNature: {
      type: String,
      default: "未選択",
    },
    memoMove1: {
      type: String,
      default: "未選択",
    },
    memoMove2: {
      type: String,
      default: "未選択",
    },
    memoMove3: {
      type: String,
      default: "未選択",
    },
    memoMove4: {
      type: String,
      default: "未選択",
    },
    effortValue: {
      type: Array,
      default: () => [
        { name: "体力", key: "eHp", value: 0 },
        { name: "攻撃", key: "eAtk", value: 0 },
        { name: "防御", key: "eDef", value: 0 },
        { name: "特攻", key: "eSpAtk", value: 0 },
        { name: "特防", key: "eSpDef", value: 0 },
        { name: "素早さ", key: "eSpeed", value: 0 },
      ],
    },
    indivisualValue: {
      type: Array,
      default: () => [
        { name: "体力", key: "iHp", value: 31 },
        { name: "攻撃", key: "iAtk", value: 31 },
        { name: "防御", key: "iDef", value: 31 },
        { name: "特攻", key: "iSpAtk", value: 31 },
        { name: "特防", key: "iSpDef", value: 31 },
        { name: "素早さ", key: "iSpeed", value: 31 },
      ],
    },
    calcedHP: {
      type: Number,
      default: 0,
    },
    calcedA: {
      type: Number,
      default: 0,
    },
    calcedB: {
      type: Number,
      default: 0,
    },
    calcedC: {
      type: Number,
      default: 0,
    },
    calcedD: {
      type: Number,
      default: 0,
    },
    calcedS: {
      type: Number,
      default: 0,
    },
    pokeTitle: {
      type: String,
      default: "",
    },
    pokeMemo: {
      type: String,
      default: "",
    },
    decidedItem: {
      type: String,
      default: "未選択",
    },
    updateFlag: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TheModal,
  },
  data() {
    return {
      isModalOpen: false,
      userData: null,
    };
  },
  computed: {
    isDuplicate() {
      const moves = [
        this.memoMove1,
        this.memoMove2,
        this.memoMove3,
        this.memoMove4,
      ];
      // 「未選択」を除外
      const filteredMoves = moves.filter((move) => move !== "未選択");
      const uniqueMoves = new Set(filteredMoves);
      // ユニークな要素の数とフィルター適用後の配列の長さを比較（一行で書くと、正常に動かない）
      return uniqueMoves.size !== filteredMoves.length;
    },
  },
  methods: {
    handleModalVisibility(isVisible) {
      this.isModalOpen = isVisible;
    },
    async postPokeMemoInfo() {
      // ポケモンメモを投稿する処理

      const calcedNumDict = {
        calcedHP: this.calcedHP,
        calcedA: this.calcedA,
        calcedB: this.calcedB,
        calcedC: this.calcedC,
        calcedD: this.calcedD,
        calcedS: this.calcedS,
      };

      const memoData = {
        poke_name: this.selectedPokemon.poke_name,
        poke_ability: this.selectedPokemonAbility,
        poke_level: this.pokeLevel,
        poke_tera_type: this.selectedTeraType,
        poke_nature: this.pokeNature,
        poke_item: this.decidedItem,
        poke_move1: this.memoMove1,
        poke_move2: this.memoMove2,
        poke_move3: this.memoMove3,
        poke_move4: this.memoMove4,
        poke_ev: this.effortValue.map((ev) => ({
          name: ev.name,
          key: ev.key,
          value: ev.value,
        })),
        poke_iv: this.indivisualValue.map((iv) => ({
          name: iv.name,
          key: iv.key,
          value: iv.value,
        })),
        poke_rv: calcedNumDict,
        title: this.pokeTitle,
        memo: this.pokeMemo,
        user: this.userData.id,
      };

      // パラメーターからidを取得
      const memoId = this.$route.params.id || null;
      try {
        if (this.updateFlag) {
          await authAxios.put(
            `/api/pokememo/list/user/retrieve/${memoId}`,
            memoData
          );
        } else {
          await authAxios.post("/api/pokememo/create", memoData);
        }
        this.isModalOpen = false;
        this.$toast.open({
          message: "ポケモンメモを投稿しました",
          type: "success",
          position: "top",
          duration: 3000,
        });
        this.$router.push(`/home/detail/${memoId}`);
      } catch (error) {
        console.error(error);
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.detail;
        } else {
          errorMessage = "ポケモンメモの投稿に失敗しました";
        }
        this.$toast.open({
          message: errorMessage,
          type: "error",
          position: "top",
          duration: 3000,
        });
      }
    },
  },
  mounted() {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      this.userData = JSON.parse(storedUserData);
      // dddd
      // console.log("User Data:", this.userData);
    }
  },
};
</script>
