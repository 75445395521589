<template>
  <div class="mt-4">
    <div
      v-if="abilities.length > 0 && !selectedAbility"
      class="text-sm text-blue-500 font-semibold mb-2"
    >
      特性を選択してください:
    </div>
    <ul
      v-if="!selectedAbility"
      class="bg-white rounded-md divide-y divide-gray-200 shadow"
    >
      <li
        v-for="ability in abilities"
        :key="ability"
        @click="selectAbility(ability)"
        class="px-1 pb-1 hover:bg-gray-100 cursor-pointer"
      >
        {{ ability.name }}
      </li>
    </ul>
    <div v-if="selectedAbility" class="bg-white rounded-md shadow-md px-4 py-2">
      <div class="text-sm font-semibold">
        <span class="text-blue-500">特性:</span>
        <span class="ml-1">{{ selectedAbility.name }}</span>
      </div>
      <div class="mt-2">
        <span class="text-xs">{{ selectedAbility.description }}</span>
      </div>
      <button
        @click="resetSelection"
        class="m-2 w-full bg-blue-500 hover:bg-blue-700 text-white px-2 rounded"
      >
        <span class="text-sm">特性を変更</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ["abilities"]: Array,
    selectedPokemon: Object,
  },
  data() {
    return {
      selectedAbility: "",
    };
  },
  watch: {
    selectedPokemon() {
      this.selectedAbility = "";
    },
  },
  methods: {
    selectAbility(ability) {
      this.selectedAbility = ability;
      this.$emit("ability-selected", ability);
    },
    resetSelection() {
      this.selectedAbility = "";
      this.$emit("ability-reset"); // 親コンポーネントにリセットを通知（必要に応じて）
    },
  },
};
</script>
