<template>
  <div></div>
</template>

<script>
/* 
ダメージ計算式
ダメージ = (((レベル×2/5+2)×威力×A/D)/50+2)
×範囲補正×おやこあい補正×天気補正×急所補正×乱数補正
×タイプ一致補正×相性補正×やけど補正×M×Mprotect

下記を計算
ダメージ = (((レベル×2/5+2)×威力×A/D)/50+2)
また、注意点としてAとDは雪や砂で変化するため、propsで受け取る値は
そのままのステータスを渡さない

*/
export default {
  props: {
    atkStatus: Number,
    atkLevel: Number,
    defStatus: Number,
    movePower: Number,
  },
  data() {
    return {
      baseDmg: 0,
    };
  },
  watch: {
    $props: {
      handler: "calculateDamage",
      deep: true,
      immediate: true,
    },
  },
  methods: {
    calculateDamage() {
      const dmg = Math.floor(
        Math.floor(
          Math.floor((this.atkLevel * 2) / 5 + 2) *
            this.movePower *
            this.atkStatus
        ) /
          this.defStatus /
          50 +
          2
      );
      this.baseDmg = dmg;

      this.$emit("updateDamage", this.baseDmg);
    },
  },
  mounted() {
    this.calculateDamage();
  },
};
</script>
