<template>
  <div>
    <input
      v-model="searchQuery"
      @input="fetchMove"
      :placeholder="placeholderText"
      class="w-full p-1 mr-3 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
    />
    <div v-if="moveList.length" class="mt-2">
      <ul class="bg-white rounded-md divide-y divide-gray-200 shadow">
        <li
          v-for="(move, index) in moveList"
          :key="index"
          class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
          @click="selectMove(move)"
        >
          {{ move.move_name }}
          <div class="text-xs">
            {{ move.move_type }}
            {{ move.move_class }}
            威力:{{ move.move_power }} 命中率:{{ move.move_accuracy }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { publicAxios } from "@/axios";

export default {
  props: {
    placeholderText: {
      type: String,
      default: "技名を検索",
    },
  },
  data() {
    return {
      searchQuery: "",
      moveList: [],
    };
  },
  methods: {
    async fetchMove() {
      if (this.searchQuery.length >= 3) {
        try {
          const response = await publicAxios.get("/api/moves/", {
            // 検索クエリをパラメータとしてリクエストを送信
            params: { search: this.searchQuery },
          });
          // レスポンスから最大5つのポケモンのデータを取得する
          this.moveList = response.data.slice(0, 5);
          console.log(JSON.stringify(response.data, null, 2)); // オブジェクトの内容を整形して文字列化
        } catch (error) {
          console.error(error);
        }
      }
    },
    selectMove(move) {
      // クリックされたポケモンの名前をsearchQueryにセットし、moveListを空にする
      this.searchQuery = move.move_name;
      this.moveList = [];
      // アクションをディスパッチしてストアの状態を更新
      // dmg App用にstore情報を更新
      this.$store.dispatch("dmgAttack/selectMove", move);

      // memoApp用にイベントを発火
      this.$emit("move-memo", move);
    },
  },
};
</script>
