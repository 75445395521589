<template>
  <div>
    <input
      type="range"
      class="range w-full"
      min="-6"
      max="6"
      step="1"
      v-model="RankCount"
    />
    <div class="flex justify-between">
      <button @click="decrement" class="round-button">-</button>
      <span class="mx-2 text-sm">ランク: {{ RankCount }}</span>
      <button @click="increment" class="round-button">+</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: Number,
  },
  emits: ["update:modelValue"], // 明示的にカスタムイベントを宣言
  data() {
    return {
      RankCount: this.modelValue, // 初期値はpropsから取得
    };
  },
  computed: {
    RankRealValue() {
      const positiveRankMapping = [1, 1.5, 2, 2.5, 3, 3.5, 4];
      const negativeRankMapping = [2 / 3, 2 / 4, 2 / 5, 2 / 6, 2 / 7, 2 / 8];
      return this.RankCount >= 0
        ? positiveRankMapping[this.RankCount] || 1
        : negativeRankMapping[Math.abs(this.RankCount) - 1];
    },
  },
  watch: {
    RankCount(newVal) {
      // RankCountとRankRealValueの両方を含むオブジェクトを親コンポーネントに渡す
      this.$emit("update:modelValue", {
        RankCount: newVal,
        RankRealValue: this.RankRealValue,
      });
    },
  },
  methods: {
    increment() {
      if (this.RankCount < 6) this.RankCount++;
    },
    decrement() {
      if (this.RankCount > -6) this.RankCount--;
    },
  },
};
</script>
