<template>
  <div>
    <button
      @click="isModalOpen = true"
      class="w-full px-2 py-1 bg-blue-500 text-white rounded text-sm hover:bg-blue-600"
    >
      <span class="text-sm">性格を選択</span>
    </button>

    <TheModal
      :isVisible="isModalOpen"
      @update:isVisible="handleModalVisibility"
    >
      <div class="p-3">
        <ul class="grid grid-cols-2 md:grid-cols-3 gap-4">
          <li
            v-for="item in dropdownItems"
            :key="item.value"
            @click="notifySelectedItem(item)"
            :class="[
              'px-2 py-1 rounded cursor-pointer hover:bg-blue-100',
              item.value === selectedNature.value
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200',
            ]"
          >
            <div class="flex justify-center text-sm">{{ item.label }}</div>
            <div class="text-xs">{{ item.description }}</div>
          </li>
        </ul>
      </div>
    </TheModal>
  </div>
</template>

<script>
import TheModal from "@/components/common/TheModal.vue";

export default {
  components: {
    TheModal,
  },
  data() {
    return {
      isModalOpen: false,
      selectedNature: { value: "未選択", label: "未選択", description: "" },
      dropdownItems: [
        { value: "未選択", label: "未選択", description: "" },
        {
          label: "さみしがり",
          value: "さみしがり",
          description: "攻撃↑ 防御↓",
        },
        {
          label: "いじっぱり",
          value: "いじっぱり",
          description: "攻撃↑ 特攻↓",
        },
        { label: "やんちゃ", value: "やんちゃ", description: "攻撃↑ 特防↓" },
        { label: "ゆうかん", value: "ゆうかん", description: "攻撃↑ 素早さ↓" },
        { label: "ずぶとい", value: "ずぶとい", description: "防御↑ 攻撃↓" },
        { label: "わんぱく", value: "わんぱく", description: "防御↑ 特攻↓" },
        {
          label: "のうてんき",
          value: "のうてんき",
          description: "防御↑ 特防↓",
        },
        { label: "のんき", value: "のんき", description: "防御↑ 素早さ↓" },
        { label: "ひかえめ", value: "ひかえめ", description: "特攻↑ 攻撃↓" },
        { label: "おっとり", value: "おっとり", description: "特攻↑ 防御↓" },
        {
          label: "うっかりや",
          value: "うっかりや",
          description: "特攻↑ 特防↓",
        },
        { label: "れいせい", value: "れいせい", description: "特攻↑ 素早さ↓" },
        { label: "おだやか", value: "おだやか", description: "特防↑ 攻撃↓" },
        {
          label: "おとなしい",
          value: "おとなしい",
          description: "特防↑ 防御↓",
        },
        {
          label: "しんちょう",
          value: "しんちょう",
          description: "特防↑ 特攻↓",
        },
        { label: "なまいき", value: "なまいき", description: "特防↑ 素早さ↓" },
        {
          label: "おくびょう",
          value: "おくびょう",
          description: "素早さ↑ 攻撃↓",
        },
        { label: "せっかち", value: "せっかち", description: "素早さ↑ 防御↓" },
        { label: "ようき", value: "ようき", description: "素早さ↑ 特攻↓" },
        { label: "むじゃき", value: "むじゃき", description: "素早さ↑ 特防↓" },
        { label: "てれや", value: "てれや", description: "補正なし" },
        { label: "がんばりや", value: "がんばりや", description: "補正なし" },
        { label: "すなお", value: "すなお", description: "補正なし" },
        { label: "きまぐれ", value: "きまぐれ", description: "補正なし" },
        { label: "まじめ", value: "まじめ", description: "補正なし" },
      ],
    };
  },
  methods: {
    handleModalVisibility(isVisible) {
      this.isModalOpen = isVisible;
    },
    notifySelectedItem(item) {
      this.selectedNature = item;
      this.$emit("update:nature", item.value);
      this.isModalOpen = false;
    },
  },
};
</script>
