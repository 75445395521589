<template>
  <div>
    <poke-memo-update />
  </div>
</template>
<script>
import PokeMemoUpdate from "@/components/memo/PokeMemoUpdate.vue";
export default {
  components: {
    PokeMemoUpdate,
  },
};
</script>
