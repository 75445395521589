<template>
  <div class="container">
    <MemoDisplay />
  </div>
</template>

<script>
import MemoDisplay from "@/components/home/MemoDisplay.vue";

export default {
  components: {
    MemoDisplay,
  },
};
</script>
