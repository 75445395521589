<template>
  <div></div>
</template>

<script>
export default {
  props: {
    field: String,
    moveType: String,
  },
  watch: {
    field: {
      handler: "handleCorrectionFactor",
      immediate: true,
    },
    moveType: {
      handler: "handleCorrectionFactor",
      immediate: true,
    },
  },
  methods: {
    handleCorrectionFactor() {
      let correctionFactor = 1;
      switch (this.field) {
        case "エレキフィールド":
          correctionFactor = this.moveType === "でんき" ? 1.3 : 1;
          break;
        case "グラスフィールド":
          correctionFactor = this.moveType === "くさ" ? 1.3 : 1;
          break;
        case "ミストフィールド":
          correctionFactor = 1;
          break;
        case "サイコフィールド":
          correctionFactor = this.moveType === "エスパー" ? 1.3 : 1;
          break;
      }
      this.$emit("update:fieldEffect", correctionFactor);
    },
  },
};
</script>
