<template>
  <div>
    <activate-account />
  </div>
</template>
<script>
import activateAccount from "@/components/auth/ActivateAccount.vue";
export default {
  components: {
    activateAccount,
  },
};
</script>
