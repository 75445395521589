<template>
  <div class="space-y-3">
    <span class="font-semibold flex justify-center border-b-2 border-blue-500"
      >努力値</span
    >
    <div
      v-for="effort in efforts"
      :key="effort.name"
      class="items-center gap-2"
    >
      <input
        type="range"
        class="range w-full mt-2"
        min="0"
        max="252"
        step="4"
        :value="effort.value"
        @input="updateValue($event, effort.name)"
      />
      <div class="flex justify-between mt-1">
        <button @click="decrement(effort.name)" class="round-button">-</button>
        <span class="mx-2 text-sm">({{ effort.name }}):{{ effort.value }}</span>
        <button @click="increment(effort.name)" class="round-button">+</button>
      </div>
    </div>
  </div>
  <button
    @click="clearEffortValue"
    class="flex justify-center my-4 w-full round-long-button-border"
  >
    全努力値をクリア
  </button>
  <div
    class="flex justify-center mb-1 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-2"
    role="alert"
  >
    <p class="font-bold">{{ totalEVs }} / 全510</p>
  </div>
  <div
    class="flex justify-center bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-2"
    role="alert"
  >
    <p class="font-bold">残り: {{ remainingEVs }}</p>
  </div>
</template>
<script>
export default {
  emits: ["updateEfforts"],
  props: {
    // propが存在した場合それを使用して初期化する
    initialEfforts: {
      type: Array,
      default: () => [
        { name: "体力", key: "eHp", value: 0 },
        { name: "攻撃", key: "eAtk", value: 0 },
        { name: "防御", key: "eDef", value: 0 },
        { name: "特攻", key: "eSpAtk", value: 0 },
        { name: "特防", key: "eSpDef", value: 0 },
        { name: "素早さ", key: "eSpeed", value: 0 },
      ],
    },
  },
  data() {
    return {
      efforts: this.initialEfforts,
      totalEVs: 0,
      remainingEVs: 510,
    };
  },
  created() {
    // propから初期値を設定
    this.efforts = this.initialEfforts;
    this.totalEVs = this.calcTotalEVs();
    this.remainingEVs = 510 - this.totalEVs;
  },
  watch: {
    totalEVs() {
      this.calcRemainingEVs();
    },
    initialEfforts(newValue) {
      // propが変更された場合、effortsを更新
      this.efforts = newValue;
      this.totalEVs = this.calcTotalEVs();
      this.remainingEVs = 510 - this.totalEVs;
    },
  },
  methods: {
    clearEffortValue() {
      this.efforts.forEach((effort) => {
        effort.value = 0;
      });
      this.totalEVs = 0;
      this.$emit("updateEfforts", this.efforts);
    },
    calcRemainingEVs() {
      this.remainingEVs = 510 - this.totalEVs;
    },
    updateValue(event, effortName) {
      const newValue = parseInt(event.target.value);
      const effort = this.efforts.find((effort) => effort.name === effortName);
      const delta = newValue - effort.value;
      // 合計が510を超えない、かつ個々の努力値が252を超えない、かつ新しい値が0以上であることを確認
      if (this.totalEVs + delta <= 510 && newValue <= 252 && newValue >= 0) {
        effort.value = newValue;
        this.totalEVs += delta;
        this.$emit("updateEfforts", this.efforts);
      } else {
        // 範囲外であればスライダーの値を元に戻す
        event.target.value = effort.value;
      }
    },
    increment(effortName) {
      const effort = this.efforts.find((s) => s.name === effortName);
      if (this.totalEVs < 510 && this.remainingEVs >= 4) {
        if (effort.value === 0) {
          effort.value += 4;
          this.totalEVs += 4;
        } else if (effort.value < 252 && this.remainingEVs >= 8) {
          effort.value = Math.min(effort.value + 8, 252);
          this.totalEVs += 8;
        }
      }
    },
    decrement(effortName) {
      const effort = this.efforts.find((s) => s.name === effortName);
      if (effort.value !== 0) {
        if (effort.value <= 4) {
          effort.value = 0;
          if (this.totalEVs > 0) {
            this.totalEVs -= 4;
          }
        } else if (effort.value > 0) {
          effort.value = Math.max(effort.value - 8, 0);
          if (this.totalEVs > 0) {
            this.totalEVs -= 8;
          }
        }
      }
    },
    calcTotalEVs() {
      return this.efforts.reduce((total, effort) => total + effort.value, 0);
    },
  },
};
</script>
