<template>
  <div class="max-w-md mx-auto">
    <button @click="toggleModal">
      <div class="flex items-center space-x-3">
        <span class="icon-[mdi--information] text-2xl text-blue-500"></span>
        <span>使い方を見る</span>
      </div>
    </button>

    <TheModal
      :isVisible="isModalOpen"
      @update:isVisible="handleModalVisibility"
    >
      <template v-slot:default>
        <div class="font-bold text-lg">使い方</div>
        <div class="text-sm">
          <ul>
            <li>1.ランクリストを作成します。</li>
            <li>
              2.ランクリストに追加したいポケモンを検索バー「ポケモンを追加する」から選択（複数体追加可能）
            </li>
            <li>3.選択しポケモンが追加したポケモンに追加されます。</li>
            <li>4.ポケモンをドラッグアンドドロップで並び替える</li>
            <li>
              5.「画像を保存する」をクリックして、作成したランキング画像をダウンロード
            </li>
          </ul>
          <div class="my-3">
            <p class="font-bold">画像を保存</p>
            <span>
              <span class="text-red-500 font-bold">
                「× バツ印」が取り除かれた状態で画像が保存されます。
              </span>
              ダウンロード後も、ランクリストを編集することができます。
            </span>
          </div>
        </div>
      </template>
    </TheModal>
  </div>
</template>

<script>
import TheModal from "@/components/common/TheModal.vue";

export default {
  components: {
    TheModal,
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    handleModalVisibility(value) {
      this.isModalOpen = value;
    },
  },
};
</script>
