<template>
  <the-dropdown
    :items="dropdownItems"
    buttonText="フィールドを選択"
    @select="onSelect"
  >
  </the-dropdown>
</template>

<script>
import TheDropdown from "@/components/common/TheDropdown.vue";

export default {
  components: {
    TheDropdown,
  },
  data() {
    return {
      dropdownItems: [
        { label: "未選択", value: "未選択", description: "" },
        {
          label: "エレキフィールド",
          value: "エレキフィールド",
          description:
            "自分のでんきタイプの技が1.3倍。ねむり、あくび状態にならない。エレキシードを持っていれば、Bが一段階上がる",
        },
        {
          label: "グラスフィールド",
          value: "グラスフィールド",
          description:
            "自分のくさタイプの技が1.3倍。毎ターン最大HPの1/16回復。グラスシードを持っていれば、Bが一段階上がる",
        },
        {
          label: "ミストフィールド",
          value: "ミストフィールド",
          description:
            "状態異常にならない。ドラゴンタイプで受けるダメージが半減。ミストシードを持っていればDが一段階上がる",
        },
        {
          label: "サイコフィールド",
          value: "サイコフィールド",
          description:
            "自分のエスパータイプの技が1.3倍。相手の先制技を受けない。サイコシードを持っていれば、Dが一段階上がる",
        },
      ],
    };
  },
  methods: {
    onSelect(value) {
      this.$store.commit("dmgEnviron/setSelectedField", value.value);
      // this.$emit("update:field", value.value);
    },
  },
};
</script>
