<template>
  <div class="flex space-x-0.5">
    <button
      v-for="modifier in modifiers"
      :key="modifier.value"
      :class="{
        'bg-blue-500 text-white': selectedModifier.value === modifier.value,
        'bg-gray-200 text-black': selectedModifier.value !== modifier.value
        }"    
        @click="selectModifier(modifier.value)"
        class="px-2 py-1 rounded-full transition-colors duration-300 ease-in-out"
    >
      {{ modifier.label }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    atkNature: Boolean,
    defNature: Boolean,
    aNature: Boolean,
    bNature: Boolean,
    cNature: Boolean,
    dNature: Boolean,
    sNature: Boolean,
  },
  data() {
    return {
      selectedModifier: { value: 1.0 },
      modifiers: [
        { value: 0.9, label: "×0.9" },
        { value: 1.0, label: "×1.0" },
        { value: 1.1, label: "×1.1" }
      ]
    };
  },
  methods: {
    selectModifier(value) {
      this.selectedModifier.value = value;
      this.$emit('update:modifier', {
        value, 
        atkNature: this.atkNature,
        defNature: this.defNature,
        hpNature: this.hpNature,
        aNature: this.aNature,
        bNature: this.bNature,
        cNature: this.cNature,
        dNature: this.dNature,
        sNature: this.sNature,
      });
    }
  }
};
</script>
