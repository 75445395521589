<template>
  <div>
    <!-- ヘッダーの下のメニュー -->
    <!-- 
      まだ何を表示するか決めていないが、
      予定としては、ナビゲート用のメニューにするか、一時メンテナンス中ですなどの、告知を行うための
      メニューにするかのどちらか
    -->
  </div>
</template>
