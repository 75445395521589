<template>
  <div class="bg-white shadow-lg rounded-lg container my-3">
    <div class="flex items-center p-4">
      <div class="flex-shrink-0">
        <img
          :src="imageUrl"
          :alt="memoInfo.poke_name"
          class="w-20 h-20 object-cover"
        />
      </div>
      <div
        class="flex-grow ml-4 hover:cursor-pointer text-blue-500"
        @click="jumpToDetail"
      >
        <div class="text-base">
          {{ memoInfo.poke_name }} ({{
            memoInfo.additional_field.poke_types.poke_type1
          }}, {{ memoInfo.additional_field.poke_types.poke_type2 }})
        </div>
        <div class="text-xs">
          H:{{ memoInfo.additional_field.poke_each_stats.poke_hp }} A:{{
            memoInfo.additional_field.poke_attack
          }}
          B:{{ memoInfo.additional_field.poke_each_stats.poke_defense }} C:{{
            memoInfo.additional_field.poke_each_stats.poke_sp_atk
          }}
          D:{{ memoInfo.additional_field.poke_each_stats.poke_sp_def }} S:{{
            memoInfo.additional_field.poke_each_stats.poke_speed
          }}
          合計:{{ memoInfo.additional_field.total_stats }}
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between px-4 py-2">
      <div
        class="inline-block bg-gray-500 text-white px-2 py-1 rounded-full text-xs uppercase tracking-wide mr-2"
      >
        {{ generationName }}
      </div>
      <!--
        一覧画面でもいいね機能を使えるようにすると、初期値のfetchが大量にされるため、なしでいく
        いいね機能を使う場合は、詳細画面でのみ使用する
    -->
      <div class="flex items-center font-bold space-x-2">
        <span class="icon-[mdi--favorite] text-red-500 w-6 h-6"></span>
        <span>{{ memoInfo.favorited_count }}</span>
      </div>
    </div>
    <div class="flex">
      <div v-if="memoInfo.generation === 9" class="flex items-center px-4 py-2">
        <span
          class="inline-block bg-blue-500 text-white px-2 py-1 rounded-full text-xs uppercase tracking-wide mr-2"
        >
          テラスタイプ
        </span>
        <span class="text-gray-700">{{ memoInfo.poke_tera_type }}</span>
      </div>

      <div class="flex items-center px-4 py-2">
        <span
          class="inline-block bg-rose-500 text-white px-2 py-1 rounded-full text-xs uppercase tracking-wide mr-2"
          >特性</span
        >
        <span class="text-gray-700">{{ memoInfo.poke_ability }}</span>
      </div>
    </div>

    <div class="flex">
      <div class="flex items-center px-4 py-2">
        <span
          class="inline-block bg-green-500 text-white px-2 py-1 rounded-full text-xs uppercase tracking-wide mr-2"
        >
          性格
        </span>
        <span class="text-gray-700">{{ memoInfo.poke_nature }}</span>
      </div>

      <div class="flex items-center px-4 py-2">
        <span
          class="inline-block bg-yellow-500 text-white px-2 py-1 rounded-full text-xs uppercase tracking-wide mr-2"
        >
          持ち物
        </span>
        <span class="text-gray-700">{{ memoInfo.poke_item }}</span>
      </div>
    </div>

    <div class="overflow-x-auto">
      <table
        class="w-full mx-2 text-sm text-left border-collapse border border-slate-200 text-gray-500 shadow-md sm:rounded-lg"
      >
        <thead
          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-3 py-3">Lv.{{ memoInfo.poke_level }}</th>
            <th scope="col" class="px-3 py-3">H</th>
            <th scope="col" class="px-3 py-3">A</th>
            <th scope="col" class="px-3 py-3">B</th>
            <th scope="col" class="px-3 py-3">C</th>
            <th scope="col" class="px-3 py-3">D</th>
            <th scope="col" class="px-3 py-3">S</th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th
              scope="row"
              class="px-3 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
            >
              努力値
            </th>
            <td class="px-3 py-4">{{ memoInfo.poke_ev[0].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_ev[1].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_ev[2].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_ev[3].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_ev[4].value }}</td>
            <td class="px-3 py-4">{{ memoInfo.poke_ev[5].value }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="p-4">
      <h3 class="font-bold mb-2">覚えている技</h3>
      <ul class="grid grid-cols-2 gap-2">
        <li
          v-for="(move, index) in moves"
          :key="index"
          class="text-gray-700 text-sm border-block"
        >
          {{ move }}
        </li>
      </ul>
    </div>

    <div
      class="p-3 bg-white rounded-lg hover:cursor-pointer"
      @click="jumpToDetail"
    >
      <div class="mb-1 p-2">
        <div class="font-bold text-indigo-600 line-clamp-1">
          {{
            titleLengthOver70
              ? memoInfo.title.slice(0, 50) + "..."
              : memoInfo.title
          }}
        </div>
        <div class="w-20 h-1 bg-indigo-500 rounded-full"></div>
      </div>
      <div class="bg-indigo-50 rounded-lg p-2 text-sm">
        <span
          class="text-gray-800 line-clamp-3"
          v-html="
            memoLengthOver150
              ? formatMemo(memoInfo.memo.slice(0, 150)) + '.....'
              : formatMemo(memoInfo.memo)
          "
        ></span>
      </div>
    </div>
    <div class="text-right">
      <router-link
        :to="{ name: 'TheHomeDetail', params: { id: memoInfo.id } }"
        class="round-long-button-border mx-6 my-3"
      >
        続きを読む
      </router-link>
    </div>
    <div
      v-if="memoInfo.additional_field.author_info"
      class="flex items-center m-1 pb-3 pl-3"
    >
      {{ memoInfo.user }}
      &nbsp;
      <img
        :src="favoritedImageUrl"
        alt="お気に入りポケモンの画像"
        class="w-10 h-10 border rounded-full object-cover"
      />
      &nbsp;
      {{ memoInfo.additional_field.author_info.user_name }}
      &nbsp;
      {{ formatUpdatedAt }}
    </div>
    <div v-if="isUserMemo" class="flex space-x-4 pb-4 pl-4">
      <div @click="updateMemo" class="round-long-button-border">編集する</div>
      <div @click="showConfirm = true" class="round-long-button-border">
        削除する
      </div>
    </div>
    <confirm-dialog
      :show="showConfirm"
      :title="memoInfo.title"
      :message="`このポケモンメモを削除しますか？`"
      @confirm="deleteMemo"
      @cancel="showConfirm = false"
    />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import { authAxios } from "@/axios";
export default {
  components: {
    ConfirmDialog,
  },
  props: {
    memoInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showConfirm: false,
      generationName: "",
    };
  },
  computed: {
    isLoggedin() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    isUserMemo() {
      // メモがログインユーザーのものかどうかを判定
      return (
        this.isLoggedin &&
        this.memoInfo.user === this.$store.state.auth.userData.id
      );
    },
    imageUrl() {
      return this.memoInfo.additional_field.poke_image_src;
    },
    favoritedImageUrl() {
      if (this.memoInfo.additional_field.author_info.favorite_poke_image_src) {
        return this.memoInfo.additional_field.author_info
          .favorite_poke_image_src;
      } else {
        return "hoge.png";
      }
    },
    formatUpdatedAt() {
      if (this.memoInfo.updated_at) {
        const formatData = new Date(this.memoInfo.updated_at);
        return formatData.toLocaleString("ja-JP"); // 'ja-JP' locale results in 'yyyy/mm/dd hh:mm:ss' format
      } else {
        return "";
      }
    },
    moves() {
      return [
        this.memoInfo.poke_move1,
        this.memoInfo.poke_move2,
        this.memoInfo.poke_move3,
        this.memoInfo.poke_move4,
      ];
    },
    titleLengthOver70() {
      return this.memoInfo.title.length > 70;
    },
    memoLengthOver150() {
      return this.memoInfo.memo.length > 150;
    },
    memoLength() {
      return this.memoInfo.memo.length;
    },
    memoTitle() {
      return this.memoInfo.title.length;
    },
  },
  methods: {
    formatMemo(memo) {
      // 改行を <br> タグに置き換える
      memo = memo.replace(/\n/g, "<br>");

      // URLを <a> タグでラップする
      memo = memo.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank">$1</a>'
      );
      return memo;
    },
    async deleteMemo() {
      await authAxios.delete(
        `/api/pokememo/list/user/retrieve/${this.memoInfo.id}`
      );
      this.showConfirm = false;

      /* 
      画面上に表示されているメモを取り除く、emitで配列から削除だと、４つのメモがあるため
      画面をリロードするのが無難？なのかな...う～ん個人的にはいやだけどそれがベストな気がするしな...
      */
      location.reload();
    },
    updateMemo() {
      this.$store.commit("updateMemo/setMemo", this.memoInfo);
      this.$router.push({
        name: "PokeMemoUpdate",
        params: { id: this.memoInfo.id },
      });
    },
    jumpToDetail() {
      this.$router.push({
        name: "TheHomeDetail",
        params: { id: this.memoInfo.id },
      });
    },
  },
  created() {
    // memoInfo.generationに応じて、世代ごとにタグを変える
    if (this.memoInfo.generation) {
      this.generationName = `${this.memoInfo.generation}世代のデータ`;
    }
  },
};
</script>
