import { publicAxios } from "@/axios";
import router from "@/router";

// 初期状態
const state = {
  accessToken: localStorage.getItem("accessToken") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,
  userData: JSON.parse(localStorage.getItem("userData")) || null,
  redirectMessage: "",
};

// ゲッター
const getters = {
  // localstrageから取得しようとすると,parseとかいろいろめんどくさいので、
  // this.$store.getters["auth/isLoggedIn"]で簡単にboolを取得できるようにしている
  // ここまでやるならライブラリを使った方がいいかもしれない.....
  isLoggedIn: (state) => !!state.accessToken,
  // 常時ログアウト状態にしたい場合下記を使用
  // isLoggedIn: () => {
  //   return false;
  // },
  userData: (state) => state.userData,
};

// ミューテーション
const mutations = {
  setTokens(state, { access, refresh }) {
    state.accessToken = access;
    state.refreshToken = refresh;
  },
  setRedirectMessage(state, message) {
    state.redirectMessage = message;
  },
  setUserData(state, userData) {
    state.userData = userData;
  },
};

// アクション
const actions = {
  async login({ commit }, { email, password }) {
    try {
      const response = await publicAxios.post("/api/auth/jwt/create/", {
        email,
        password,
      });
      console.log("response for token:", response.data);
      const { access, refresh } = response.data;
      // トークンをローカルストレージに保存
      localStorage.setItem("accessToken", access);
      localStorage.setItem("refreshToken", refresh);
      commit("setTokens", { access, refresh });
      // アクセストークンをセットしてユーザー情報を取得するAPIエンドポイントを呼び出す
      const userResponse = await publicAxios.get("/api/auth/users/me/", {
        headers: {
          Authorization: `JWT ${access}`,
        },
      });
      // ユーザー情報を取得
      // console.log("userResponse:", userResponse);
      const userData = userResponse.data;
      // ユーザー情報をローカルストレージに保存後、stateにもセット
      // ユーザー情報をJSON文字列に変換してローカルストレージに保存後、stateにもセット
      localStorage.setItem("userData", JSON.stringify(userData));
      commit("setUserData", userData);
      return true; // ログイン成功
    } catch (error) {
      console.error("Login Error:", error);
      let errorMessage;

      if (error.response) {
        const { status } = error.response;
        if (status === 400) {
          errorMessage = "メールアドレスまたはパスワードが正しくありません。";
        } else if (status === 401) {
          errorMessage =
            "認証に失敗しました。メールアドレスまたはパスワードが正しくありません";
        } else if (status === 500) {
          errorMessage = "サーバーエラーが発生しました。";
        }
      } else if (error.request) {
        errorMessage = "サーバーからの応答がありません。";
      } else {
        errorMessage = "メールアドレスまたはパスワードが正しくありません。";
      }
      // this.$toast.open({
      //   message: errorMessage,
      //   position: "top",
      //   type: "error",
      //   duration: 5000,
      // });
      commit("setUserData", null);
      throw new Error(errorMessage);
    }
  },
  logout({ commit }) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    commit("setTokens", { access: "", refresh: "" });
  },
  async refreshAccessToken({ state, commit }) {
    try {
      const response = await publicAxios.post("/api/auth/jwt/refresh/", {
        refresh: state.refreshToken,
      });
      const { access } = response.data;
      localStorage.setItem("accessToken", access);
      commit("setTokens", { access, refresh: state.refreshToken });
    } catch (error) {
      console.error("Refresh Access Token Error:", error);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      commit("setTokens", { access: "", refresh: "" });
      router.push("/login");
      throw new Error("トークンが切れました。再度ログインしてください。");
    }
  },
  // 認証関連のリダイレクトする際のメッセージをセット
  setRedirectMessage({ commit }, message) {
    commit("setRedirectMessage", message);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
