<template>
  <div class="mx-auto">
    <div class="sticky top-0 bg-white mb-5 z-40">
      <the-pagination
        :totalPages="totalPages"
        :currentPage="currentPage"
        :hasNextPage="hasNextPage"
        :hasPreviousPage="hasPreviousPage"
        @pageChange="fetchPokemonList"
      />
    </div>
    <!-- TODO: 検索機能つけたいがいったんパス -->
    <BackToTopArrow />
    <div class="flex items-center mb-8 border-b-2 border-gray-700">
      <span class="icon-[mdi--note-plus-outline] text-xl mr-2"></span>
      <div class="text-lg font-bold">投稿したポケメモ</div>
    </div>
    <div class="memo-card-container">
      <div class="flex flex-wrap -mx-2">
        <div
          v-for="pokemon in pokemonList"
          :key="pokemon.id"
          class="w-full sm:w-1/2 md:w-1/3 px-2 mb-4"
        >
          <memo-card :memo-info="pokemon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authAxios } from "@/axios";
import BackToTopArrow from "@/components/common/BackToTopArrow.vue";
import ThePagination from "@/components/common/ThePagination.vue";
import MemoCard from "../MemoCard.vue";

export default {
  components: {
    BackToTopArrow,
    ThePagination,
    MemoCard,
  },
  data() {
    return {
      pokemonList: [],
      totalPages: 0,
      currentPage: 1,
      hasNextPage: false,
      hasPreviousPage: false,
    };
  },
  methods: {
    async fetchPokemonList(page = 1) {
      const response = await authAxios.get(
        `/api/pokememo/list/user?page=${page}`
      );
      // console.log("respose.data =>", response.data);
      this.pokemonList = response.data.results;
      this.totalPages = Math.ceil(response.data.count / 10); // 1ページあたり10件とする
      this.currentPage = page;
      this.hasNextPage = response.data.next !== null;
      this.hasPreviousPage = response.data.previous !== null;
    },
  },

  created() {
    this.fetchPokemonList();
    // console.log("UserMemo.vue created");
  },
};
</script>

<style scoped></style>
