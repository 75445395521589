<template>
  <div>
    <!-- シェアボタン -->
    <button @click="openModal" class="font-bold">
      <span class="icon-[bi--share] text-xl"></span>
    </button>

    <!-- モーダルウィンドウ -->
    <TheModal
      :isVisible="showModal"
      @update:isVisible="showModal = $event"
      width="max-w-lg"
    >
      <div
        class="flex flex-col items-center justify-center p-6 bg-white rounded-lg"
      >
        <div class="flex justify-between w-full mb-4">
          <sns-share-button sns-name="twitter" @shared="closeModal" />
          <sns-share-button sns-name="facebook" @shared="closeModal" />
          <sns-share-button sns-name="line" @shared="closeModal" />
        </div>
      </div>
    </TheModal>
  </div>
</template>

<script>
import TheModal from "../TheModal.vue";
import SnsShareButton from "./SnsShareButton.vue";

export default {
  components: {
    TheModal,
    SnsShareButton,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>
