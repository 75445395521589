import RegisterFormView from "@/views/RegisterFormView.vue";
import ActivateAccountsView from "@/views/ActivateAccountsView.vue";
import ActivateEmailView from "@/views/ActivateEmailView.vue";
import LoginFormView from "@/views/LoginFormView.vue";
import PasswordChangeView from "@/views/PasswordChangeView.vue";

import store from "@/store";

const authRoutes = [
  {
    path: "register",
    component: RegisterFormView,
    meta: {
      title: "新規アカウント登録",
      description:
        "あなたのポケプロジェクトへの旅を始めよう。数ステップで簡単に登録可能。",
    },
  },
  {
    path: "activation",
    component: ActivateAccountsView,
    meta: {
      title: "アカウント有効化",
      description:
        "アカウントを有効化して、ポケプロジェクトの全機能を利用しよう。",
    },
    query: { uid: ":uid", token: ":token" },
  },
  {
    path: "activation/email",
    component: ActivateEmailView,
    meta: {
      title: "メールアドレス変更の有効化",
      description: "メールアドレスの変更を有効化",
    },
    query: { uid: ":uid", token: ":token" },
  },
  {
    path: "login",
    component: LoginFormView,
    meta: {
      title: "アカウントログイン",
      description:
        "既存のアカウントへのログイン。あなたのポケモンジャーニーを今すぐ再開しよう。",
    },
  },
  {
    path: "password/change",
    component: PasswordChangeView,
    name: "PasswordChange",
    meta: {
      title: "パスワード変更",
      description: "アカウントのパスワードを変更",
    },
    beforeEnter: (to, from, next) => {
      // ログインしていない場合はログインページにリダイレクト
      if (!localStorage.getItem("accessToken")) {
        store.commit(
          "auth/setRedirectMessage",
          "ログインしていないと利用できないページです。"
        );
        next("/login");
      } else {
        next();
      }
    },
  },
];

export default authRoutes;
