import { authAxios } from "@/axios";

const state = {
  searchedPokeMemo: null,
};

const getters = {
  getSearchedPokeMemo(state) {
    return state.searchedPokeMemo;
  },
};

const mutations = {
  setSearchedPokeMemo(state, pokeMemo) {
    state.searchedPokeMemo = pokeMemo;
  },
};

const actions = {
  async searchPokeMemo({ commit }, { page = 1, search = "" }) {
    try {
      const response = await authAxios.get("/api/pokememo/list/user", {
        params: {
          page,
          search,
        },
      });
      commit("setSearchedPokeMemo", response.data);
    } catch (error) {
      console.error("Error fetching PokeMemos:", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
