<template>
  <div class="flex items-center">
    <button @click="toggleIconValue">
      <span :class="iconClass" class="w-4 h-4 align-middle"></span>
      <span class="align-middle">ひかりの壁</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    moveClass: String,
  },
  data() {
    return {
      isLightScreen: false,
    };
  },
  computed: {
    iconClass() {
      return this.isLightScreen
        ? "icon-[mdi--circle-outline]"
        : "icon-[mdi--circle-off-outline]";
    },
    caluculateWallValue() {
      let wallValue = 1;
      if (this.isLightScreen && this.moveClass === "特殊") {
        return wallValue * 0.5;
      } else {
        return wallValue;
      }
    },
  },
  methods: {
    toggleIconValue() {
      this.isLightScreen = !this.isLightScreen;
      const newValue = this.caluculateWallValue;
      this.$store.commit("dmg/setLightScreenCorrect", newValue);
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>
