<template>
  <div class="h-screen">
    <div class="bg-white shadow-md rounded-lg p-6">
      <div class="flex items-center mb-6">
        <img
          :src="ImageSrc"
          alt="プロファイル画像"
          class="w-24 h-24 rounded-full border-4 border-white mr-4"
        />
        <div>
          <h2 class="text-2xl font-bold mb-1">{{ profile.name }}</h2>
          <p class="text-gray-600">@{{ profile.name }}</p>
        </div>
      </div>
      <div class="mb-4">
        <h3 class="font-semibold text-lg mb-2">自己紹介</h3>
        <p class="text-gray-800">{{ profile.bio }}</p>
      </div>
      <div class="mb-4">
        <h3 class="font-semibold text-lg mb-2">お気に入りポケモン</h3>
        <div class="flex items-center">
          <img
            :src="ImageSrc"
            alt="プロファイル画像"
            class="w-12 h-12 rounded-full mr-2"
          />
          <p class="text-gray-800">{{ profile.favorited_pokemon }}</p>
        </div>
      </div>
      <!-- <div class="flex items-center text-gray-600">
        <span class="icon-[mdi--email] w-8 h-8 mr-4"></span>
        <p>{{ profile.email }}</p>
      </div> -->
    </div>
    <div>
      <router-link to="/profile/edit" class="flex justify-center mt-5">
        <span class="text-blue-500">プロフィール編集</span>
      </router-link>

      <router-link to="/home/user" class="flex justify-center mt-5">
        <span class="text-blue-500">投稿したメモ一覧</span>
      </router-link>

      <router-link to="/home/user/favorited" class="flex justify-center mt-5">
        <span class="text-blue-500">お気に入りメモ一覧</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      error: null,
    };
  },
  computed: {
    profile() {
      return this.$store.getters["profile/getProfile"];
    },
    ImageSrc() {
      return this.profile.additional_field
        ? this.profile.additional_field.favorite_poke_image_src
        : "";
    },
  },
  methods: {
    async fetchProfile() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("profile/fetchProfile");
      } catch (error) {
        this.error = error.toString();
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.fetchProfile();
  },
};
</script>
