<template>
  <div class="relative">
    <!-- モーダルトリガー -->
    <div class="mb-1 flex justify-center">
      <button
        @click="toggleDropdown"
        class="px-2 py-1 bg-blue-500 text-white rounded text-sm hover:bg-blue-600"
        :class="propWhidth"
      >
        {{ buttonText }}
      </button>
    </div>

    <!-- ドロップダウンコンテンツ -->
    <div
      v-if="isOpen"
      class="dropdown absolute z-10 bg-white rounded-md border border-gray-200"
      :class="{ 'w-full': isOpen }"
      @click.stop
    >
      <slot></slot>
      <ul class="bg-white rounded-md divide-y divide-gray-200 shadow">
        <li
          v-for="(item, index) in items"
          :key="index"
          @click="selectItem(item)"
          :class="[
            'px-3 py-2 hover:bg-gray-200 cursor-pointer',
            item === selectedItem ? 'bg-blue-400 text-white' : '',
          ]"
        >
          <div class="text-sm font-semibold">{{ item.label }}</div>
          <div class="text-xs">{{ item.description }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownModal",
  props: {
    items: Array,
    buttonText: String,
    propWhidth: {
      default: "w-full",
      type: String,
    },
  },
  data() {
    return {
      isOpen: false,
      selectedItem: "", //mountedされたら、ここがitemsの最初の要素になる
    };
  },
  methods: {
    toggleDropdown(event) {
      event.stopPropagation();
      this.isOpen = !this.isOpen;
    },
    selectItem(item) {
      this.selectedItem = item;
      this.$emit("select", item);
      this.isOpen = false;
    },
    closeDropdown(event) {
      if (this.isOpen && !this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
  },
  mounted() {
    document.addEventListener("mousedown", this.closeDropdown);
    this.selectedItem = this.items[0]; // ここでitemsの最初の要素を選択
  },
  beforeUnmount() {
    document.removeEventListener("mousedown", this.closeDropdown);
  },
};
</script>
