<template>
  <div class="container mx-auto">
    <BackToTopArrow />

    <div class="flex items-center mb-4 border-b-2 border-gray-700">
      <span class="icon-[mdi--pencil-plus-outline] text-xl"></span>
      <div class="text-lg font-bold">ポケメモ アップデート</div>
    </div>
    <div class="flex space-x-3">
      <div class="text-xs">ポケモン</div>
      <div class="text-xs">※ 変更はできません</div>
    </div>
    <div class="border-block text-gray-400">
      {{ selectedPokemon?.poke_name }}
    </div>

    <div
      v-if="selectedPokemon"
      class="flex items-center p-1 m-2 bg-white rounded-lg shadow-md"
    >
      <div class="flex-shrink-0">
        <a :href="selectedPokemon.poke_image_src">
          <img :src="selectedPokemon.poke_image_src" alt="" class="w-12 h-12" />
        </a>
      </div>
      <div class="flex-grow ml-4">
        <div class="text-base">
          {{ selectedPokemon.poke_name }}
          ({{ selectedPokemon.poke_type }}, {{ selectedPokemon.poke_type2 }})
          <div
            v-if="selectedTeraType !== '未選択'"
            class="text-sm text-blue-500 font-semibold"
          >
            テラタイプ:{{ selectedTeraType }}
          </div>
        </div>
        <div class="text-xs">
          H:{{ selectedPokemon.poke_hp }} A:{{
            selectedPokemon.poke_attack
          }}
          B:{{ selectedPokemon.poke_defense }} C:{{
            selectedPokemon.poke_sp_atk
          }}
          D:{{ selectedPokemon.poke_sp_def }} S:{{
            selectedPokemon.poke_speed
          }}
          合計:{{ selectedPokemon.poke_base_stats }}
        </div>
      </div>
    </div>

    <div v-if="selectedPokemon">
      <abilities-selector
        :abilities="getAbilities(selectedPokemon)"
        :selected-pokemon="selectedPokemon"
        @ability-selected="handleAbilitySelected"
        @ability-reset="handleAbilityReset"
      />
    </div>

    <div class="mb-5">
      <div v-for="index in 4" :key="index" class="mt-4">
        <div class="text-xs">攻撃技{{ index }}</div>
        <poke-move-suggest
          :placeholder-text="
            selectedMoves[index - 1]
              ? selectedMoves[index - 1].move_name
              : '未選択'
          "
          @move-memo="handleMove(index, $event)"
        />
        <div v-if="selectedMoves[index - 1]" class="text-xs">
          {{ selectedMoves[index - 1].move_type }}
          {{ selectedMoves[index - 1].move_class }}
          威力:{{ selectedMoves[index - 1].move_power }} 命中率:{{
            selectedMoves[index - 1].move_accuracy
          }}
        </div>
      </div>
    </div>

    <div class="grid grid-cols-9 mt-8">
      <div class="col-span-4">
        <ChoiceTeraType
          :value="selectedTeraType"
          @input="selectedTeraType = $event"
        />
        <span class="text-xs">テラタイプ:{{ selectedTeraType }}</span>
      </div>
      <div class="col-span-1"></div>
      <span class="col-span-4">
        <NatureSelecter @update:nature="pokeNature = $event" />
        <span class="text-xs">性格:{{ pokeNature }}</span>
      </span>
    </div>

    <div class="grid grid-cols-9 mt-4">
      <div class="col-span-4">
        <AtkItemsList @atkItemSelected="handleAtkItem" />
        <span class="text-xs">&nbsp;攻撃側アイテム:{{ atkItem.name }}</span>
      </div>
      <div class="col-span-1"></div>
      <div class="col-span-4">
        <DefItemsList @defItemSelected="handleDefItem" />
        <span class="text-xs">&nbsp;防御側アイテム:{{ defItem.name }}</span>
      </div>
    </div>

    <div v-if="selectedPokemon">
      <div class="mt-4">
        <CalcHP
          :modelPokeHP="Number(selectedPokemon.poke_hp)"
          :modelIV="indivisualValue[0].value"
          :modelEV="effortValue[0].value"
          :modelLevel="Number(pokeLevel)"
          @update:modelValue="calcedHP = $event"
        />
      </div>

      <div>
        <calc-plane-status
          :selected-pokemon="selectedPokemon"
          :what-status="'A'"
          :modelIV="indivisualValue[1].value"
          :modelEV="effortValue[1].value"
          :model-level="Number(pokeLevel)"
          :model-nature="pokeNature"
          @update:perStatus="calcedA = $event"
        />
        <calc-plane-status
          :selected-pokemon="selectedPokemon"
          :what-status="'B'"
          :modelIV="indivisualValue[2].value"
          :modelEV="effortValue[2].value"
          :model-level="Number(pokeLevel)"
          :model-nature="pokeNature"
          @update:perStatus="calcedB = $event"
        />
        <calc-plane-status
          :selected-pokemon="selectedPokemon"
          :what-status="'C'"
          :modelIV="indivisualValue[3].value"
          :modelEV="effortValue[3].value"
          :model-level="Number(pokeLevel)"
          :model-nature="pokeNature"
          @update:perStatus="calcedC = $event"
        />
        <calc-plane-status
          :selected-pokemon="selectedPokemon"
          :what-status="'D'"
          :modelIV="indivisualValue[4].value"
          :modelEV="effortValue[4].value"
          :model-level="Number(pokeLevel)"
          :model-nature="pokeNature"
          @update:perStatus="calcedD = $event"
        />
        <calc-plane-status
          :selected-pokemon="selectedPokemon"
          :what-status="'S'"
          :modelIV="indivisualValue[5].value"
          :modelEV="effortValue[5].value"
          :model-level="Number(pokeLevel)"
          :model-nature="pokeNature"
          @update:perStatus="calcedS = $event"
        />
      </div>
    </div>

    <div v-if="selectedPokemon" class="border-2 mt-4">
      <div class="flex items-center">
        <div
          class="flex items-center p-1 m-2 w-full bg-white rounded-lg shadow-md"
        >
          <!-- 画像部分 (flex-shrink-0 でサイズを固定) -->
          <div class="flex-shrink-0">
            <a :href="selectedPokemon.poke_image_src">
              <img
                :src="selectedPokemon.poke_image_src"
                alt=""
                class="w-12 h-12"
              />
            </a>
          </div>
          <!-- 情報部分 (flex-grow で残りのスペースを埋める) -->
          <div class="flex-grow ml-4">
            <div class="text-base">
              {{ selectedPokemon.poke_name }}
              ({{ selectedPokemon.poke_type }},
              {{ selectedPokemon.poke_type2 }})
              <div
                v-if="selectedTeraType !== '未選択'"
                class="text-sm text-blue-500 font-semibold"
              >
                テラタイプ:{{ selectedTeraType }}
              </div>
            </div>
            <div class="text-xs">
              <span>種族値:</span>
              H:{{ selectedPokemon.poke_hp }} A:{{
                selectedPokemon.poke_attack
              }}
              B:{{ selectedPokemon.poke_defense }} C:{{
                selectedPokemon.poke_sp_atk
              }}
              D:{{ selectedPokemon.poke_sp_def }} S:{{
                selectedPokemon.poke_speed
              }}
              合計:{{ selectedPokemon.poke_base_stats }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="mt-4 flex flex-col space-y-2 w-full max-w-lg mx-auto overflow-x-auto"
      >
        <table class="min-w-full text-left whitespace-no-wrap">
          <thead>
            <tr
              class="font-semibold tracking-wide text-left text-blue-500 uppercase border-b dark:border-gray-700 bg-gray-50"
            >
              <th class="px-4 py-3"></th>
              <th class="px-4 py-3">H</th>
              <th class="px-4 py-3">A</th>
              <th class="px-4 py-3">B</th>
              <th class="px-4 py-3">C</th>
              <th class="px-4 py-3">D</th>
              <th class="px-4 py-3">S</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y dark:divide-gray-700">
            <tr class="text-gray-700">
              <td class="px-4 py-3 font-semibold">努力値</td>
              <td class="px-4 py-3 font-mono">{{ effortValue[0].value }}</td>
              <td class="px-4 py-3 font-mono">{{ effortValue[1].value }}</td>
              <td class="px-4 py-3 font-mono">{{ effortValue[2].value }}</td>
              <td class="px-4 py-3 font-mono">{{ effortValue[3].value }}</td>
              <td class="px-4 py-3 font-mono">{{ effortValue[4].value }}</td>
              <td class="px-4 py-3 font-mono">{{ effortValue[5].value }}</td>
            </tr>
            <tr class="text-gray-700">
              <td class="px-4 py-3 font-semibold">個体値</td>
              <td class="px-4 py-3 font-mono">
                {{ indivisualValue[0].value }}
              </td>
              <td class="px-4 py-3 font-mono">
                {{ indivisualValue[1].value }}
              </td>
              <td class="px-4 py-3 font-mono">
                {{ indivisualValue[2].value }}
              </td>
              <td class="px-4 py-3 font-mono">
                {{ indivisualValue[3].value }}
              </td>
              <td class="px-4 py-3 font-mono">
                {{ indivisualValue[4].value }}
              </td>
              <td class="px-4 py-3 font-mono">
                {{ indivisualValue[5].value }}
              </td>
            </tr>
            <tr class="text-gray-700">
              <td class="px-4 py-3 font-semibold">実数値</td>
              <td class="px-4 py-3 font-mono">{{ calcedHP }}</td>
              <td class="px-4 py-3 font-mono">{{ calcedA }}</td>
              <td class="px-4 py-3 font-mono">{{ calcedB }}</td>
              <td class="px-4 py-3 font-mono">{{ calcedC }}</td>
              <td class="px-4 py-3 font-mono">{{ calcedD }}</td>
              <td class="px-4 py-3 font-mono">{{ calcedS }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="mt-4">
      <LevelCounter
        :model-value="Number(pokeLevel)"
        @update:modelValue="pokeLevel = $event"
      />
    </div>

    <div class="grid grid-cols-9 mt-4">
      <div class="col-span-4">
        <MemoEVCounter
          :initial-efforts="effortValue"
          @updateEfforts="handleEVUpdate"
        />
      </div>
      <span class="col-span-1"></span>
      <div class="col-span-4">
        <MemoIVCounter
          :initial-i-vs="indivisualValue"
          @updateIVs="handleIVUpdate"
        />
      </div>
    </div>

    <div class="p-4 space-y-4 mt-4">
      <input
        type="text"
        v-model="pokeTitle"
        placeholder="タイトルを入力"
        class="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
      />
      <textarea
        v-model="pokeMemo"
        placeholder="メモ情報を入力（個人情報は入力しないでください）"
        class="w-full p-2 h-60 border text-xs border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
      ></textarea>
      <div class="flex">
        <button @click="clearTitleForm" class="round-long-button-border mx-2">
          タイトルをクリア
        </button>
        <button @click="clearMemoForm" class="round-long-button-border mx-2">
          メモ情報をクリア
        </button>
      </div>
      <PokeMemoForm
        :selected-pokemon="selectedPokemon"
        :selected-pokemon-ability="selectedPokemonAbility.name"
        :selected-tera-type="selectedTeraType"
        :memo-move1="selectedMoves[0]?.move_name"
        :memo-move2="selectedMoves[1]?.move_name"
        :memo-move3="selectedMoves[2]?.move_name"
        :memo-move4="selectedMoves[3]?.move_name"
        :poke-level="Number(pokeLevel)"
        :poke-nature="pokeNature"
        :effort-value="effortValue"
        :indivisual-value="indivisualValue"
        :calced-h-p="Number(calcedHP)"
        :calced-a="Number(calcedA)"
        :calced-b="Number(calcedB)"
        :calced-c="Number(calcedC)"
        :calced-d="Number(calcedD)"
        :calced-s="Number(calcedS)"
        :poke-title="pokeTitle"
        :poke-memo="pokeMemo"
        :decided-item="decidedItem"
        :update-flag="true"
      />
    </div>

    <code>
      <span>アップデート デバック用(コメント残す)</span>
      <pre>HP努力値:{{ effortValue[0].value }}</pre>
      <pre>攻撃努力値:{{ effortValue[1].value }}</pre>
      <pre>防御努力値:{{ effortValue[2].value }}</pre>
      <pre>特攻努力値:{{ effortValue[3].value }}</pre>
      <pre>特防努力値:{{ effortValue[4].value }}</pre>
      <pre>素早さ努力値:{{ effortValue[5].value }}</pre>
      <br />

      <pre>HP個体値:{{ indivisualValue[0].value }}</pre>
      <pre>攻撃個体値:{{ indivisualValue[1].value }}</pre>
      <pre>防御個体値:{{ indivisualValue[2].value }}</pre>
      <pre>特攻個体値:{{ indivisualValue[3].value }}</pre>
      <pre>特防個体値:{{ indivisualValue[4].value }}</pre>
      <pre>素早さ個体値:{{ indivisualValue[5].value }}</pre>
      <br />

      <pre>計算済みHP:{{ calcedHP }}</pre>
      <pre>計算済みA:{{ calcedA }}</pre>
      <pre>計算済みB:{{ calcedB }}</pre>
      <pre>計算済みC:{{ calcedC }}</pre>
      <pre>計算済みD:{{ calcedD }}</pre>
      <pre>計算済みS:{{ calcedS }}</pre>
      <br />
    </code>
  </div>
</template>

<script>
/* ポケモンのメモを表示するページ
 *  メモの内容は、ポケモンのタイトル、性格、特性、技、個体値、努力値、持ち物、メモ
 */
import { publicAxios } from "@/axios";

// memo only
import MemoEVCounter from "./MemoEVCounter.vue";
import MemoIVCounter from "./MemoIVCounter.vue";
import PokeMemoForm from "./PokeMemoForm.vue";

// api
import PokeMoveSuggest from "@/components/common/api/PokeMoveSuggest.vue";

// dmg9
import AbilitiesSelector from "@/components/dmg9/ability/AbilitiesSelector.vue";
import ChoiceTeraType from "@/components/dmg9/tera_type/ChoiceTeraType.vue";
import NatureSelecter from "./NatureSelecter.vue";
import BackToTopArrow from "@/components/common/BackToTopArrow.vue";

// dmg9/counter
import LevelCounter from "@/components/dmg9/counter/LevelCounter.vue";

// common/calc_status
import CalcPlaneStatus from "@/components/dmg9/calc_status/CalcPlaneStatus.vue";
import CalcHP from "@/components/dmg9/calc_status/CalcHP.vue";

// common/items
import AtkItemsList from "@/components/dmg9/items/AtkItemsList.vue";
import DefItemsList from "@/components/dmg9/items/DefItemsList.vue";

export default {
  components: {
    PokeMoveSuggest,
    AbilitiesSelector,
    ChoiceTeraType,
    AtkItemsList,
    DefItemsList,
    MemoEVCounter,
    MemoIVCounter,
    PokeMemoForm,
    LevelCounter,
    NatureSelecter,
    CalcPlaneStatus,
    CalcHP,
    BackToTopArrow,
  },
  data() {
    return {
      updateFlag: false,
      selectedPokemon: null,
      selectedPokemonAbility: "",
      selectedTeraType: "未選択",
      selectedMoves: [],
      pokeLevel: 50,
      pokeNature: "未選択",
      effortValue: [
        { name: "体力", key: "eHp", value: 0 },
        { name: "攻撃", key: "eAtk", value: 0 },
        { name: "防御", key: "eDef", value: 0 },
        { name: "特攻", key: "eSpAtk", value: 0 },
        { name: "特防", key: "eSpDef", value: 0 },
        { name: "素早さ", key: "eSpeed", value: 0 },
      ],
      indivisualValue: [
        { name: "体力", key: "iHp", value: 31 },
        { name: "攻撃", key: "iAtk", value: 31 },
        { name: "防御", key: "iDef", value: 31 },
        { name: "特攻", key: "iSpAtk", value: 31 },
        { name: "特防", key: "iSpDef", value: 31 },
        { name: "素早さ", key: "iSpeed", value: 31 },
      ],
      calcedHP: "",
      calcedA: "",
      calcedB: "",
      calcedC: "",
      calcedD: "",
      calcedS: "",
      pokeTitle: "",
      pokeMemo: "",
      decidedItem: "未選択",
      atkItem: { name: "未選択", description: "" },
      defItem: { name: "未選択", description: "" },
    };
  },
  computed: {
    isUpdateMemo() {
      return this.$store.getters["updateMemo/existMemo"];
    },
  },
  watch: {
    // atkItemとdefItemが変更されたらhandleDecidedItemを実行
    atkItem: {
      handler: function (newVal) {
        this.handleDecidedItem(newVal);
      },
      deep: true,
    },
    defItem: {
      handler: function (newVal) {
        this.handleDecidedItem(newVal);
      },
      deep: true,
    },
  },
  methods: {
    handleAbilitySelected(ability) {
      this.selectedPokemonAbility = ability; // 選択されたアビリティを更新
    },
    handleAbilityReset() {
      this.selectedPokemonAbility = ""; // アビリティの選択をリセット
    },
    getAbilities(pokemon) {
      return [
        pokemon.poke_ability,
        pokemon.poke_ability2,
        pokemon.poke_abi_hidden,
      ].filter(Boolean); // 空の値を除外
    },
    handleMove(index, move) {
      // Vue 3では直接代入でリアクティブな更新が可能
      this.selectedMoves[index - 1] = move;
    },
    handleEVUpdate(efforts) {
      this.effortValue = efforts;
    },
    handleIVUpdate(ivs) {
      this.indivisualValue = ivs;
    },
    handleAtkItem(atkItemObj) {
      if (this.defItem.name !== "未選択") {
        this.defItem = { name: "未選択", description: "" };
        this.atkItem = atkItemObj;
      } else {
        this.atkItem = atkItemObj;
      }
    },
    handleDefItem(defItemObj) {
      if (this.atkItem.name !== "未選択") {
        this.atkItem = { name: "未選択", description: "" };
        this.defItem = defItemObj;
      } else {
        this.defItem = defItemObj;
      }
    },
    handleDecidedItem(item) {
      this.decidedItem = item.name;
    },

    // 各フォームをクリアする
    clearTitleForm() {
      this.pokeTitle = "";
    },
    clearMemoForm() {
      this.pokeMemo = "";
    },

    // update用の情報を取得する
    async fetchPokemon() {
      const response = await publicAxios.get("/api/pokemons/", {
        params: { search: this.$store.getters["updateMemo/memo"].poke_name },
      });
      this.selectedPokemon = response.data[0];
    },

    async fetchMoves0() {
      const response = await publicAxios.get("/api/moves/", {
        params: { search: this.$store.getters["updateMemo/memo"].poke_move1 },
      });
      this.selectedMoves[0] = response.data[0];
    },

    async fetchMoves1() {
      const response = await publicAxios.get("/api/moves/", {
        params: { search: this.$store.getters["updateMemo/memo"].poke_move2 },
      });
      this.selectedMoves[1] = response.data[0];
    },

    async fetchMoves2() {
      const response = await publicAxios.get("/api/moves/", {
        params: { search: this.$store.getters["updateMemo/memo"].poke_move3 },
      });
      this.selectedMoves[2] = response.data[0];
    },

    async fetchMoves3() {
      const response = await publicAxios.get("/api/moves/", {
        params: { search: this.$store.getters["updateMemo/memo"].poke_move4 },
      });
      this.selectedMoves[3] = response.data[0];
    },
  },
  mounted() {
    if (this.$store.getters["updateMemo/memo"]) {
      this.updateFlag = true;
      const memo = this.$store.getters["updateMemo/memo"];
      this.pokeTitle = memo.title;
      this.pokeMemo = memo.memo;

      this.selectedTeraType = memo.poke_tera_type;
      this.pokeLevel = memo.poke_level;
      this.pokeNature = memo.poke_nature;
      this.effortValue = memo.poke_ev;
      this.indivisualValue = memo.poke_iv;

      this.fetchPokemon();
      this.fetchMoves0();
      this.fetchMoves1();
      this.fetchMoves2();
      this.fetchMoves3();
    }
  },
};
</script>
