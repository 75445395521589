<template>
  <div>
    <poke-memo />
  </div>
</template>
<script>
import PokeMemo from "@/components/memo/PokeMemo.vue";
export default {
  components: {
    PokeMemo,
  },
};
</script>
