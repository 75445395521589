<template>
  <input
    type="range"
    class="range w-full"
    min="0"
    max="31"
    step="1"
    v-model="IVcount"
  />
  <div class="flex justify-between">
    <button @click="decrement" class="round-button">-</button>
    <span class="mx-2 text-sm">個体値:{{ IVcount }}</span>
    <button @click="increment" class="round-button">+</button>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: Number,
    hFlag: Boolean,
    aFlag: Boolean,
    bFlag: Boolean,
    cFlag: Boolean,
    dFlag: Boolean,
    sFlag: Boolean,
  },
  data() {
    return {
      IVcount: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.IVcount = newValue;
    },
    IVcount() {
      this.$emit("update:modelValue", {
        value: this.IVcount,
        hFlag: this.hFlag,
        aFlag: this.aFlag,
        bFlag: this.bFlag,
        cFlag: this.cFlag,
        dFlag: this.dFlag,
        sFlag: this.sFlag,
      });
    },
  },
  methods: {
    increment() {
      if (this.IVcount < 31) {
        this.IVcount++;
      }
    },
    decrement() {
      if (this.IVcount > 0) {
        this.IVcount--;
      }
    },
  },
};
</script>
