<template>
  <div class="h-screen mx-auto">
    <div class="flex justify-center">
      <form
        @submit.prevent="passwordChange"
        class="bg-white shadow-md rounded px-8 pt-6 pb-8"
      >
        <div class="flex items-center justify-center mb-5">
          <span class="icon-[mdi--password-reset] w-10 h-10"> </span>
          <span class="ml-2">パスワード変更</span>
        </div>

        <div class="flex items-center mb-4 relative">
          <span class="icon-[mdi--password-add-outline] w-7 h-7 mr-3"></span>
          <input
            :type="passwordFieldType"
            v-model="newPassword"
            placeholder="新しいパスワード"
            autocomplete="new-password"
            required
            class="shadow appearance-none border rounded w-full py-2 px-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <button
            type="button"
            @click="showPasswordToggle"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
          >
            <span v-if="showIcon" class="icon-[mdi--eye]"></span>
            <span v-else class="icon-[mdi--eye-off]"></span>
          </button>
        </div>

        <div class="flex items-center mb-4 relative">
          <span class="icon-[mdi--password-add] w-7 h-7 mr-3"></span>
          <input
            :type="passwordFieldType"
            v-model="reNewPassword"
            placeholder="新しいパスワード確認用"
            autocomplete="re-new-password"
            required
            class="shadow appearance-none border rounded w-full py-2 px-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <button
            type="button"
            @click="showPasswordToggle"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
          >
            <span v-if="showIcon" class="icon-[mdi--eye]"></span>
            <span v-else class="icon-[mdi--eye-off]"></span>
          </button>
        </div>

        <div class="flex items-center mb-4 relative">
          <span class="icon-[mdi--password] w-7 h-7 mr-3"></span>
          <input
            :type="passwordFieldType"
            v-model="currentPassword"
            placeholder="現在のパスワードを入力"
            autocomplete="current-password"
            required
            class="shadow appearance-none border rounded w-full py-2 px-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <button
            type="button"
            @click="showPasswordToggle"
            class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
          >
            <span v-if="showIcon" class="icon-[mdi--eye]"></span>
            <span v-else class="icon-[mdi--eye-off]"></span>
          </button>
        </div>
        <div class="flex items-center">
          <button
            class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            パスワードを変更する
          </button>
        </div>
      </form>
    </div>
    <router-link to="/profile" class="flex justify-center mt-5">
      <span class="text-blue-500">プロフィールに戻る</span>
    </router-link>

    <router-link to="/profile/edit" class="flex justify-center mt-5">
      <span class="text-blue-500">プロフィール編集に戻る</span>
    </router-link>
  </div>
</template>

<script>
import { authAxios } from "@/axios";
export default {
  data() {
    return {
      newPassword: "",
      reNewPassword: "",
      currentPassword: "",
      showIcon: false,
    };
  },
  methods: {
    async passwordChange() {
      try {
        await authAxios.post("api/auth/users/set_password/", {
          new_password: this.newPassword,
          re_new_password: this.reNewPassword,
          current_password: this.currentPassword,
        });
        this.$toast.open({
          message: "パスワードを変更を完了しました",
          type: "success",
          position: "top",
        });
        this.$router.push({ name: "EditProfile" });
      } catch (error) {
        console.error(error);
        this.$toast.open({
          message: "パスワードの変更に失敗しました",
          type: "error",
          position: "top",
        });
      }
    },
    showPasswordToggle() {
      this.showIcon = !this.showIcon;
    },
  },
  computed: {
    passwordFieldType() {
      return this.showIcon ? "text" : "password";
    },
  },
};
</script>
