<template>
  <div class="container">
    <memo-display-favorited />
  </div>
</template>

<script>
import MemoDisplayFavorited from "@/components/home/user/MemoDisplayFavorited.vue";

export default {
  components: {
    MemoDisplayFavorited,
  },
};
</script>
